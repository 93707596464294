import { HeaderName } from '../../../components/EditableTable/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FiltrationInput from '../../../components/EditableTable/FiltrationInput';
import React from 'react';
import { headerCellContainer, StyledDelete, StyledPencil } from '../../Flights/list/styles';
import LinkButton from '../../../components/LinkButton';
import SettingsIcon from '@mui/icons-material/Settings';
import moment from 'moment';

export const columns = ({
  handleFilter,
  handleOpenDeleteModal,
  filters,
}) => ([
  {
    flex: 0.2,
    minWidth: 200,
    field: 'username',
    headerName: 'Username',
    renderHeader: () => (
      <Box sx={headerCellContainer}>
        <Typography style={HeaderName}>Username</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.username || ''}
          value="username"
          placeholder="Username"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" sx={{ textTransform: 'capitalize' }}>
        {row?.username ?? 'No Username'}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    minWidth: 250,
    field: 'name',
    headerName: 'Name',
    renderHeader: () => (
      <Box sx={headerCellContainer}>
        <Typography style={HeaderName}>Name</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.name || ''}
          value="name"
          placeholder="Name"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" sx={{ textTransform: 'capitalize' }}>
        {row?.name ?? 'No Name'}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    minWidth: 200,
    field: 'group',
    headerName: 'Access Group',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Access Group</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.group || ''}
          value="group"
          placeholder="Access Group"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" sx={{ textTransform: 'capitalize' }}>
        {row?.group ?? ''}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    minWidth: 130,
    field: 'role',
    headerName: 'Role',
    renderHeader: () => (
      <Box sx={headerCellContainer}>
        <Typography style={HeaderName}>Role</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.role || ''}
          value="role"
          placeholder="Role"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize' }}>
        {row?.role ?? ''}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    minWidth: 250,
    field: 'email',
    headerName: 'Email',
    renderHeader: () => (
      <Box sx={headerCellContainer}>
        <Typography style={HeaderName}>Email</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.email || ''}
          value="email"
          placeholder="Email"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" sx={{ textTransform: 'capitalize' }}>
        {row?.email ?? ''}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    minWidth: 150,
    field: 'positions',
    headerName: 'Position',
    sortingOrder: null,
    renderHeader: () => (
      <Box sx={headerCellContainer}>
        <Typography style={HeaderName}>Position</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.positions || ''}
          value="positions"
          placeholder="Position"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize' }}>
        {row.positions.map((u) => u.position_name).join(', ') ?? ''}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'last_seen',
    minWidth: 80,
    renderHeader: () => 'Last Seen',
    renderCell: ({ row }) => {
      let lastSeen;

      if (row.last_seen) {
        const newMoment = moment.utc(row.last_seen);
        const localTime = newMoment.local();
        lastSeen = localTime.format('YYYY-MM-DD HH:mm:ss');
      } else {
        lastSeen = 'N/A';
      }

      return (
        <Typography>{lastSeen}</Typography>
      );
    }
  },
  {
    field: 'actions',
    maxWidth: 350,
    headerName: 'Actions',
    sortable: false,
    renderHeader: () => (
      <Box sx={headerCellContainer}>
        <Typography sx={{ ...HeaderName, mb: '12px' }}>Actions</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <>
        <LinkButton path={`/manage-users/${row.id}`}>
          <StyledPencil />
        </LinkButton>
        {' '}
        <LinkButton path={`/manage-users/change-password/${row.id}`}>
          <SettingsIcon />
        </LinkButton>
        <StyledDelete onClick={() => handleOpenDeleteModal(row.id)} />
      </>
    ),
  },
]);
