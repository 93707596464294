import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import {
  Cog, Counter, Plus, Rename
} from 'mdi-material-ui';
import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import Button from '../../../../../components/Button';
import { PART_TYPES, PRIORITY } from '../../../../../constans/logistics';
import FormGroup from '../../../../../form/components/FormGroup';
import Select from '../../../../../form/components/Select';
import { useGetUnitsListQuery } from '../../../../../store/slices/logisticsSlice';
import { getOptions } from '../../../../../utils/getOptions';
import { usePermissions } from '../../../../../utils/hooks/usePermissions';
import { btnS, headerBtn } from '../../../../Invoices/style';
import { GreenButton, RedButton, TextWithIcon } from '../../../style';
import { defaultValues } from '../schema';
import { content } from './style';

const PartsRepeater = () => {
  const { data: units, isLoading: isUnitsLoading } = useGetUnitsListQuery();
  const unitsOptions = getOptions(units);
  const AOGPermission = usePermissions('logistics_order_read_aog');

  const {
    control,
    watch,
    setValue,
    formState: { errors }
  } = useFormContext();

  const {
    fields,
    append,
    remove
  } = useFieldArray({
    name: 'parts',
    control,
  });

  const priorityOptions = AOGPermission ? PRIORITY : PRIORITY.filter(({ value }) => value !== 10);

  return (
    <Box sx={content}>
      <Box>
        <Typography noWrap variant="h5" sx={TextWithIcon}>
          <Cog />
          Parts
        </Typography>
      </Box>

      <Divider />
      {fields.map((item, index) => (
        <Grid
          container
          sx={{
            display: 'flex',
            backgroundColor: 'white',
            padding: '15px',
            borderRadius: '8px'
          }}
          key={item?.id || index}
        >
          <Grid item xs={12} lg={12}>
            <Box
              sx={{ ...headerBtn }}
            >
              <Box>
                <Typography noWrap variant="h5">
                  #
                  {index + 1}
                </Typography>
              </Box>

              {index !== 0 && (
                <Button
                  sx={{ ...(RedButton), fontWeight: 600 }}
                  endIcon={<CloseIcon />}
                  size="small"
                  title="Delete"
                  onClick={() => remove(index)}
                />
              )}

            </Box>
          </Grid>

          <Grid item xs={4} lg={4}>
            <FormGroup label="Part Number" required hasError={errors?.parts?.[index]?.part_number}>
              <Controller
                name={`parts.${[index]}.part_number`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    placeholder="Part Number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Rename sx={{ ...(field?.value && { color: '#626477' }) }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={4} lg={4}>
            <FormGroup label="Part Name" required hasError={errors?.parts?.[index]?.part_name}>
              <Controller
                name={`parts.${[index]}.part_name`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    placeholder="Part Name"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Rename sx={{ ...(field?.value && { color: '#626477' }) }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={4} lg={4}>
            <FormGroup label="Part Type" required hasError={errors?.parts?.[index]?.part_type}>
              <Controller
                name={`parts.${[index]}.part_type`}
                control={control}
                render={({ field }) => (
                  <Select
                    options={PART_TYPES}
                    placeholder="Part Type"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      if (e.target.value === 'part') setValue(`parts.${[index]}.part_count`, 1);
                    }}
                  />
                )}
              />
            </FormGroup>
          </Grid>

          <Grid
            container
            spacing={1}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Grid item xs={4} lg={4}>
              <FormGroup label="Quantity" required hasError={errors?.parts?.[index]?.part_count}>
                <Controller
                  name={`parts.${[index]}.part_count`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      placeholder="Quantity"
                      size="small"
                      disabled={watch(`parts.${[index]}.part_type`) === 'part'}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Counter sx={{ ...(field?.value && { color: '#626477' }) }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </FormGroup>
            </Grid>

            {watch(`parts.${[index]}.part_type`) === 'consumable' && (
              <Grid item xs={4} lg={4}>
                <FormGroup label="Unit" required hasError={errors?.parts?.[index]?.unit_id}>
                  <Controller
                    name={`parts.${[index]}.unit_id`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={unitsOptions}
                        placeholder="Units"
                        disabled={isUnitsLoading}
                        {...field}
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
            )}

            <Grid item xs={4} lg={4}>
              <FormGroup label="Priority" required hasError={errors?.parts?.[index]?.priority}>
                <Controller
                  name={`parts.${[index]}.priority`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      options={priorityOptions}
                      placeholder="Priority"
                      {...field}
                    />
                  )}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <FormGroup label="Remarks" hasError={errors?.parts?.[index]?.remarks}>
                <Controller
                  name={`parts.${[index]}.remarks`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      rows={2}
                      placeholder="Enter Remarks"
                      multiline
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </FormGroup>
            </Grid>
          </Grid>

        </Grid>
      ))}

      <Grid container sx={{ mt: 2.75, mb: 2.75, justifyContent: 'center' }}>
        <Grid item xs={4} sx={{ px: 0 }}>
          <Button
            title="Add Part"
            startIcon={<Plus />}
            sx={{ ...btnS, ...GreenButton }}
            onClick={() => append(defaultValues.parts[0])}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default PartsRepeater;
