import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import AppBarContent from '../AppBarContent/AppBarContent';
import {
  LayoutWrapper,
  LayoutAppBar,
  HeaderBox,
  HeaderToolbar,
  ContentWrapper,
} from './styles';


const Layout = () => {
  const location = useLocation();

  return (
    <LayoutWrapper className="layout-wrapper">
      {location.pathname !== '/ods' && (
        <LayoutAppBar
          color="default"
          elevation={3}
          position="static"
          className="layout-navbar-and-nav-container"
        >
          <HeaderBox className="layout-navbar">
            <HeaderToolbar className="navbar-content-container">
              <AppBarContent />
            </HeaderToolbar>
          </HeaderBox>
        </LayoutAppBar>
      )}


      <ContentWrapper className="layout-page-content">
        <Outlet />
      </ContentWrapper>

    </LayoutWrapper>
  ); };

export default Layout;
