import {
  string, object
} from 'yup';

export const defaultValues = {
  ttm_code: '',
};

export const schema = object().shape({
  ttm_code: string()
    .required()
    .label('TTM Code')
});
