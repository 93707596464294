import moment from 'moment';
import {
  array, boolean,
  date, number,
  object, string,
} from 'yup';

export const defaultValues = {
  fleet_company: '',
  fleet_company_person: '',
  fleet_typeofac: '',
  fleet_regofac: '',
  fleet_cargo: '',
  fleet_dimensions: '',
  fleet_payload: 0,
  fleet_flttime: '',
  fleet_date: moment().toDate(),
  fleet_crew_ready: moment().toDate(),
  fleet_excluding: 'de-icing, royalty/NOF, airports extra opening hours, warehouse cargo storage',
  fleet_subjectto: 'aircraft availability; weather conditions; cargo loadability; traffic rights; fuel price escalation',
  fleet_acmi: 0,
  fleet_cond_nav: 0,
  fleet_cond_fuel: 0,
  fleet_cond_other: 1000,
  fleet_price: '',
  hops: [
    {
      'hop_empty': 1,
      'hop_trip': null,
      'hop_departure': '',
      'hop_arriving': '',
      'hop_start_id': null,
      'hop_start': '',
      'hop_end_id': null,
      'hop_end': '',
      'hop_ap_fees_f': '',
      'hop_ap_fees_t': '',
      'hop_distance': '',
      'hop_flttime': '',
      'hop_tow': '',
      'hop_q_kg': '',
      'hop_maxpl_kg': ''
    },
  ]
};

export const schema = object().shape({
  fleet_company: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Company'),
  fleet_company_person: string()
    .nullable()
    .label('Person'),
  fleet_typeofac: string()
    .required('${label} cannot be blank.')
    .label('Type of A/C'),
  fleet_regofac: string()
    .required('${label} cannot be blank.')
    .label('Reg'),
  fleet_cargo: string()
    .required('${label} cannot be blank.')
    .label('Cargo'),
  fleet_payload: string().required('${label} cannot be blank.').label('Payload'),
  fleet_flttime: string(),
  fleet_fdp: string(),
  fleet_dimensions: string().label('Dimensions'),
  fleet_date: date().required('${label} cannot be blank.').label('Date'),
  fleet_crew_ready: date().required('${label} cannot be blank.').label('Crew Ready'),
  fleet_excluding: string().label('Excluding'),
  fleet_subjectto: string().label('Subject To'),
  fleet_acmi: string().nullable().required('${label} cannot be blank.').label('Acmi'),
  fleet_cond_nav: string().required('${label} cannot be blank.').label('Nav/1nm'),
  fleet_cond_fuel: string().required('${label} cannot be blank.').label('Fuel, eur/T'),
  fleet_cond_other: string()
    .nullable()
    .label('Other'),
  hops: array().of(object().shape({
    hop_empty: boolean(),
    hop_start_id: number().nullable().required('${label} cannot be blank.').label('Airport from'),
    hop_start: string(),
    hop_end_id: string().nullable().required('${label} cannot be blank.').label('Airport to'),
    hop_end: string(),
    hop_flttime: string(),
    hop_arriving: string(),
    hop_ap_fees_f: string(),
    hop_ap_fees_t: string(),
    hop_departure: string(),
    hop_distance: string(),
    hop_trip: string().nullable(),
    hop_tow: string(),
    hop_maxpl_kg: string(),
    hop_q_kg: string(),
  }))
});
