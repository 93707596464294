import React from 'react';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Modal from '../../../../../components/Modal';
import Input from '../../../../../form/components/Input';
import CloseIcon from '@mui/icons-material/Close';
import FormGroup from '../../../../../form/components/FormGroup';
import FieldError from '../../../../../form/components/FieldError';
import { Controller, useForm } from 'react-hook-form';
import { defaultValues, schema } from './schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useChangeFeesMutation } from '../../../../../store/session';
import {
  footerSx, headerSx, cancelButton, title
} from './styles';
import toast from 'react-hot-toast';

export const FeesModal = ({
  onClose, open, airportList, setQuotation, refetchAirportList
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    trigger,
    getValues,
    reset,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  const [changeFees] = useChangeFeesMutation();

  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };

  const onSubmit = async ({ airport }) => {
    const toastId = toast.loading('Loading...');
    const reqData = {
      airport_fees_e: parseInt(getValues('airport_fees_e'), 10) ?? null,
      airport_fees_f: parseInt(getValues('airport_fees_f'), 10) ?? null,
    };

    await changeFees({ id: airport, data: reqData, }).then((res) => {
      if (!res?.error) {
        toast.success('Airport fees updated!', {
          id: toastId
        });
        setQuotation();
        refetchAirportList();
        handleClose();
      } else {
        toast.error('Something went wrong', {
          id: toastId
        });
      }
    });
  };

  const handleChange = (e, value) => {
    setValue('airport', value.id);
    trigger('airport');
  };

  return (
    <Modal onClose={handleClose} open={open}>
      <Box>
        <Box sx={headerSx}>
          <Typography sx={title}>Change A/P Fees</Typography>
          <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
        </Box>
        <Grid spacing={2}>
          <Grid item lg={12}>
            <FormGroup required hasError={!!errors.airport}>
              <Controller
                name="airport"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    freeSolo
                    value={airportList?.filter((AirportItem) => AirportItem.id === field.value)[0] || null}
                    options={airportList}
                    getOptionLabel={(option) => `${option.airport_iata}/${option.airport_icao} (${option.airport_name})` || ''}
                    isOptionEqualToValue={(option, AirportListItem) => option.id === AirportListItem.id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        inputRef={field.ref}
                        error={Boolean(errors.airport)}
                        label="Airport From"
                      />
                    )}
                    onChange={handleChange}
                  />
                )}
              />

              <FieldError error={errors.email} />
            </FormGroup>
          </Grid>

          <Grid item lg={12}>
            <FormGroup label="Ferry Flight">
              <Controller
                name="airport_fees_e"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    value={field.value || airportList?.find((AirportItem) => AirportItem.id === getValues().airport)?.fees_empty || ''}
                    onChange={(e) => {
                      setValue('airport_fees_e', parseInt(e.target.value, 10));
                    }}
                  />
                )}
              />

              <FieldError error={errors.email} />
            </FormGroup>
          </Grid>

          <Grid item lg={12}>
            <FormGroup label="To Cargo Flight">
              <Controller
                name="airport_fees_f"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    value={field.value || airportList?.find((AirportItem) => AirportItem.id === getValues().airport)?.fees_loaded || ''}
                    onChange={(e) => {
                      setValue('airport_fees_f', parseInt(e.target.value, 10));
                    }}
                  />
                )}
              />

              <FieldError error={errors.email} />
            </FormGroup>
          </Grid>
        </Grid>

        <Box sx={footerSx}>
          <Button onClick={handleClose} sx={cancelButton}>Cancel</Button>
          <Button onClick={handleSubmit(onSubmit)} variant="contained">Save</Button>
        </Box>
      </Box>
    </Modal>
  ); };
