import {
  Dialog, Divider, Grid, Typography
} from '@mui/material';
import React from 'react';
import { CloseIcon } from '../../../../../../../../components/Icons';
import { CloseIconStyle } from '../../../../../styles';

const SupplierInvoiceModalInfo = (props) => {
  const {
    open = false,
    onClose = () => {},
    data = {}
  } = props;

  return (
    <Dialog
      // fullWidth
      maxWidth="lg"
      open={open}
      onClose={onClose}
    >
      <Grid container spacing={6} sx={{ padding: '24px' }}>
        <Grid item xs={12} sx={{ position: 'relative' }}>
          <Typography variant="h4">
            Supplier Invoice
            {' '}
            {data?.invoice_number}
          </Typography>
          <Divider />
          <CloseIcon
            sx={{
              ...CloseIconStyle, 
              position: 'absolute',
              top: '1rem',
              right: '0',
              fontSize: '2rem'
            }}
            onClick={onClose}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">
            <b>Generated by:</b>
            {' '}
            {data?.creator}  
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">
            <b>Generation Date:</b>
            {' '}
            {data?.created_at}
          </Typography>
          <Divider />
        </Grid>
        
      </Grid>
    </Dialog>
  );
};

export default SupplierInvoiceModalInfo;