import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import FormGroup from '../../../../../../form/components/FormGroup';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../../../../../form/components/Input';
import FieldError from '../../../../../../form/components/FieldError';
import { numberInputSx } from '../../../../../Quotations/CreateQuotation/CharterDrawer/styles';
import Box from '@mui/material/Box';
import { footerSx } from '../FuelForm/styles';
import Button from '@mui/material/Button';
import Drawer from '../../../../../../components/Drawer';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { useSendPermitMailMutation } from '../../../../../../store/session';

const PermitOrderDrawer = ({ open, onClose, permitData }) => {
  const [sendPermit, { isLoading: updating }] = useSendPermitMailMutation();
  const defaultValues = {
    'to': '',
    'cc': '',
    'description': ''
  };

  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    const mailData = {
      ...data,
      to: data.to.split(' ').join('').split(','),
      cc: data.cc.split(' ').join('').split(','),
      message: data.description
    };

    await sendPermit(mailData).then((res) => {
      if (!res?.error) {
        toast.success('Permit request was sent successfully!', {
          id: toastId
        });
        handleClose();
      } else {
        toast.error(res?.error?.data?.message, {
          id: toastId
        });
      }
    });
  };

  const {
    user
  } = useSelector((state) => state?.session);

  const setEmailData = () => {
    setValue('to', permitData?.to);
    setValue('cc', permitData?.cc);
    setValue('subject', permitData?.subject);
    setValue('description', `${permitData?.message?.attn ? `ATTN: ${permitData?.message?.attn}

DEAR OPS,` : `DEAR  ${permitData?.message?.caa_name}`}

PLEASE BE SO KIND TO ISSUE ${permitData?.message?.issue} AND ${permitData?.message?.landings} FOR ${permitData?.message?.loading} FLIGHT FROM ${permitData?.message?.airport_from} TO ${permitData?.message?.airport_to}  AS FOLLOWS:

A OPERATOR (FRF ICAO CODE) : FLEET AIR INTERNATIONAL KFT
LEGAL ADDRESS: 2220, VECSES, FO UTCA 218, HUNGARY

B TYPE A/C : ${permitData?.message?.aircraft_type}
MTOW : ${permitData?.message?.mtow}
REG : ${permitData?.message?.reg}

C SCHEDULE (ALL TIME UTC):
${permitData?.message?.hops}
D ${permitData?.message?.shortHops}
E CARGO TYPE: ${permitData?.message?.cargoType}
DIMENSION: ${permitData?.message?.dimensions}
WEIGHT: ${permitData?.message?.weight} KG

F CONSIGNEE: ${permitData?.message?.consignee}
CONSIGNOR: ${permitData?.message?.consignor}

G CREW DETAILS
${permitData?.message?.crews}

BEST REGARDS,
${user.name ?? ''}
FLEET AIR OPS
TEL: +36 1 4453776 (24H)
SITA: BUDFA7X AFTN: KAIRFRFX
EMAIL: OPS@FLEETAIR.EU
`);
  };

  useEffect(() => {
    if (permitData) {
      setEmailData();
    }
  }, [open]);

  return (
    <Drawer
      title="PERMIT MAILER"
      open={open}
      onClose={handleClose}
    >
      <form>
        <Grid
          container
          spacing={2}
          rowSpacing={2}
          sx={{ flexGrow: 1, p: '30px' }}
        >
          <Grid item xs={12}>
            <FormGroup label="To" required hasError={!!errors.to}>
              <Controller
                render={({ field } ) => (
                  <Input
                    placeholder="To"
                    {...field}
                  />
                )}
                control={control}
                name="to"
              />

              <FieldError error={errors.to} />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup label="CC">
              <Controller
                render={({ field } ) => (
                  <Input
                    sx={numberInputSx}
                    placeholder="CC"
                    {...field}
                  />
                )}
                control={control}
                name="cc"
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup label="Subject">
              <Controller
                render={({ field } ) => (
                  <Input
                    sx={numberInputSx}
                    placeholder="Subject"
                    {...field}
                  />
                )}
                control={control}
                name="subject"
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup label="Message" required hasError={!!errors.description}>
              <Controller
                render={({ field } ) => (
                  <Input
                    multiline
                    rows={26}
                    sx={numberInputSx}
                    placeholder="Message"
                    {...field}
                  />
                )}
                control={control}
                name="description"
              />

              <FieldError error={errors.description} />
            </FormGroup>
          </Grid>

          <Box sx={footerSx}>
            <Button variant="contained" disabled={updating} onClick={handleSubmit(onSubmit)}>Send</Button>
            <Button
              sx={{ background: '#e1e5ec' }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </Grid>
      </form>
    </Drawer>
  );
};

export default PermitOrderDrawer;
