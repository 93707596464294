import { AccountCircle } from '@mui/icons-material';
import AirlinesIcon from '@mui/icons-material/Airlines';
import BuildIcon from '@mui/icons-material/Build';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import TabContext from '@mui/lab/TabContext';
import MuiTabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import BoxIcon from 'mdi-material-ui/PackageVariantClosed';
import DrawIcon from '@mui/icons-material/Draw';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { usePermissions } from '../../utils/hooks/usePermissions';

import { useGetNotificationListQuery } from '../../store/session';
import UserProfileHeader from './UserProfileHeader';

import { useSelector } from 'react-redux';
import DocumentsTab from './DocumentsTab/DocumentsTab';
import InvoicesTab from './InvoicesTab/InvoicesTab';
import MyInvoicesTab from './MyInvoicesTab/MyInvoicesTab';
import ProfileTab from './ProfileTab/ProfileTab';
import SignsTab from './SignsTab/SignsTab';

const TabList = styled(MuiTabList)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'none'
  },
  '& .Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: `${theme.palette.common.white} !important`
  },
  '& .MuiTab-root': {
    minWidth: 65,
    minHeight: 38,
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.up('sm')]: {
      minWidth: 130
    }
  }
}));

const MyProfile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState('documents');
  const { page } = useParams();
  const navigate = useNavigate();

  const { data: notifications, isLoading: isLoadingNotification, refetch } = useGetNotificationListQuery();

  const hasPermissionsOCCM = usePermissions(['invoice_request_approve', 'invoice_request_logist_approve']);
  const hasPermissionsInvoiceExpensive = usePermissions('invoice_request_approve_expensive');
  const hasPermissionsInvoicePayment = usePermissions('invoice_request_payment');

  const hasPermissionsMyInvoice = usePermissions(['invoice_request_access', 'invoice_request_logist']);

  const hasPermissions = hasPermissionsOCCM || hasPermissionsInvoiceExpensive || hasPermissionsInvoicePayment;

  const handleChange = (event, newValue) => {
    navigate(`/my-profile/${newValue}`);
    setCurrentTab(newValue);
  };

  const {
    user
  } = useSelector((state) => state?.session);

  const hideText = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const tabContentList = {
    profile: <ProfileTab data={user} notifications={notifications} isLoading={isLoadingNotification} refetch={refetch} />,
    documents: <DocumentsTab />,
    signs: <SignsTab />
  };

  if (hasPermissions) {
    tabContentList.invoices = (
      <InvoicesTab
        hasPermissionsOCCM={hasPermissionsOCCM}
        hasPermissionsInvoiceExpensive={hasPermissionsInvoiceExpensive}
        hasPermissionsInvoicePayment={hasPermissionsInvoicePayment}
        type="request"
      />
    );

    tabContentList.partInvoices = (
      <InvoicesTab
        hasPermissionsOCCM={hasPermissionsOCCM}
        hasPermissionsInvoiceExpensive={hasPermissionsInvoiceExpensive}
        hasPermissionsInvoicePayment={hasPermissionsInvoicePayment}
        type="part"
      />
    );

    tabContentList.deliveryInvoices = (
      <InvoicesTab
        hasPermissionsOCCM={hasPermissionsOCCM}
        hasPermissionsInvoiceExpensive={hasPermissionsInvoiceExpensive}
        hasPermissionsInvoicePayment={hasPermissionsInvoicePayment}
        type="delivery"
      />
    );

    tabContentList.overhaulInvoices = (
      <InvoicesTab
        hasPermissionsOCCM={hasPermissionsOCCM}
        hasPermissionsInvoiceExpensive={hasPermissionsInvoiceExpensive}
        hasPermissionsInvoicePayment={hasPermissionsInvoicePayment}
        type="overhaul"
      />
    );

    tabContentList.exchangeInvoices = (
      <InvoicesTab
        hasPermissionsOCCM={hasPermissionsOCCM}
        hasPermissionsInvoiceExpensive={hasPermissionsInvoiceExpensive}
        hasPermissionsInvoicePayment={hasPermissionsInvoicePayment}
        type="exchange"
      />
    );
  };

  if (hasPermissions || hasPermissionsMyInvoice) {
    tabContentList.myInvoices = <MyInvoicesTab />;
  }

  useEffect(() => {
    if (page && tabContentList[page]) {
      navigate(`/my-profile/${page}`);
      setCurrentTab(page);
      if (page === 'profile') {
        refetch();
      }
    } else {
      navigate('/my-profile/documents');
    }
  }, [page]);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <UserProfileHeader data={user} />
      </Grid>
      {currentTab === undefined ? null : (
        <Grid item xs={12}>
          <TabContext value={currentTab}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <TabList
                  variant="scrollable"
                  scrollButtons="auto"
                  onChange={handleChange}
                  aria-label="customized tabs example"
                >
                  <Tab
                    value="profile"
                    label={(
                      <Box sx={{ display: 'flex', alignItems: 'center', ...(!hideText && { '& svg': { mr: 2 } }) }}>
                        <AccountCircle />
                        {!hideText && 'Profile'}
                      </Box>
                    )}
                  />
                  <Tab
                    value="documents"
                    label={(
                      <Box sx={{ display: 'flex', alignItems: 'center', ...(!hideText && { '& svg': { mr: 2 } }) }}>
                        <BoxIcon />
                        {!hideText && 'Documents'}
                      </Box>
                    )}
                  />
                  <Tab
                    value="signs"
                    label={(
                      <Box sx={{ display: 'flex', alignItems: 'center', ...(!hideText && { '& svg': { mr: 2 } }) }}>
                        <DrawIcon />
                        {!hideText && 'Signs'}
                      </Box>
                    )}
                  />
                  <Tab
                    value="myInvoices"
                    disabled={!hasPermissions && !hasPermissionsMyInvoice}
                    label={(
                      <Box sx={{ display: 'flex', alignItems: 'center', ...(!hideText && { '& svg': { mr: 2 } }) }}>
                        <ReceiptIcon />
                        {!hideText && 'My Invoices'}
                      </Box>
                  )}
                  />
                  <Tab
                    value="invoices"
                    disabled={!hasPermissions}
                    label={(
                      <Box sx={{ display: 'flex', alignItems: 'center', ...(!hideText && { '& svg': { mr: 2 } }) }}>
                        <ReceiptLongIcon />
                        {!hideText && 'Invoices'}
                      </Box>
                  )}
                  />
                  <Tab
                    value="partInvoices"
                    disabled={!hasPermissions}
                    label={(
                      <Box sx={{ display: 'flex', alignItems: 'center', ...(!hideText && { '& svg': { mr: 2 } }) }}>
                        <AirlinesIcon />
                        {!hideText && 'Part Invoices'}
                      </Box>
                      )}
                  />
                  <Tab
                    value="deliveryInvoices"
                    disabled={!hasPermissions}
                    label={(
                      <Box sx={{ display: 'flex', alignItems: 'center', ...(!hideText && { '& svg': { mr: 2 } }) }}>
                        <LocalShippingIcon />
                        {!hideText && 'Delivery Invoices'}
                      </Box>
                      )}
                  />
                  <Tab
                    value="overhaulInvoices"
                    disabled={!hasPermissions}
                    label={(
                      <Box sx={{ display: 'flex', alignItems: 'center', ...(!hideText && { '& svg': { mr: 2 } }) }}>
                        <BuildIcon />
                        {!hideText && 'Overhaul Invoices'}
                      </Box>
                      )}
                  />
                  <Tab
                    value="exchangeInvoices"
                    disabled={!hasPermissions}
                    label={(
                      <Box sx={{ display: 'flex', alignItems: 'center', ...(!hideText && { '& svg': { mr: 2 } }) }}>
                        <SyncAltIcon />
                        {!hideText && 'Exchange Invoices'}
                      </Box>
                      )}
                  />
                </TabList>
              </Grid>
              <Grid item xs={12}>
                {isLoading ? (
                  <Box sx={{
                    mt: 6, display: 'flex', alignItems: 'center', flexDirection: 'column'
                  }}
                  >
                    <CircularProgress sx={{ mb: 4 }} />
                    <Typography>Loading...</Typography>
                  </Box>
                ) : (
                  <TabPanel sx={{ p: 0 }} value={currentTab}>
                    {tabContentList[currentTab]}
                  </TabPanel>
                )}
              </Grid>
            </Grid>
          </TabContext>
        </Grid>
      )}
    </Grid>
  );
};

export default MyProfile;
