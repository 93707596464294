import {
  Box,
  Card,
  CardContent, Grid,
  Tooltip,
  Typography, styled
} from '@mui/material';
import React from 'react';
import Button from '../../../../../../../components/Button';
import ButtonIcon from '../../../../../../../components/ButtonIcon';
import { StyledDelete } from '../../../../../../../components/EditableTable/styles';

const HeaderTypography = styled(Typography)({
  fontWeight: 'bold',
  color: '#333',
});

const StyledCard = styled(Card)({
  margin: '10px 0',
  boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.1)',
});

const TitleTypography = styled(Typography)({
  fontWeight: 'bold',
});

const StatusTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
}));

const InvoiceTable = (props) => {
  const {
    tableConfig: {
      header = [],
      body = []
    }
  } = props;

  const setTableField = (title, xs, item, field, callback = () => {}) => {
    switch (title) {
      case 'Action':
        return (
          <Grid item xs={xs} container justifyContent="flex-end">
            <ButtonIcon>
              <Tooltip title="Delete">
                <StyledDelete />
              </Tooltip>
            </ButtonIcon>
          </Grid>
        );
      case 'Invoice Number':
        return (
          <Grid item xs={xs} sx={{ overflow: 'hidden', textWrap: 'nowrap' }}>
            <TitleTypography
              variant="body1"
              onClick={() => callback(item)}
              sx={{
                color: 'rgb(108, 108, 241)',
                cursor: 'pointer',
                textTransform: 'capitalize',
                whiteSpace: 'break-spaces',
                wordBreak: 'break-all'
              }}
            >
              {item?.[field] || title}
            </TitleTypography>
          </Grid>
        );
      case 'Info':
        return (
          <Grid item xs={xs} sx={{ paddingLeft: '0!important' }}>
            <Button 
              title="Open"
              sx={{ width: '100%' }}
              disabled={!item?.creator}
              onClick={() => callback(item)}
            />
          </Grid>
          
        );
      case 'Regenerate':
        return (
          <Grid item xs={xs} container justifyContent="center">
            <Button 
              title="Regenerate"
              disabled={body?.some((vlv) => (vlv?.parent_invoice_number === item?.invoice_number))}
              onClick={() => callback(item)}
            />
          </Grid>
        );
      default:
        return (
          <Grid item xs={xs}>
            <Typography
              onClick={() => callback(item)}
              sx={{
                whiteSpace: 'break-spaces',
                wordBreak: 'break-all' 
              }}
            >
              {item?.[field] || title}
            </Typography>
          </Grid>
        ); 
    }
  };

  return (
    <>
      <CardContent sx={{
        margin: '0',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#f5f5f5',
        borderRadius: '4px 0px',
      }}
      >
        <Grid container alignItems="center" spacing={2}>
          {
          header.map(({ xs = 4, title = '' }) => (
            <Grid item xs={xs}>
              <HeaderTypography variant="body1">{title}</HeaderTypography>
            </Grid>
          ))
        }
        </Grid>
      </CardContent>
      <Box sx={{ display: 'flex', flexDirection: 'column-reverse' }}>
        {
        body.map(item => (
          <StyledCard sx={body?.some((vlv) => (vlv?.parent_invoice_number === item?.invoice_number)) ? { backgroundColor: 'rgb(194, 194, 194)' } : {}}>
            <CardContent>
              <Grid container alignItems="center" spacing={2}>
                {
                  header.map(({
                    xs, field, title, callback 
                  }) => setTableField(title, xs, item, field, callback))
                }
              </Grid>
            </CardContent>
          </StyledCard>
        ))
      }
      </Box>
      
    </>
  );
};

export default InvoiceTable;