import { RefreshRounded } from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import {
  Box, Card, Tab, Tabs, Typography, useMediaQuery
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../components/Button';
import EditableTable from '../../../components/EditableTable';

import BuildIcon from '@mui/icons-material/Build';
import LoopIcon from '@mui/icons-material/Loop';
import { useNavigate } from 'react-router-dom';
import { BoxIcon, StorageIcon } from '../../../components/Icons';
import BoxesIcon from '../../../components/Icons/BoxesIcon';
import { useAppDispatch } from '../../../hooks/toolkitHooks';
import { useDeleteStorageOrderMutation, useGetAircraftListQuery, useGetStorageOrdersTableQuery } from '../../../store/session';
import { fetchCurrencies } from '../../../store/slices/logisticsSlice';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import { btnS, headerBtn } from '../../Invoices/style';
import {
  GreenButton,
  TextWithIcon,
  YellowButton
} from '../style';
import { columns } from './columns';

const MyOrders = () => {
  const { data: AircraftList } = useGetAircraftListQuery();
  const navigate = useNavigate();
  
  const aircraftOptions = AircraftList?.map((aircraft) => ({
    label: aircraft.aircraft_registration,
    value: aircraft.id
  }));
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const hasPermissions = usePermissions('logistics_storage_write');
  const externalStorageMaster = usePermissions('logistics_storage_master');
  const hasInternalPermissions = usePermissions('internal_logistics_storage_master');

  useEffect(() => {
    if (aircraftOptions) {
      aircraftOptions?.push({ value: 0, label: 'N/A' });
      aircraftOptions?.push({ value: '', label: '-' });
    }
  }, [aircraftOptions]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchCurrencies());
  }, []);
  const { currencies } = useSelector((state) => state.logistics);

  const [arFilter, setArFilter] = useState(null);
  const [partNumber, setPartNumber] = useState('');
  const [partName, setPartName] = useState('');
  const [refresh, setRefresh] = useState(0);

  const maxWidth800px = useMediaQuery('(max-width:800px)');

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const renderTable = (part_type) => (
    <EditableTable
      useHook={useGetStorageOrdersTableQuery}
      useHookDelete={useDeleteStorageOrderMutation}
      columns={columns}
      sortByType="DESC"
      sortByActive="id"
      // getRowClassName={(params) => 'row-m row-cnld'}
      getRowHeight={() => 'auto'}
      getRowId={(row) => row.id}
      tableParams={{
        part_type,
        aircraft_id: arFilter,
        part_number: partNumber,
        part_name: partName,
        refresh
      }}
      currencies={currencies}
      aircraftOptions={aircraftOptions}
      partType={part_type}
      permissions={hasPermissions}
      internalPermissions={hasInternalPermissions}
      externalStorageMaster={externalStorageMaster}
    />
  );

  return (
    <>
      <Card
        sx={{
          mt: 3,
          padding: '1rem'
        }}
      >
        <Box
          sx={{
            ...headerBtn, display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', ...(maxWidth800px && { display: 'flex', flexDirection: 'column', gap: 5 }) 
          }}
        >
          <Box sx={maxWidth800px ? { alignSelf: 'start' } : {}}>
            <Typography noWrap variant="h3" sx={TextWithIcon}>
              <StorageIcon />
              Storage
            </Typography>
          </Box>
          <Box sx={{ justifySelf: 'center' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              sx={maxWidth800px ? { 
                '& .MuiTabs-flexContainer': {
                  overflowX: 'auto'
                }
              } : {}}
            >
              <Tab value="1" label="Parts" icon={<BoxIcon />} />
              <Tab value="2" label="Instruments" icon={<BuildIcon />} />
              <Tab value="3" label="Exchange Send" icon={<LoopIcon />} />
            </Tabs>
          </Box>
          <Box sx={maxWidth800px ? {
            display: 'flex',
            gap: '5px',
            flexDirection: 'column',
            width: '100%'
          } : { display: 'flex', gap: '10px', justifySelf: 'end' }}
          >
            <Button
              startIcon={<RefreshRounded />}
              sx={{ ...(!maxWidth800px && { ml: 2 }), ...(maxWidth800px && btnS), ...YellowButton }}
              title="Refresh"
              onClick={handleRefresh}
            />
            <Button
              sx={GreenButton}
              startIcon={<BoxIcon />}
              title="Create"
              onClick={() => navigate(`/logistics/orders-storage/create_${+value === 1 ? 'part' : 'instrument'}`)}
              disabled={(!hasPermissions && !hasInternalPermissions && !externalStorageMaster)}
            />
            <Button
              startIcon={<BoxesIcon />}
              title="Dashboard"
              onClick={() => navigate('/logistics/dashboard/orders')}
            />
          </Box>
        </Box>
      </Card>

      <Card
        sx={{
          mt: 3,
          padding: '1rem'
        }}
      >
        <TabContext value={value}>
          <TabPanel value="1">
            {renderTable('all_parts')}
          </TabPanel>
          <TabPanel value="2">
            {renderTable('instrument')}
          </TabPanel>
          <TabPanel value="3">
            {renderTable('exchange')}
          </TabPanel>
        </TabContext>
      </Card>
    </>
  );
};

export default MyOrders;
