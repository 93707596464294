import { Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import DatePicker from '../../../../../form/components/DatePicker';
import FieldError from '../../../../../form/components/FieldError';
import FormGroup from '../../../../../form/components/FormGroup';
import Select from '../../../../../form/components/Select';
import { useGetCrewListQuery } from '../../../../../store/session';
import { stringifyLocationSearch } from '../../../../../utils/locationSearch';
import { selectOptionFormat } from '../../../../../utils/number';
import { setDateValue, validateDatePickerValue } from '../../../../../utils/setDateValue';
import TypeCard from '../../../../Ods/OdsForm/TypeCard';

const DateSing = (props) => {
  const { performedBy = null, closedBy = null } = props;
  const { control, setValue, formState: { errors } } = useFormContext();

  const { data: crewListPerform } = useGetCrewListQuery(stringifyLocationSearch({
    crew_wo_can_perform: 1,
    post: 3,
    crew_selected: performedBy
  }), {
    refetchOnMountOrArgChange: true
  });

  const { data: crewListClose } = useGetCrewListQuery(stringifyLocationSearch({
    crew_wo_can_close: 1,
    post: 3,
    crew_selected: closedBy
  }), {
    refetchOnMountOrArgChange: true
  });

  const optionCrewListPerform = selectOptionFormat(crewListPerform, 'crew_name');
  const optionCrewListClose = selectOptionFormat(crewListClose, 'crew_name');

  const maxWidth768px = useMediaQuery('(max-width:768px)');

  return (
    <TypeCard title="DATE & SIGN" variant="yellow" isPanel>
      <Grid xs={12} container spacing={5} rowSpacing={2} sx={{ flexGrow: 1 }}>

        <Grid item xs={maxWidth768px ? 12 : 4}>
          <FormGroup label="PERFORMED AT" hasError={!!errors.performed_at}>
            <Controller
              render={({ field } ) => (
                <DatePicker
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  placeholderText="YYYY-MM-DD HH:mm"
                  dateFormat="yyyy-MM-dd HH:mm"
                  showMonthDropdown
                  showYearDropdown
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    setDateValue(e, setValue, 'performed_at');
                  }}
                  value={validateDatePickerValue(field.value)}
                />
              )}
              control={control}
              name="performed_at"
            />

            <FieldError error={errors.performed_at} />
          </FormGroup>
        </Grid>

        <Grid item xs={maxWidth768px ? 12 : 4}>
          <FormGroup label="PERFORMED BY" hasError={!!errors.performed_by}>
            <Controller
              render={({ field } ) => (
                <Select
                  options={optionCrewListPerform}
                  placeholder="Performed By"
                  {...field}
                />
              )}
              control={control}
              name="performed_by"
            />

            <FieldError error={errors.performed_by} />
          </FormGroup>
        </Grid>

        <Grid item xs={maxWidth768px ? 12 : 4}>
          <FormGroup label="INSPECTED BY" hasError={!!errors.inspection_by}>
            <Controller
              render={({ field } ) => (
                <Select
                  options={optionCrewListPerform}
                  placeholder="Inspection By"
                  {...field}
                />
              )}
              control={control}
              name="inspection_by"
            />

            <FieldError error={errors.inspection_by} />
          </FormGroup>
        </Grid>

        <Grid item xs={maxWidth768px ? 12 : 4}>
          <FormGroup label="INSPECTED AT" hasError={!!errors.inspection_at}>
            <Controller
              render={({ field } ) => (
                <DatePicker
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  placeholderText="YYYY-MM-DD HH:mm"
                  dateFormat="yyyy-MM-dd HH:mm"
                  showMonthDropdown
                  showYearDropdown
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    setDateValue(e, setValue, 'inspection_at');
                  }}
                  value={validateDatePickerValue(field.value)}
                />
              )}
              control={control}
              name="inspection_at"
            />

            <FieldError error={errors.inspection_at} />
          </FormGroup>
        </Grid>

        <Grid item xs={maxWidth768px ? 12 : 4}>
          <FormGroup label="CLOSED AT" hasError={!!errors.closed_at}>
            <Controller
              render={({ field } ) => (
                <DatePicker
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  placeholderText="YYYY-MM-DD HH:mm"
                  dateFormat="yyyy-MM-dd HH:mm"
                  showMonthDropdown
                  showYearDropdown
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    setDateValue(e, setValue, 'closed_at');
                  }}
                  value={validateDatePickerValue(field.value)}
                />
              )}
              control={control}
              name="closed_at"
            />

            <FieldError error={errors.closed_at} />
          </FormGroup>
        </Grid>

        <Grid item xs={maxWidth768px ? 12 : 4}>
          <FormGroup label="CLOSED BY" hasError={!!errors.closed_by}>
            <Controller
              render={({ field } ) => (
                <Select
                  options={optionCrewListClose}
                  placeholder="Closed By"
                  {...field}
                />
              )}
              control={control}
              name="closed_by"
            />

            <FieldError error={errors.closed_by} />
          </FormGroup>
        </Grid>

      </Grid>
    </TypeCard>
  );
};

export default DateSing;
