import {
  Box, Card,
  CircularProgress,
  Grid,
  Typography
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Button from '../../../../../../../../components/Button/Button';
import ServerAutocomplete from '../../../../../../../../form/components/ServerAutocomplete';
import { useSearchSupplierQuery } from '../../../../../../../../store/slices/logisticsSlice';
import { headerBtn } from '../../../../../../../Invoices/style';
import { GreenButton } from '../../../../../../style';
import InvoiceTable from '../InvoiceTable';
import RegenerateInvoice from './RegenerateInvoice';
import SupplierInvoiceForm from './SupplierInvoiceForm';
import SupplierInvoiceModalInfo from './SupplierInvoiceModalInfo';
import { useSupplierInvoice } from './useSupplierInvoice';

const SupplierInvoice = ({ refetch }) => {
  const { order } = useSelector((state) => state.logistics);
  const location = useLocation();
  const typeDashboard = location.pathname.split('/')[3];
  const isExternal = (typeDashboard === 'orders');
  
  const {
    methods: {
      handleSubmit,
      control,
      formState: { errors },
      setValue,
      watch
    },
    onSubmit,
    handleSelectSupplier,
    supplier,
    handlePreviewInvoice,
    previewLoading,
    sendLoading,
    handleAppendNewParts,
    handleRemoveParts,
    partsArray,
    deliveryServices,
    handleOpenSupplierInfoModal,
    supplierInvoiceInfo,
    handleSetInfoToRegenerate,
    supplierToRegenerate
  } = useSupplierInvoice({ refetch });

  const handleChangeInvoiceArray = (array = []) => {
    if (!array) return [];
    const currentInvoices = array.filter(inv => !inv?.parent_invoice_number);
    const childrenInvoices = array.filter(inv => inv?.parent_invoice_number);
    const currentInvoicesWithChild = currentInvoices.map(inv => {
      let child = null;
      let children = [];

      if (array.some(item => item?.parent_invoice_number === inv?.invoice_number)) {
        child = array.find(item => item?.parent_invoice_number === inv?.invoice_number);
        let currChild = child?.invoice_number;
      
        for (let i = 0; i < childrenInvoices?.length; i++) {
          if (childrenInvoices?.some(vlv => vlv?.parent_invoice_number === currChild)) {
            const itemChild = childrenInvoices.find(item => item?.parent_invoice_number === currChild);

            currChild = itemChild?.invoice_number;
            children = [...children, itemChild];
          } else {
            currChild = child?.invoice_number;
            break;
          }
        }

        children = [child, ...children];
      }

      return {
        ...inv,
        children
      };
    });

    let returnArray = [];
  
    for (let i = 0; i < currentInvoicesWithChild?.length; i++) {
      const item = currentInvoicesWithChild?.[i];
      const children = currentInvoicesWithChild?.[i]?.children;

      returnArray = [...returnArray, item, ...children];
    }

    return returnArray;
  };

  const externalTableConfig = {
    header: [
      {
        xs: 1,
        title: 'Info',
        field: 'info',
        callback: ({ creator = null, created_at = null, invoice_number = null }) => handleOpenSupplierInfoModal(true, creator, created_at, invoice_number)
      },
      {
        xs: 3,
        title: 'Invoice Number',
        field: 'invoice_number',
        callback: ({
          invoice_number, ship_to, to, parts
        }) => handlePreviewInvoice(null, {
          invoice_number, ship_to, to, parts
        })
      },
      {
        xs: 3,
        title: 'Email',
        field: 'email',
        callback: () => {}
      },
      {
        xs: 3,
        title: 'To',
        field: 'to',
        callback: () => {}
      },
      {
        xs: 2,
        title: 'Regenerate',
        field: 'regenerate',
        callback: (data) => handleSetInfoToRegenerate(true, data)
      },
    ],
    body: handleChangeInvoiceArray(order?.exist_invoices_data)
  };

  const internalTableConfig = {
    header: [
      {
        xs: 4,
        title: 'Invoice Number',
        field: 'invoice_number',
        callback: ({
          invoice_number, ship_to, to, parts
        }) => handlePreviewInvoice(null, {
          invoice_number, ship_to, to, parts
        })
      },
      {
        xs: 5,
        title: 'Email',
        field: 'email',
        callback: () => {}
      },
      {
        xs: 3,
        title: 'To',
        field: 'to',
        callback: () => {}
      },
    ],
    body: order?.exist_invoices_data
  };

  return (
    <Card sx={{ padding: '1rem' }}>
      <Grid sx={{
        mb: 5
      }}
      >
        {
          order?.exist_invoices_data?.length > 0 ? <InvoiceTable tableConfig={isExternal ? externalTableConfig : internalTableConfig} /> : (
            <Box sx={{
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyItems: 'center'
            }}
            >
              <Typography variant="h5" noWrap>
                No related invoices
              </Typography>
              <Typography variant="subtitle2" noWrap>
                Please add new invoice.
              </Typography>
            </Box>
          )
        }

      </Grid>
      <Box sx={{ ...headerBtn }}>
        <Box>
          <Typography variant="h5" noWrap>
            Select Supplier
          </Typography>
        </Box>
        <Box />
        <Box sx={{ display: 'flex', justifySelf: 'end', justifyContent: 'end' }}>
          <Button
            title="Preview Invoice"
            disabled={!supplier || previewLoading}
            onClick={handlePreviewInvoice}
            startIcon={previewLoading && <CircularProgress size={15} />}
          />

          <Button
            sx={{ ...(GreenButton), marginLeft: '1rem' }}
            title="Send Invoice"
            disabled={!supplier || sendLoading}
            onClick={handleSubmit(onSubmit)}
            startIcon={sendLoading && <CircularProgress size={15} />}
          />
        </Box>

        <SupplierInvoiceModalInfo 
          open={supplierInvoiceInfo.isOpen}
          onClose={() => handleOpenSupplierInfoModal()}
          data={supplierInvoiceInfo}
        />
        
        <RegenerateInvoice 
          open={supplierToRegenerate.isOpen}
          onClose={() => handleSetInfoToRegenerate()}
          handlePreviewInvoice={handlePreviewInvoice}
          onRegenerate={onSubmit}
          invoice={supplierToRegenerate.invoice}
          order={order}
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ServerAutocomplete
            value={supplier}
            withData
            placeholder="Search suppliers by name or email"
            searchQueryFn={useSearchSupplierQuery}
            handleSelect={handleSelectSupplier}
          />
        </Grid>

        {supplier && (
          <SupplierInvoiceForm 
            errors={errors}
            control={control}
            deliveryServices={deliveryServices}
            partsArray={partsArray}
            handleAppendNewParts={handleAppendNewParts}
            handleRemoveParts={handleRemoveParts}
            order={order}
            setValue={setValue}
            watch={watch}
          />
        )}
      </Grid>
    </Card>
  );
};

export default SupplierInvoice;
