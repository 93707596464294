import {
  Box, Card, Divider, Typography, useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../components/Button';
import EditableTable from '../../../components/EditableTable';
import Loader from '../../../components/Loader/ContentPageLoader';
import {
  useDeleteWorkOrderMutation,
  useGetAircraftListQuery, useGetWorkOrderQuery,
  useUpdateWorkOrderMutation
} from '../../../store/session';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import { headerRow, types, typesSmall } from '../../Ods/OdsTable/styles';
import Info from '../MaintenanceACFT/Info';
import {
  boxPopover, boxPopoverInfo, btnX, headerRowSx, mobileInfo
} from '../MaintenanceACFT/styles';
import { columns } from './columns';
import {
  activeTypeSx,
  boxAircraft,
  boxAircraftSx,
  containerAircraft,
  containerAircraftSx,
  titleContainer,
  typeSx
} from './styles';

const WorkOrder = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [activeType, setActiveType] = useState(+params.id);
  const [aircraftType, setAircraftType] = useState([]);
  const [showInfo, setShowInfo] = useState(false);

  const maxWidth768px = useMediaQuery('(max-width:840px)');
  const maxWidth1000px = useMediaQuery('(max-width:1275px)');
  const maxWidth733px = useMediaQuery('(max-width:733px)');

  const deletePermission = usePermissions('wo_and_job_delete_access');
  const deletedWOPermission = usePermissions('deleted_wo_access');

  const { data: aircraft } = useGetAircraftListQuery('?with_current_ods=true');

  const handleSetActiveType = (data) => {
    setActiveType(data?.id);
    setAircraftType(data);
    navigate(`/maintenance/work-order/${data?.id}`);
  };

  const handleInfo = () => {
    setShowInfo(!showInfo);
  };

  const tableParams = { aircraft_id: params.id };

  useEffect(() => {
    const res = aircraft?.find(item => item.id === +params.id);

    if (res) {
      setActiveType(res.id);
      setAircraftType(res);
    }
  }, [aircraft, params.version]);

  if (!aircraft) return <Loader />;

  return (
    <Card sx={{
      mt: 3,
      padding: '1rem',
    }}
    >
      <Box sx={{ ...titleContainer, pb: 3 }}>
        <Typography variant="h3" sx={{ textTransform: 'capitalize' }}>Work Order list</Typography>
      </Box>
      <Box sx={{
        ...containerAircraft, ...(maxWidth1000px && containerAircraftSx), ...(!maxWidth1000px && { alignItems: 'center' }), mb: 0 
      }}
      >
        <Box sx={{ ...boxAircraft, ...(maxWidth768px && boxAircraftSx), mb: 0 }}>
          {aircraft?.map(type => (
            <Button
              title={type.aircraft_registration}
              sx={{ ...typeSx, ...(type.id === activeType && activeTypeSx) }}
              key={type.id}
              onClick={() => handleSetActiveType(type)}
            />
          ))}
        </Box>
        <Box
          sx={{
            ...headerRow,
            ...(maxWidth1000px && types),
            ...(maxWidth733px && typesSmall),
            minWidth: 'auto',
            backgroundColor: 'none',
            padding: 0
          }}
        >
          {deletedWOPermission && (
          <Button
            sx={typeSx}
            onClick={() => navigate(`/maintenance/work-order/delete/confirmation/${+params.id}`)}
          >
            Deleted Work Orders
          </Button>
          )}
        </Box>
      </Box>

      <Box sx={{
        ...headerRow, ...(maxWidth768px && headerRowSx), backgroundColor: 'none', padding: '10px 0 0 0' 
      }}
      >
        <Button sx={{ ...(maxWidth768px && btnX) }} title={showInfo ? 'Hide Info' : 'Show info'} onClick={handleInfo} />
      </Box>

      <Divider />

      {!maxWidth768px ? (
        <Box sx={{ ...(boxPopover), ...(showInfo && boxPopoverInfo) }}>
          {showInfo && <Info aircraft={aircraftType} />}
        </Box>
      ) : (
        showInfo && (
        <Box sx={{ ...(maxWidth768px && mobileInfo) }}>
          <Info aircraft={aircraftType} />
        </Box>
        )
      )}

      <EditableTable
        useHook={useGetWorkOrderQuery}
        useHookUpdate={useUpdateWorkOrderMutation}
        useHookDelete={useDeleteWorkOrderMutation}
        getRowId={(row) => row.id}
        columns={columns}
        sortByType="DESC"
        deletePermission={deletePermission}
        sortByActive="deadline"
        tableParams={tableParams}
      />
    </Card>
  );
};

export default WorkOrder;
