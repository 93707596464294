export const typeSx = {
  color: '#fff',
  backgroundColor: '#ACB5C3',
  borderRadius: '25px',
  whiteSpace: 'nowrap',
  height: '40px',
  p: '6px 12px',
  textTransform: 'capitalize',
  textAlign: 'center',
  '&:hover': {
    backgroundColor: '#8e9bae !important'
  }
};

export const activeTypeSx = {
  backgroundColor: '#36918b',
  '&:hover': {
    backgroundColor: '#36918b !important'
  }
};

export const containerAircraft = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  gap: '25px'
};

export const titleContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const boxAircraft = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  flexWrap: 'wrap',
  maxHeight: '158px',
  overflowY: 'auto',
  backgroundColor: '#F5F5F7',
  padding: '15px',
  borderRadius: '8px',
  width: 'max-content',
  // flexDirection: 'column',
};

export const boxAircraftSx = {
  width: '100%',
  gap: '5px',
  padding: '10px',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr'
};

export const tabsSx = {
  maxWidth: '300px',
  '& .MuiTab-textColorPrimary': {
    alignItems: 'flex-start'
  }
};

export const tabsSmall = {
  maxWidth: '100%',
  marginRight: 'unset',
  padding: '0.75rem 0',
  '& .MuiTab-root': {
    maxWidth: '100%'
  }
};

export const cardInfo = {
  p: '0.75rem',
  backgroundColor: '#f5f5f5',
  border: '1px solid #ffffff',
};

export const headerRow = {
  display: 'flex',
  gap: '10px',
  overflowY: 'auto',
  mt: '15px',
  borderRadius: '8px',
  width: 'max-content',
  flexDirection: 'row',
  height: 'fit-content',
  minWidth: '240px'
};

export const headerBtn = {
  display: 'flex',
  gap: '10px',
  overflowY: 'auto',
  borderRadius: '8px',
  width: 'max-content',
  flexDirection: 'row',
  height: 'fit-content',
  minWidth: '240px'
};

export const boxTitle = {
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  gap: '20px'
};

export const types = {
  flexDirection: 'column',
};

export const typesSmall = {
  minWidth: '100%'
};

export const icon = {
  width: '18px',
  marginLeft: '3px',
  transform: 'rotate(0deg)',
  transition: 'transform .5s ease-in-out',
};

export const boxPopover = {
  display: 'flex',
  justifyContent: 'flex-end',
};

export const boxPopoverInfo = {
  justifyContent: 'space-between',
};

export const containerAircraftSx = {
  flexDirection: 'column',
};

export const btnX = {
  width: '50%'
};

export const headerRowSx = {
  width: '100%'
};

export const mobileInfo = {
  display: 'flex',
  justifyContent: 'center',
};

export const taskNumberTypog = {
  overflowWrap: 'anywhere'
};