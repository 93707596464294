import {
  Box, Card, Divider, Typography
} from '@mui/material';
import React from 'react';
import { headerBtn } from '../../../../Invoices/style';
import OrderHistoryItem from './OrderHistoryItem';

const OrderHistory = (props) => {
  const {
    order: {
      send = null,
      pending = null,
      approve = null,
      onTheWay = null,
      delivered = null,
      installed = null,
      decline = null,
      parts = null
    }
  } = props;
  const isInstalled = ((Array.isArray(installed) && installed?.length >= 1) || !!installed?.installed);

  return (
    <Card
      sx={{
        display: 'flex', flexDirection: 'column', padding: '1rem', backgroundColor: 'transparent', alignItems: 'center'
      }}
    >
      <Box sx={{ ...headerBtn, justifyContent: 'center' }}>
        <Box>
          <Typography variant="h5" noWrap sx={{ textAlign: 'center' }}>
            Order History
          </Typography>
        </Box>
      </Box>
      {send && (<OrderHistoryItem item={send} type="send" scaleIcon={!!pending || isInstalled || decline} title="Send" />)}
      {pending && (<OrderHistoryItem item={pending} type="pending" scaleIcon={!!approve || isInstalled || decline} title="Pending Approve" />)}
      {approve && (<OrderHistoryItem item={approve} type="approve" scaleIcon={!!onTheWay || isInstalled || decline} title="Approve" />)}
      {onTheWay && (<OrderHistoryItem item={onTheWay} type="onTheWay" scaleIcon={!!delivered || isInstalled || decline} title="On The Way" />)}
      {delivered && (<OrderHistoryItem item={{ ...delivered, ...(parts && { parts }) }} type="delivered" scaleIcon={isInstalled || decline} title="Delivered" />)}
      {decline && (<OrderHistoryItem item={decline} type="decline" scaleIcon={isInstalled} title="Decline" />)}
      {isInstalled && (<OrderHistoryItem item={installed} type="installed" title="Installed" />)}
      
      <Divider />
    </Card>
  );
};

export default OrderHistory;
