import LinearScaleIcon from '@mui/icons-material/LinearScale';
import { Box, Divider, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import { useOpenCertificateMutation, usePreviewSupplierInvoiceMutation } from '../../../../../store/slices/logisticsSlice';
import { changeKey } from '../../../../../utils/getOptions';
import { PRICES_STATUS } from '../../../OrdersDashboard/components/forms/new/schema';
import { handleGetName } from '../constants';

const OrderHistoryItem = (props) => {
  const {
    item, type = 'send', scaleIcon = false, title = ''
  } = props;

  const [previewInvoice, { isLoading: previewLoading }] = usePreviewSupplierInvoiceMutation();
  const [openCertificate, { isLoading: certificateLoading }] = useOpenCertificateMutation();

  const handlePreviewInvoice = async (invoiceData) => {
    const toastId = toast.loading('Loading...');

    const data = {
      to: invoiceData?.to,
      ship_to: invoiceData?.ship_to,
      parts: invoiceData?.parts,
      invoice_number: invoiceData?.invoice_number,
    };

    const res = await previewInvoice({ data });

    if (res.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }

    if (!res?.errros) {
      toast.success('Success!', {
        id: toastId,
      });
      const fileUrl = URL.createObjectURL(res.data);
      window.open(fileUrl, '_blank', 'noreferrer');
    }
  };

  const handleOpenCertificate = async (cert_id) => {
    const toastId = toast.loading('Opening...');

    await openCertificate(cert_id).then((res) => {
      if (!res?.errros) {
        toast.success('Success!', {
          id: toastId,
        });
        const fileUrl = URL.createObjectURL(res.data);

        window.open(fileUrl, '_blank', 'noreferrer');
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  return (
    <>
      <Box sx={{
        width: '100%',
        padding: '.4rem .6rem',
        border: '1px solid rgba(0,0,0, .4)',
        borderRadius: '6px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        backgroundColor: '#fff',
        boxShadow: '0 0 9px -5px #000'
      }}
      >
        <Box sx={{ width: '100%' }}>
          <Typography variant="h6" sx={{ fontWeight: '600', textAlign: 'center' }}>{title}</Typography>
        </Box>
        {(type === 'send') && (
          <>
            {Object.entries(item).map(([key, value]) => (
              <Box sx={{ display: 'flex', gap: 3 }} key={key}>
                <Typography sx={{ textTransform: 'capitalize', fontWeight: '600' }}>
                  {changeKey(key)}
                  :
                </Typography>
                <Typography sx={{ overflowWrap: 'anywhere' }}>{value}</Typography>
              </Box>
            ))}
          </>
        )}
        {(type === 'pending') && (
          <>
            {Object.entries({
              requester: item?.requester,
              requested_at: item?.requested_at,
              requested_notice: item?.requested_notice,
              prices: item?.prices
            }).map(([key, value]) => {
              if (!value) return null;

              if ((key === 'prices')) {
                const priceArray = value?.filter(pr => ((pr?.price !== '1 EUR') && (pr?.supplier !== null)));

                if (!priceArray.length) return null;

                return (
                  <>
                    <Box sx={{ display: 'flex', gap: 3 }} key={key}>
                      <Typography sx={{ textTransform: 'capitalize', fontWeight: '600' }}>
                        {changeKey(key)}
                        :
                      </Typography>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Divider />
                      {value?.map((price, index) => (
                        <Box sx={{ display: 'grid', gridTemplateColumns: '1rem 1fr' }} key={price?.supplier}>
                          <Typography sx={{ fontWeight: '600' }}>
                            {index + 1}
                            .
                          </Typography>
                          <Box>
                            {Object.entries(price).map(([priceKey, priceVlv]) => (
                              <Box sx={{ display: 'flex', gap: 2 }} key={priceKey}>
                                <Typography sx={{ fontWeight: '600', textTransform: 'capitalize' }}>
                                  {priceKey === 'supplier' ? 'Vendor' : changeKey(priceKey)}
                                  :
                                </Typography>
                                <Typography sx={{ overflowWrap: 'anywhere', textTransform: 'capitalize' }}>
                                  {(priceKey === 'status') ? PRICES_STATUS?.find(prSt => prSt?.value === priceVlv)?.label
                                    : priceVlv}
                                </Typography>
                              </Box>
                            ))}
                            <Divider />
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </>

                );
              }

              return (
                <Box sx={{ display: 'flex', gap: 3 }} key={key}>
                  <Typography sx={{ textTransform: 'capitalize', fontWeight: '600' }}>
                    {changeKey(key)}
                    :
                  </Typography>
                  <Typography sx={{ overflowWrap: 'anywhere', textTransform: 'capitalize' }}>{value}</Typography>
                </Box>
              );
            })}
          </>
        )}
        {(type === 'approve') && (
          <>
            {Object.entries({
              approver: item?.approver,
              approved_at: item?.approved_at,
              approver_notice: item?.approver_notice,
              price: item?.price?.price,
              vendor: item?.price?.supplier,
              transaction_type: item?.price?.transaction_type,
              ...(item?.supplier_invoices?.length && { supplier_invoices: item?.supplier_invoices })
            }).map(([key, value]) => {
              if (!value) return null;
              if ((key === 'supplier_invoices')) {
                return (
                  <>
                    <Box sx={{ display: 'flex', gap: 3 }} key={key}>
                      <Typography sx={{ textTransform: 'capitalize', fontWeight: '600' }}>
                        {changeKey(key)}
                        :
                      </Typography>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Divider />
                      {value?.map((supplier, index) => (
                        <Box sx={{ display: 'grid', gridTemplateColumns: '1rem 1fr' }} key={supplier?.invoice_number}>
                          <Typography sx={{ fontWeight: '600' }}>
                            {index + 1}
                            .
                          </Typography>
                          <Box>
                            {Object.entries({
                              email: supplier?.email,
                              invoice_number: supplier?.invoice_number,
                              ship_to: supplier?.ship_to,
                              ship_via: supplier?.ship_via,
                              to: supplier?.to,
                            }).map(([spKey, spVlv]) => (
                              <Box sx={{ display: 'flex', gap: 2 }}>
                                <Typography sx={{ fontWeight: '600', textTransform: 'capitalize' }}>
                                  {changeKey(spKey)}
                                  :
                                </Typography>
                                <Typography
                                  onClick={() => {
                                    if ((spKey === 'invoice_number') && !previewLoading) {
                                      handlePreviewInvoice(supplier);
                                    }
                                  }}
                                  sx={{
                                    overflowWrap: 'anywhere',
                                    color: (spKey === 'invoice_number') ? 'blue' : 'rgba(76, 78, 100, 0.87)',
                                    ...((spKey === 'invoice_number') && { cursor: 'pointer' }),
                                    ...((spKey !== 'email') && { textTransform: 'capitalize', })
                                  }}
                                >
                                  {spVlv}
                                </Typography>
                              </Box>
                            ))}
                            <Divider />
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </>

                );
              }

              if ((key === 'vendor') && (typeof value === 'object')) {
                return (
                  <>
                    <Box sx={{ display: 'flex', gap: 3 }} key={key}>
                      <Typography sx={{ textTransform: 'capitalize', fontWeight: '600' }}>
                        {changeKey(key)}
                        :
                      </Typography>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Divider />
                      
                      {Object.entries(value).map(([spKey, spVlv]) => (
                        <Box sx={{ display: 'flex', gap: 3 }} key={spKey}>
                          <Typography sx={{ fontWeight: '600', textTransform: 'capitalize' }}>
                            {changeKey(spKey)}
                            :
                          </Typography>
                          <Typography sx={{ overflowWrap: 'anywhere' }}>
                            {spVlv}
                          </Typography>
                        </Box>
                      ))}
                      <Divider />
                    </Box>
                  </>
                );
              }

              return (
                <Box sx={{ display: 'flex', gap: 3 }} key={key}>
                  <Typography sx={{ textTransform: 'capitalize', fontWeight: '600' }}>
                    {changeKey(key)}
                    :
                  </Typography>
                  <Typography sx={{ overflowWrap: 'anywhere', textTransform: 'capitalize' }}>{value}</Typography>
                </Box>
              );
            })}
          </>
        )}
        {(type === 'onTheWay') && (
          <>
            {Object.entries({
              ordered: item?.ordered,
              ordered_at: item?.ordered_at,
              expected_day_delivery: item?.expected_day_delivery,
              vendor: item?.supplier,
              ttn_code: item?.ttn_code,
            }).map(([key, value]) => {
              if (!value) return null;

              return (
                <Box sx={{ display: 'flex', gap: 3 }} key={key}>
                  <Typography sx={{ textTransform: 'capitalize', fontWeight: '600' }}>
                    {changeKey(key)}
                    :
                  </Typography>
                  <Typography sx={{ overflowWrap: 'anywhere' }}>{value}</Typography>
                </Box>
              );
            })}
          </>
        )}
        {(type === 'delivered') && (
          <>
            {Object.entries({
              delivered: item?.delivered,
              delivered_at: item?.delivered_at,
              part_id: item?.part_id,
              storage: item?.storage,
              ...(item?.certificates?.length && { certificates: item?.certificates }),
              ...(item?.parts?.length && { parts: item?.parts })
            }).map(([key, value]) => {
              if (!value) return null;
              if ((key === 'certificates')) {
                return (
                  <>
                    <Box sx={{ display: 'flex', gap: 3 }} key={key}>
                      <Typography sx={{ textTransform: 'capitalize', fontWeight: '600' }}>
                        {changeKey(key)}
                        :
                      </Typography>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      {value?.map((file, index) => (
                        <Box sx={{ display: 'grid', gridTemplateColumns: '1rem 1fr' }}>
                          <Typography sx={{ fontWeight: '600' }}>
                            {index + 1}
                            .
                          </Typography>
                          <Box>
                            <Box sx={{ display: 'flex', gap: 2 }}>
                              <Typography sx={{ fontWeight: '600', textTransform: 'capitalize' }}>
                                Name
                                :
                              </Typography>
                              <Typography
                                onClick={() => handleOpenCertificate(file?.id)}
                                sx={{
                                  overflowWrap: 'anywhere',
                                  color: 'blue',
                                  cursor: 'pointer'
                                }}
                              >
                                {handleGetName(file?.certificate_path, 4)}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </>

                );
              }
              if (key === 'parts') {
                return (
                  <>
                    <Box sx={{ display: 'flex', gap: 3 }} key={key}>
                      <Typography sx={{ textTransform: 'capitalize', fontWeight: '600' }}>
                        Parts:
                      </Typography>
                    </Box>
                    <Box>
                      <Divider />
                      {value?.map((pt) => (
                        <Box sx={{ display: 'grid', gridTemplateColumns: '5rem 1fr' }} key={pt?.id}>
                          <Typography sx={{ fontWeight: '600', display: 'flex' }}>
                            ID
                            {' '}
                            {pt?.id}
                          </Typography>
                          <Box>
                            {Object.entries({
                              name: pt?.name,
                              quantity: pt?.quantity,
                              type: pt?.type,
                              aircraft: pt?.aircraft,
                              certificates: pt?.certificates
                            }).map(([ptKey, ptVlv]) => {
                              if (!ptVlv) return null;
                              if ((ptKey === 'certificates')) {
                                if (!ptVlv.length) return null;

                                return (
                                  <>
                                    <Box sx={{ display: 'flex', gap: 3 }} key={key}>
                                      <Typography sx={{ textTransform: 'capitalize', fontWeight: '600' }}>
                                        Certificates:
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <Divider />
                                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        {
                                        ptVlv?.map((cer, index) => (
                                          <Box sx={{ display: 'flex' }}>
                                            <Typography sx={{ fontWeight: '600' }}>
                                              {index + 1}
                                              .
                                            </Typography>
                                            <Typography
                                              sx={{
                                                overflowWrap: 'anywhere',
                                                color: 'blue',
                                                cursor: 'pointer',
                                              }}
                                              onClick={() => handleOpenCertificate(cer?.id)}
                                            >
                                              {handleGetName(cer?.certificate_path, 4)}
                                            </Typography>
                                          </Box>
                                        ))
                                      }
                                      </Box>
                                    </Box>
                                  </>
                                );
                              }

                              return (
                                <Box sx={{ display: 'flex', gap: 2 }} key={ptKey}>
                                  <Typography sx={{ fontWeight: '600', textTransform: 'capitalize' }}>
                                    {changeKey(ptKey)}
                                    :
                                  </Typography>
                                  <Typography sx={{ overflowWrap: 'anywhere', textTransform: 'capitalize' }}>{ptVlv}</Typography>
                                </Box>
                              );
                            })}
                            <Divider />
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </>
                );
              }

              return (
                <Box sx={{ display: 'flex', gap: 3 }} key={key}>
                  <Typography sx={{ textTransform: 'capitalize', fontWeight: '600' }}>
                    {changeKey(key)}
                    :
                  </Typography>
                  <Typography sx={{ overflowWrap: 'anywhere' }}>{value}</Typography>
                </Box>
              );
            })}
          </>
        )}
        {(type === 'installed') && (
          <>
            {!Array.isArray(item) ? Object.entries({
              installed: item?.installed,
              installed_at: item?.installed_at,
              aircraft: item?.aircraft,
              text: item?.text,
            }).map(([key, value]) => {
              if (!value) return null;

              return (
                <Box sx={{ display: 'flex', gap: 3 }} key={key}>
                  <Typography sx={{ textTransform: 'capitalize', fontWeight: '600' }}>
                    {changeKey(key)}
                    :
                  </Typography>
                  <Typography sx={{ overflowWrap: 'anywhere' }}>{value}</Typography>
                </Box>
              );
            }) : item.map((pt, index) => (
              <Box sx={{ display: 'grid', gridTemplateColumns: '1rem 1fr' }} key={pt?.part_id}>
                <Typography sx={{ fontWeight: '600' }}>
                  {index + 1}
                  .
                </Typography>
                <Box>
                  {Object.entries(pt).map(([ptKey, ptVlv]) => (
                    <Box sx={{ display: 'flex', gap: 2 }} key={ptKey}>
                      <Typography sx={{ fontWeight: '600', textTransform: 'capitalize' }}>
                        {changeKey(ptKey)}
                        :
                      </Typography>
                      <Typography sx={{ overflowWrap: 'anywhere', textTransform: 'capitalize' }}>{ptVlv}</Typography>
                    </Box>
                  ))}
                  <Divider />
                </Box>
              </Box>
            ))}
          </>
        )}
        {(type === 'decline') && (
          <>
            {Object.entries(item).map(([key, value]) => (
              <Box sx={{ display: 'flex', gap: 3 }} key={key}>
                <Typography sx={{ textTransform: 'capitalize', fontWeight: '600' }}>
                  {changeKey(key)}
                  :
                </Typography>
                <Typography sx={{ overflowWrap: 'anywhere' }}>{value}</Typography>
              </Box>
            ))}
          </>
        )}
      </Box>
      {scaleIcon && (<LinearScaleIcon sx={{ transform: 'rotate(90deg)', fontSize: '3rem', margin: '5px 0' }} />)}
    </>
  );
};

export default OrderHistoryItem;
