import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  defaultValues, schema
} from './schema';
import {
  Box, Card, Typography, Grid, CardContent, Button
} from '@mui/material';
import FormGroup from '../../../../../form/components/FormGroup';
import Input from '../../../../../form/components/Input';
import DatePicker from '../../../../../form/components/DatePicker';
import FieldError from '../../../../../form/components/FieldError';
import Upload from '../../../../../components/Upload';
import {
  fileName, 
} from './style';

const InstructorForm = ({ onSubmit }) => {
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  });

  const [file, setFile] = useState();

  const uploadFile = (e) => {
    setFile(e.target.files[0].name);

    setValue('file', e.target.files[0]);
  };

  return (
    <form>
      <Card sx={{
        mt: 3,
        padding: '1rem',
      }}
      >
        <Box>
          <Typography variant="h3">Create Document</Typography>
        </Box>

        <CardContent sx={{ mt: 5 }}>
          <Grid container spacing={5}>

            <Grid item xs={12} sm={4}>
              <FormGroup label="Document Name " required hasError={!!errors.document_name}>
                <Controller
                  name="document_name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Document Name"
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors.document_name} />
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormGroup label="Expiry Date" hasError={!!errors.expiry_date}>
                <Controller
                  name="expiry_date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      placeholderText="Enter Expiry Date"
                      dateFormat="yyyy-MM-dd"
                      placeholder="2023-01-01"
                      showMonthDropdown
                      showYearDropdown
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.expiry_date} />
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormGroup label="Document">
                <Controller
                  name="file"
                  control={control}
                  render={({ field }) => (
                    <Upload
                      {...field}
                      accept=".pdf"
                      handleUpload={(e) => {
                        field.onChange(e);
                        uploadFile(e);
                      }}
                      title="Upload Document"
                    />
                  )}
                />
                <FieldError error={errors.file} />
              </FormGroup>
              <Typography sx={fileName}>{file}</Typography>
            </Grid>

          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" size="large" onClick={handleSubmit(onSubmit)}>
              Save
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default InstructorForm;