import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import FiltrationDate from '../../../components/EditableTable/FiltrationDate';
import FiltrationInput from '../../../components/EditableTable/FiltrationInput';
import FiltrationSelect from '../../../components/EditableTable/FiltrationSelect';
import TextEdit from '../../../components/EditableTable/TextEdit';
import { TYPE_INPUT } from '../../../components/EditableTable/constants';
import {
  HeaderName, StyledDelete, StyledPencil,
} from '../../../components/EditableTable/styles';
import LinkButton from '../../../components/LinkButton';
import { OPTIONS_STATE } from '../../../constans/workOrder';
import { actionStyles } from './styles';

export const columns = ({
  handleFilter,
  filters,
  handleOpenDeleteModal,
  deletePermission,
  handleOpen,
}) => ([
  {
    flex: 0.1,
    field: 'wo_tasks_count',
    minWidth: 75,
    headerName: 'Task Count',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Tasks</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.wo_tasks_count}</Typography>
    ),
  },
  {
    flex: 0.1,
    maxWidth: 60,
    field: 'edit',
    headerName: '',
    sortable: false,
    renderCell: ({ row }) => (
      <Box sx={actionStyles}>
        <LinkButton target="_blank" path={`/maintenance/work-order/update/${row.id}`}>
          <StyledPencil />
        </LinkButton>
      </Box>
    ),
  },
  {
    flex: 0.2,
    field: 'name',
    minWidth: 250,
    headerName: 'Name',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Name</Typography>
        <FiltrationInput
          options={[]}
          defaultValue={filters.name || ''}
          handleFilter={handleFilter}
          placeholder="Name"
          value="name"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'name', row)}
        text={row.name}
        labelInput="Name"
        type={TYPE_INPUT}
      />
    ),
  },
  {
    flex: 0.2,
    field: 'designation',
    minWidth: 180,
    headerName: 'Designation',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Designation</Typography>
        <FiltrationInput
          options={[]}
          defaultValue={filters.designation || ''}
          handleFilter={handleFilter}
          placeholder="Designation"
          value="designation"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'designation', row)}
        text={row.designation}
        labelInput="Designation"
        type={TYPE_INPUT}
      />
    ),
  },
  {
    flex: 0.2,
    field: 'wo_date',
    minWidth: 180,
    headerName: 'WO Date',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>WO Date</Typography>
        <FiltrationDate
          onChange={(e) => handleFilter(e, 'wo_date', true)}
          value={filters.wo_date || null}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.wo_date}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'deadline',
    minWidth: 180,
    headerName: 'Due Date',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Due Date</Typography>
        <FiltrationDate
          onChange={(e) => handleFilter(e, 'deadline', true)}
          value={filters.deadline || null}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.deadline}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'due_fh',
    minWidth: 180,
    headerName: 'Due FH',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Due FH</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.due_fh}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'due_fc',
    minWidth: 180,
    headerName: 'Due FC',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Due FC</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.due_fc}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'due_date',
    minWidth: 180,
    headerName: 'WO Closed Date',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>WO Closed Date</Typography>
        <FiltrationDate
          onChange={(e) => handleFilter(e, 'due_date', true)}
          value={filters.due_date || null}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.due_date}</Typography>
    ),
  },
  {
    flex: 0.2,
    maxWidth: 110,
    field: 'man_hour_estimate',
    headerName: 'Estimate',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Estimate</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.man_hour_estimate}</Typography>
    ),
  },
  {
    flex: 0.2,
    maxWidth: 110,
    field: 'man_hour_real_job',
    headerName: 'Real Job',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Real Job</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.man_hour_real_job}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'state',
    minWidth: 180,
    headerName: 'State',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>State</Typography>
        <FiltrationSelect
          options={OPTIONS_STATE}
          defaultValue={filters.state || ''}
          handleFilter={handleFilter}
          placeholder="State"
          value="state"
        />
      </Box>
    ),
    renderCell: ({ row }) => {
      const text = OPTIONS_STATE.find(item => (
        +item.value === row.state
      ));

      return (
        <Typography>{text.label}</Typography>
      );
    },
  },
  {
    width: 85,
    field: 'delete',
    headerName: 'Delete',
    sortable: false,
    renderCell: ({ row }) => (
      <Box sx={actionStyles}>
        <StyledDelete onClick={() => {
          if (deletePermission) handleOpenDeleteModal(row.id); }}
        />
      </Box>
    ),
  },
]);
