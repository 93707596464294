
import {
  object, string
} from 'yup';

export const defaultValues = {
  name: '',
  type: '',
  legal_address: '',
  actual_address: '',
  delivery_address: '',
  phone: '',
  email: '',
  fax: ''
};

export const schema = object().shape({
  name: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('Name'),
  type: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('type'),
  legal_address: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('Legal Address'),
  actual_address: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('Actual Address'),
  delivery_address: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('Delivery Address'),
  phone: string()
    .nullable()
    .label('Phone'),
  email: string()
    .nullable()
    .label('Email'),
  fax: string()
    .nullable()
    .label('Fax'),
});
