import { RefreshRounded } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Card,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography, useMediaQuery
} from '@mui/material';
import Chip from '@mui/material/Chip';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Button from '../../../components/Button';
import EditableTable from '../../../components/EditableTable';
import TableFilterContainer from '../../../components/EditableTable/TableFilterContainer';
import BoxIcon from '../../../components/Icons/BoxIcon';
import ContentPageLoader from '../../../components/Loader/ContentPageLoader';
import { ORDER_STATUSES, PRIORITY } from '../../../constans/logistics';
import { useGetAircraftListQuery, useGetMyOrdersTableQuery } from '../../../store/session';
import { useDeleteInternalOrderMutation, useGetInternalMyOrdersTableQuery, useUpdateInternalAircraftMutation } from '../../../store/slices/internalLogisticsSlice';
import { useDeleteOrderMutation, useUpdateAircraftMutation } from '../../../store/slices/logisticsSlice';
import { useMenuToggle } from '../../../utils/hooks/useMenuToggle';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import { btnS, headerBtn, headerBtnS } from '../../Invoices/style';
import { deliveryTypes, myOrdersExternalAccess, myOrdersInternalAccess } from '../constants';
import {
  GreenButton,
  YellowButton
} from '../style';
import { columns } from './columns';
import AircraftDropDown from './components/AircraftDropDown';
import { internalColumns } from './internalColumns';
import NewDeliveryOrder from './NewDeliveryOrder';
import NewOrder from './NewOrder';
import OrderInfoModal from './OrderInfoModal';

const MyOrders = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { data: AircraftList, isLoading } = useGetAircraftListQuery();
  const [updateExternalTable] = useUpdateAircraftMutation();
  const [updateInternalTable] = useUpdateInternalAircraftMutation();
  const [currentRowTable, setCurrentRowTable] = useState(null);
  const [arFilter, setArFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [storageFilter, setStorageFilter] = useState(null);
  const [priorityFilter, setPriorityFilter] = useState(null);
  const [deliveryTypesFilter, setDeliveryTypesFilter] = useState(null);
  const [partNumber, setPartNumber] = useState('');
  const [partName, setPartName] = useState('');
  const [orderInfoState, setOrderInfoState] = useState({
    isOpen: false
  });
  const [typeMyOrders, setTypeMyOrders] = useState('all_orders');
  const [search, setSearch] = useState(null);
  const [openOrder, setOpenOrder] = useState(false);
  const [openDeliveryOrder, setOpenDeliveryOrder] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const tabValue = location.pathname.split('/')[3];
  const creator = usePermissions('logistics_order_creator');
  const AOGPermission = usePermissions('logistics_order_read_aog');
  const aircraftReservationCreator = usePermissions('logistics_aircraft_reservation');

  const internalCreator = usePermissions('internal_logistics_order_creator');
  const AOGInternalPermission = usePermissions('internal_logistics_order_read_aog');

  const ordersExternal = usePermissions(myOrdersExternalAccess);
  const ordersInternal = usePermissions(myOrdersInternalAccess);

  const [createAgain, setCreateAgain] = useState({
    isCreate: false,
    orderId: null
  });

  const {
    open, openMenu, closeMenu, anchorEl
  } = useMenuToggle();

  const maxWidth1000px = useMediaQuery('(max-width:1000px)');
  const maxWidth800px = useMediaQuery('(max-width:800px)');
  const maxWidth500px = useMediaQuery('(max-width:500px)');

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const aircraftOptions = [...(AircraftList || [])?.map((item) => ({
    value: item?.id,
    label: item?.aircraft_registration
  })),
  { value: null, label: 'N/A' }
  ];

  const setPriorityList = (list = []) => {
    if (tabValue === 'orders') {
      if (AOGPermission) {
        return list;
      }

      return list.filter((item) => item?.value !== 10);
    }
    if (AOGInternalPermission) {
      return list;
    }

    return list.filter((item) => item?.value !== 10);
  };

  const externalOrderStatuses = [
    ...ORDER_STATUSES,
    { label: '', value: '6', icon: (<Chip label="INSTALLED" size="small" sx={{ fontWeight: 'bolder', backgroundColor: '#2240fa', color: '#ffffff' }} />) }
  ];
  const internalOrderStatuses = [
    ...ORDER_STATUSES,
    { label: '', value: '7', icon: (<Chip label="DELIVERED" size="small" sx={{ fontWeight: 'bolder', backgroundColor: '#2240fa', color: '#ffffff' }} />) },
    { label: '', value: '6', icon: (<Chip label="INSTALLED" size="small" sx={{ fontWeight: 'bolder', backgroundColor: '#2240fa', color: '#ffffff' }} />) }
  ];

  const filterConfig = {
    afterDivider: false,
    items: [
      {
        value: (typeMyOrders === 'my_orders'),
        callback: (vlv) => setTypeMyOrders(vlv),
        closeCallback: () => setArFilter('all_orders'),
        typeFilter: 'Switch',
        icon: null,
      },
      {
        value: arFilter,
        callback: (e) => setArFilter(e.target.value),
        closeCallback: () => setArFilter(null),
        typeFilter: 'Select',
        icon: 'Airplane',
        options: aircraftOptions,
        placeholder: 'Select Aircraft'
      },
      {
        value: statusFilter, callback: (e) => setStatusFilter(e.target.value), closeCallback: () => setStatusFilter(null), typeFilter: 'Select', icon: 'ListStatus', options: tabValue === 'orders' ? externalOrderStatuses : internalOrderStatuses, placeholder: 'Select Status'
      },
      (tabValue !== 'orders' && {
        value: priorityFilter, callback: (e) => setPriorityFilter(e.target.value), closeCallback: () => setPriorityFilter(null), typeFilter: 'Select', icon: 'ListStatus', options: setPriorityList(PRIORITY), placeholder: 'Select Priority'
      }),
      (tabValue !== 'orders' && {
        value: deliveryTypesFilter, callback: (e) => setDeliveryTypesFilter(e.target.value), closeCallback: () => setDeliveryTypesFilter(null), typeFilter: 'Select', icon: 'ListStatus', options: deliveryTypes, placeholder: 'Select Order Type'
      }),
      (tabValue === 'orders' && {
        value: partNumber, callback: (e) => setPartNumber(e.target.value), closeCallback: () => setPartNumber(''), typeFilter: 'TextField', icon: 'Rename', options: ORDER_STATUSES, placeholder: 'Enter Part Number'
      }),
      (tabValue === 'orders' && {
        value: partName, callback: (e) => setPartName(e.target.value), closeCallback: () => setPartName(''), typeFilter: 'TextField', icon: 'Rename', options: ORDER_STATUSES, placeholder: 'Enter Part Name'
      })
    ]
  };

  const clearFilterState = () => {
    setArFilter(null);
    setStatusFilter(null);
    setPriorityFilter(null);
    setDeliveryTypesFilter(null);
    setStorageFilter(null);
    setPartNumber('');
    setPartName('');
  };

  const handleChangeTab = (_, value) => {
    clearFilterState();
    navigate(`/logistics/my-orders/${value}`);
    handleRefresh();
  };

  const handleCloseNewOrder = () => {
    setOpenOrder(false);
    setCreateAgain({
      isCreate: false,
      orderId: null
    });
    navigate('/logistics/my-orders/orders');
  };

  const handleOpenAllInfo = (id, type = tabValue) => {
    navigate(`/logistics/my-orders/${type}/order-info/${id}`);
    setOrderInfoState(prev => ({
      ...prev,
      isOpen: true
    }));
  };

  const handleCloseOrderInfo = () => {
    navigate(`/logistics/my-orders/${tabValue}`);
    setOrderInfoState(prev => ({
      ...prev,
      isOpen: false
    }));
  };

  const handleChangeSelect = async (vlv) => {
    const toastId = toast.loading('Loading...');

    const update = (tabValue === 'orders') ? updateExternalTable : updateInternalTable;

    const res = await update({
      id: currentRowTable?.id,
      data: {
        ...(tabValue === 'orders' && { aircraft_id: vlv }),
        ...(tabValue !== 'orders' && { aircraft_reservation: vlv })
      }
    });

    if (res?.error?.data) {
      toast.error(res?.error?.data?.message || 'Something went wrong', {
        id: toastId,
      });

      return;
    }

    handleRefresh();
    
    toast.success('Successfully!', {
      id: toastId,
    });
  };

  useEffect(() => {
    if (location.pathname.includes('create')) {
      const orderId = location?.pathname?.split('/')?.[5];

      if (+orderId) {
        setOpenOrder(true);
        setCreateAgain({
          isCreate: true,
          orderId
        });
      }
    }
    if (location.pathname.includes('order-info')) {
      const orderId = params?.id;
      const type = location.pathname.split('/')[3];

      if (+orderId) {
        handleOpenAllInfo(orderId, type);
      }
    }
  }, []);

  useEffect(() => {
    if (!ordersExternal && ordersInternal && !location.pathname.includes('delivery')) {
      navigate('/logistics/my-orders/delivery');
    }
  }, [location.pathname]);

  if (isLoading) {
    return <ContentPageLoader />;
  }

  return (
    <>
      <Card
        sx={{
          mt: 3,
          padding: '1rem'
        }}
      >
        <Box
          sx={{
            ...headerBtn,
            ...(maxWidth1000px && headerBtnS),
          }}
        >
          <Box sx={maxWidth1000px ? { alignSelf: 'start' } : {}}>
            <Typography noWrap variant="h3">
              My
              {tabValue === 'orders' ? ' External Orders' : ' Internal Orders'}
            </Typography>
          </Box>

          <Box>
            <Tabs
              variant="fullWidth"
              centered
              value={tabValue}
              aria-label="basic tabs example"
              onChange={handleChangeTab}
              sx={{ mt: 0, pt: 0 }}
            >
              <Tab label="External" sx={{ fontSize: '1.5rem', mr: 5 }} value="orders" disabled={!ordersExternal} />
              <Tab label="Internal" sx={{ fontSize: '1.5rem' }} value="delivery" disabled={!ordersInternal} />
            </Tabs>
          </Box>
          <Box sx={{ justifySelf: 'end', ...(maxWidth800px && { width: '100%' }) }}>
            {tabValue === 'orders' && (
            <Button
              disabled={!creator && !aircraftReservationCreator}
              endIcon={<BoxIcon />}
              sx={{ ml: 2, ...(maxWidth800px && btnS), ...GreenButton }}
              title="New External Order"
              onClick={() => setOpenOrder(!openOrder)}
            />
            )}
            {tabValue === 'delivery' && (
            <Button
              disabled={!internalCreator}
              endIcon={<BoxIcon />}
              sx={{ ml: 2, ...(maxWidth800px && btnS), ...GreenButton }}
              title="New Internal Order"
              onClick={() => setOpenDeliveryOrder(true)}
            />
            )}
          </Box>

        </Box>
      </Card>

      <Card
        sx={{
          mt: 3,
          padding: '0 1rem 1rem 1rem'
        }}
      >
        <TableFilterContainer filterConfig={filterConfig} />
      </Card>

      <Card
        sx={{
          mt: 3,
          padding: '1rem'
        }}
      >
        <Box sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          alignItems: 'center',

        }}
        >
          <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
            <TextField
              size="small"
              sx={{
                mr: 6, mb: 2, minWidth: '150px', maxWidth: '150px'
              }}
              placeholder="Search..."
              onChange={debounce((e) => setSearch(e.target.value), 400)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box />
          <Box sx={{
            mb: 2, display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifySelf: 'end'
          }}
          >
            <Button
              startIcon={<RefreshRounded />}
              sx={{ ml: 2, ...(maxWidth800px && btnS), ...YellowButton }}
              title="Refresh"
              onClick={handleRefresh}
            />
          </Box>
        </Box>
        <EditableTable
          useHook={tabValue === 'orders' ? useGetMyOrdersTableQuery : useGetInternalMyOrdersTableQuery}
          useHookUpdate={tabValue === 'orders' ? useUpdateAircraftMutation : useUpdateInternalAircraftMutation}
          useHookDelete={tabValue === 'orders' ? useDeleteOrderMutation : useDeleteInternalOrderMutation}
          columns={tabValue === 'orders' ? columns : internalColumns}
          sortByType="DESC"
          sortByActive="id"
          aircraftOptions={aircraftOptions}
          aircraftReservationCreator={aircraftReservationCreator}
          handleOpenAllInfo={handleOpenAllInfo}
          setCurrentRowTable={setCurrentRowTable}
          aircraftDropDownState={{ openMenu }}
          getRowHeight={() => 'auto'}
          getRowId={(row) => row?.id}
          tableParams={{
            aircraft_id: arFilter,
            status: statusFilter,
            ...(tabValue !== 'orders' && { storage: storageFilter }),
            ...(tabValue !== 'orders' && { priority: priorityFilter }),
            ...(tabValue !== 'orders' && { part_type: deliveryTypesFilter }),
            ...(tabValue === 'orders' && { part_number: partNumber }),
            ...(tabValue === 'orders' && { part_name: partName }),
            visible: typeMyOrders,
            search,
            refresh
          }}
          style={{
            '& .MuiDataGrid-columnHeaders': { borderRadius: 0 }
          }}
        />

      </Card>

      <AircraftDropDown
        {...{
          open, openMenu, closeMenu, anchorEl 
        }}
        options={aircraftOptions}
        activeSelect={aircraftOptions.find(({ label }) => ((label === currentRowTable?.aircraft_name) || (label === currentRowTable?.aircraft_reservation)))?.value}
        handleChangeSelect={handleChangeSelect}
      />

      <NewOrder
        open={openOrder}
        onClose={handleCloseNewOrder}
        aircraftOptions={aircraftOptions}
        createAgain={createAgain}
      />
      <NewDeliveryOrder
        open={openDeliveryOrder}
        aircraftOptions={aircraftOptions}
        handleRefresh={handleRefresh}
        priorityOptions={setPriorityList(PRIORITY)}
        onClose={() => {
          setOpenDeliveryOrder(false);
        }}
      />
      <OrderInfoModal
        open={orderInfoState.isOpen}
        onClose={handleCloseOrderInfo}
      />
    </>
  );
};

export default MyOrders;
