import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import React from 'react';
import TextEdit from '../../../components/EditableTable/TextEdit';
import { TYPE_INPUT } from '../../../components/EditableTable/constants';
import { HeaderName, StyledDelete, StyledPencil } from '../../../components/EditableTable/styles';
import { GreenButton, RedButton, TextWrap } from '../../Logistics/style';
import { INVOICE_TYPE, PAYMENT_METHOD } from '../constants';
import Button from '../../../components/Button';

export const columns = ({
  hasPermission,
  handleOpenDeleteModal,
  handlePreviewFile,
  handleToggleInvoiceStatus,
  handleOpen,
  handleTotalPrice,
  handleOpenInvoiceTransaction,
  handleTransactionPortalFileDrawer
}) => ([
  {
    flex: 0.01,
    field: 'id',
    minWidth: 140,
    headerName: '',
    renderHeader: () => (
      <Box>
        <Typography style={HeaderName}>File</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <PictureAsPdfIcon sx={GreenButton} onClick={() => handlePreviewFile(row.id)} />
    ),
  },
  {
    flex: 0.1,
    field: 'invoice_number',
    minWidth: 140,
    headerName: 'Invoice Number',
    renderHeader: () => (
      <Box>
        <Typography style={HeaderName}>Invoice Number</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row?.invoice_number}</Typography>
    ),
  },
  {
    flex: 0.05,
    field: 'invoice_status',
    minWidth: 140,
    headerName: 'Invoice Status',
    renderHeader: () => (
      <Box>
        <Typography style={HeaderName}>Invoice Status</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography sx={{ userSelect: 'none', cursor: 'pointer' }} onClick={() => handleToggleInvoiceStatus(row?.id)}>
        {row?.invoice_status === 'paid'
          ? (
            <Tooltip
              placement="top-end"
              variant="soft"
              size="md"
              title={(
                <Box>
                  <Typography sx={{ color: '#fff' }}>{`Payment Date: ${row?.payment_date}`}</Typography>
                </Box>
            )}
            >
              <Chip label="Paid" color="success" variant="outlined" size="small" sx={{ fontWeight: 800 }} />
            </Tooltip>
          )
          : <Chip label="Unpaid" color="error" variant="outlined" size="small" sx={{ fontWeight: 800 }} />}
      </Typography>
    ),
  },
  {
    flex: 0.1,
    field: 'paid',
    minWidth: 140,
    headerName: 'Paid',
    renderHeader: () => (
      <Box>
        <Typography style={HeaderName}>Paid</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'paid', row)}
        text={row.paid}
        labelInput="Comments from Tax Office"
        type={TYPE_INPUT}
      />
    ),
  },
  {
    flex: 0.15,
    field: 'price',
    minWidth: 140,
    headerName: 'Price',
    renderHeader: () => (
      <Box>
        <Typography style={HeaderName}>Total Price</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box sx={{ width: '100%' }}>
        <Chip
          label={(
            <Tooltip title={`${row?.price} ${row?.currency}`}>
              <Box sx={{
                display: 'flex', alignItems: 'center', gap: '5px', padding: '2px 4px'
              }}
              >
                <Typography sx={{ fontWeight: 600, overflow: 'hidden', textOverflow: 'ellipsis' }}>{`${row?.price} ${row?.currency}`}</Typography>
                <StyledPencil sx={{ fontSize: '1.1rem' }} />
              </Box>
            </Tooltip>

          )}
          variant="outlined"
          onClick={() => handleTotalPrice(row)}
          size="small"
          sx={{ cursor: 'pointer' }}
        />
      </Box>
      // <Typography>{`${row?.price} ${row?.currency}`}</Typography>
    ),
  },
  {
    flex: 0.1,
    field: 'customer_name',
    minWidth: 140,
    headerName: 'Customer Name',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Customer</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography sx={TextWrap}>{row?.customer_name}</Typography>
        <Typography sx={TextWrap}>{row?.customer_address}</Typography>
        <Typography sx={TextWrap}>{row?.customer_tax}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.1,
    field: 'payment_method',
    minWidth: 120,
    headerName: 'Payment Method',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Payment Method</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography>{PAYMENT_METHOD.find((item) => item?.value === row.payment_method)?.label}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.1,
    field: 'invoice_type',
    minWidth: 120,
    headerName: 'Invoice Type',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Invoice Type</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography>{INVOICE_TYPE.find((item) => item?.value === row.invoice_type)?.label}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.1,
    field: 'completion_date',
    minWidth: 140,
    headerName: 'Completion Date',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Completion Date</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography sx={TextWrap}>{row?.completion_date}</Typography>
      </Box>
    ),
  }, // Completion Date
  {
    flex: 0.1,
    field: 'issue_date',
    minWidth: 120,
    headerName: 'Issue Date',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Issue Date</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography>{row?.issue_date}</Typography>
      </Box>
    )
  }, // Issue Date
  {
    flex: 0.1,
    field: 'due_date',
    minWidth: 120,
    headerName: 'Due Date',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Due Date</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography>{row?.due_date}</Typography>
      </Box>
    )
  }, // Due Date
  {
    // flex: 0.2,
    field: 'transaction_id',
    minWidth: 120,
    headerName: 'Transaction',
    sortable: false,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Transaction</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Button
          sx={row?.transaction_id ? (GreenButton) : (RedButton)}
          size="small"
          onClick={() => {
            handleTransactionPortalFileDrawer(true, row?.transaction_id, 1, 'Transaction', row?.transaction_id);
            handleOpenInvoiceTransaction({ item_id: row.id, transaction_id: row.transaction_id });
          }}
        >
          Transaction
        </Button>
      </Box>
    )
  }, // Transaction
  {
    width: 100,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
        <StyledDelete onClick={() => {
          if (hasPermission) handleOpenDeleteModal(row.id);
        }}
        />
      </Box>
    ),
  }, // actions
]);
