import {
  Box,
  MenuItem,
  TextField
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { forwardRef } from 'react';
import { TextWithIcon } from '../../../pages/Logistics/style';
import { useHasError } from '../FormGroup';

const Select = forwardRef(
  ({
    options = [],
    placeholder,
    isLoading,
    customSx,
    width,
    selectProps = {},
    ...props
  }, ref) => {
    const hasError = useHasError();
    const theme = useTheme();

    const renderValue = (selectedValue) => {
      if (selectedValue === '' || selectedValue === null || selectedValue === undefined) {
        return (
          <Box component="span" sx={{ color: theme.palette.text.disabled }}>
            {placeholder || 'Select...'}
          </Box>
        );
      }

      const selectedItem = options?.find((option) => option?.value?.toString() === selectedValue.toString());

      return (
        <Box sx={{ ...(selectedItem?.icon && TextWithIcon), fontWeight: 'bold' }}>
          {selectedItem?.icon}
          {selectedItem?.label}
        </Box>
      );
    };

    return (
      <TextField
        {...props}
        select
        fullWidth
        size="small"
        variant="outlined"
        error={hasError}
        SelectProps={{
          displayEmpty: true,
          sx: customSx,
          renderValue,
          MenuProps: {
            sx: {
              '.MuiBackdrop-root': {
                background: 'transparent',
              }
            },
          },
          ...selectProps
        }}
        inputRef={ref}
      >
        <MenuItem value="" style={{ display: 'none' }}>
          <Box component="span" sx={{ color: '#9cabbd' }}>
            {placeholder || 'Select...'}
          </Box>
        </MenuItem>
        {isLoading && <MenuItem>Loading...</MenuItem>}
        {options?.map(({ label, value, icon }) => (
          <MenuItem value={value} key={value} sx={{ ...(icon && TextWithIcon ), fontWeight: 'bold' }}>
            {icon}
            {label}
          </MenuItem>
        ))}
      </TextField>
    );
  }
);

export default Select;
