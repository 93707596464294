import { useMemo, useState } from 'react';
import { DEFAULT_YEAR } from '../../../../../SabCrewRoster/constants';
import moment from 'moment';
import { MAX_YEAR } from '../../../../../../components/ButtonSelect';
import { useGetBankAccountListQuery } from '../../../../../../store/session';
import { getOptions } from '../../../../../../utils/getOptions';
import {
  useDeleteMonthlyPdfMutation,
  useDeleteTransactionsUploadMutation, useDownloadMonthlyPdfMutation,
  useGetMonthlyTransactionsReportQuery, useGetTransactionUploadFileMutation, useUploadMonthlyPdfMutation,
  useUploadTransactionsMutation
} from '../../../../../../store/slices/financeSlice';
import { toast } from 'react-hot-toast';

export const useMonthReport = () => {
  // Year
  const [year, setYear] = useState(DEFAULT_YEAR);
  const urlParams = new URLSearchParams(window.location.search);
  const selectedYear = `01-01-${Math.min(+(urlParams.get('year') || moment().format('YYYY')), MAX_YEAR - 1)}`;
  const defaultYears = [
    moment(selectedYear, 'MM-DD-YYYY').subtract(1, 'years').format('YYYY'),
    moment(selectedYear, 'MM-DD-YYYY').format('YYYY'),
    moment(selectedYear, 'MM-DD-YYYY').add(1, 'years').format('YYYY'),
  ];
  const [years, setYears] = useState(defaultYears);

  const handleSetYear = (item) => {
    setYear(item);
    if (+item === Math.min(...years)) {
      setYears((prevValue) => prevValue.map(value => `${+value - 1}`));
    }
    if (+item === Math.max(...years) && +item < MAX_YEAR) {
      setYears((prevValue) => prevValue.map(value => `${+value + 1}`));
    }
  };

  // Bank
  const [bank, setBank] = useState(null);
  const [bankName, setBankName] = useState(null);

  // Accounts
  const [account, setAccount] = useState(null);
  const { data: accounts, isLoading: accountsLoading } = useGetBankAccountListQuery(bank, {
    refetchOnMountOrArgChange: true,
    skip: !bank
  });
  const accountList = useMemo(() => getOptions(accounts), [accounts]);

  // Reports Data
  const {
    data: reportData, isLoading: rLoading, isFetching: rFetching, refetch: refetchReportData
  } = useGetMonthlyTransactionsReportQuery({ bank_id: bank, account_number_id: account, year }, {
    refetchOnMountOrArgChange: true,
    skip: !bank || !account || !year
  });

  const reportsLoading = rLoading || rFetching;

  const handleMonthData = (month) => {
    if (reportData && !reportsLoading) {
      const monthData = reportData.find((e) => e.month === month);

      return monthData;
    }

    return null;
  };

  // Uploads
  const [upload] = useUploadTransactionsMutation();
  const handleUploadTransactions = async (e, month, upload_id = null) => {
    const toastId = toast.loading('Loading...');

    const formData = new FormData();
    formData.append('bank_id', bank);
    formData.append('bank_account_number_id', account);
    formData.append('file', e.target.files[0]);
    formData.append('monthly', 1);
    formData.append('year', year);
    formData.append('month', month);
    if (upload_id) {
      formData.append('upload_id', upload_id);
    }

    const res = await upload(formData);

    if (res.error) {
      toast.error(res.error.data?.message || 'Something went wrong', {
        id: toastId,
      });

      return;
    }

    refetchReportData();
    toast.success('The transactions has been imported!', {
      id: toastId,
    });
  };

  const [openDeleteModal, setOpenDeleteMoadal] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [deleteReport] = useDeleteTransactionsUploadMutation();
  const handleDeleteReport = async (id) => {
    setCurrentId(id);
    setOpenDeleteMoadal(true);
  };

  const handleCloseDeleteModel = () => {
    setOpenDeleteMoadal(false);
  };

  const deleteFile = async () => {
    if (currentId) {
      const toastId = toast.loading('Deleting...');

      await deleteReport(currentId);

      refetchReportData();
      toast.success('Report deleted!', {
        id: toastId,
      });

      setCurrentId(null);
      handleCloseDeleteModel();
    }
  };

  const [download, { isLoading: fileLoading }] = useGetTransactionUploadFileMutation();
  const [deleteUpload, setDeleteUpload] = useState(null);
  const handleDownloadFile = async (upload_id) => {
    if (fileLoading) return;
    setDeleteUpload(upload_id);
    const toastId = toast.loading('Opening...');

    await download(upload_id).then((res) => {
      if (!res?.errros) {
        toast.success('Success!', {
          id: toastId,
        });
        const fileUrl = URL.createObjectURL(res.data);
        window.open(fileUrl, '_blank', 'noreferrer');
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });

    setDeleteUpload(null);
  };

  const fileDownloading = (upload_id) => fileLoading && upload_id === deleteUpload;

  const [uploadPdfId, setUploadPdfId] = useState(null);
  const [uploadPdf, { isLoading: pdfIsLoading }] = useUploadMonthlyPdfMutation();

  const handleUploadPdf = async (e, upload_id = null, month = null) => {
    const toastId = toast.loading('Loading...');
    setUploadPdfId(upload_id);

    const formData = new FormData();
    if (upload_id) {
      formData.append('upload_id', upload_id);
    }
    if (month) {
      formData.append('bank_id', bank);
      formData.append('bank_account_number_id', account);
      formData.append('year', year);
      formData.append('month', month);
    }
    formData.append('file', e.target.files[0]);

    const res = await uploadPdf({ data: formData });

    if (res.error) {
      toast.error(res.error.data?.message || 'Something went wrong', {
        id: toastId,
      });

      setUploadPdfId(null);

      return;
    }

    refetchReportData();
    toast.success('The transactions has been imported!', {
      id: toastId,
    });

    setUploadPdfId(null);
  };
  const pdfLoading = (upload_id) => pdfIsLoading && uploadPdfId === upload_id;

  const [downloadPdf, { isLoading: pdfDLoading }] = useDownloadMonthlyPdfMutation();
  const [pdfDownloadId, setPdfDownloadId] = useState(null);
  const handleDownloadPdf = async (upload_id) => {
    if (pdfDLoading) return;
    setPdfDownloadId(upload_id);
    const toastId = toast.loading('Opening...');

    await downloadPdf(upload_id).then((res) => {
      if (!res?.errros) {
        toast.success('Success!', {
          id: toastId,
        });
        const fileUrl = URL.createObjectURL(res.data);
        window.open(fileUrl, '_blank', 'noreferrer');
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });

    setPdfDownloadId(null);
  };
  const pdfDownloadLoading = (upload_id) => pdfDLoading && pdfDownloadId === upload_id;

  const [openDeletePdfModal, setOpenDeletePdfModal] = useState(false);
  const [deletePdfMoadlId, setDeletePdfMoadlId] = useState(false);
  const [deleteMonthlyPdf] = useDeleteMonthlyPdfMutation();
  const handleCloseDeletePdfModal = () => {
    setDeletePdfMoadlId(null);
    setOpenDeletePdfModal(false);
  };

  const handleOpenDeletePdfModal = (upload_id) => {
    setDeletePdfMoadlId(upload_id);
    setOpenDeletePdfModal(true);
  };
  const handleDeleteMonthlyPdf = async () => {
    handleCloseDeletePdfModal();
    if (deletePdfMoadlId) {
      const toastId = toast.loading('Deleting...');

      await deleteMonthlyPdf(deletePdfMoadlId).then((res) => {
        if (!res?.errros) {
          toast.success('Deleted!', {
            id: toastId,
          });
        } else {
          toast.error('Something went wrong.', {
            id: toastId,
          });
        }
      });

      refetchReportData();
    }
  };
  const [openTransactionsModal, setOpenTransactionsModal] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const handleOpenTransactionsModal = (data) => {
    setTransactions(data);
    setOpenTransactionsModal(true);
  };
  const handleCloseTransactionsModal = () => {
    setOpenTransactionsModal(false);
    setTransactions([]);
  };

  return {
    years,
    year,
    handleSetYear,
    accountList,
    accountsLoading,
    bank,
    bankName,
    setBank,
    setBankName,
    account,
    setAccount,
    reportsLoading,
    reportData,
    handleMonthData,
    handleUploadTransactions,
    handleDeleteReport,
    openDeleteModal,
    handleCloseDeleteModel,
    deleteFile,
    handleDownloadFile,
    fileDownloading,
    handleUploadPdf,
    pdfLoading,
    handleDownloadPdf,
    pdfDownloadLoading,
    handleDeleteMonthlyPdf,
    openDeletePdfModal,
    handleCloseDeletePdfModal,
    handleOpenDeletePdfModal,
    openTransactionsModal,
    transactions,
    handleOpenTransactionsModal,
    handleCloseTransactionsModal,
  };
};
