import { yupResolver } from '@hookform/resolvers/yup';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import TaskIcon from '@mui/icons-material/Task';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box, Card, Grid, Tab, Typography, useMediaQuery,
} from '@mui/material';
import FileSaver from 'file-saver';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../../components/Button';
import ContentPageLoader from '../../../../components/Loader/ContentPageLoader';
import {
  useGetIrsPdfMutation,
  useGetViewPdfMutation,
  useGetWorkOrderDetailMutation,
  useUpdateWorkOrderMutation,
  useWOTaskExportToExcelMutation
} from '../../../../store/session';
import { usePermissions } from '../../../../utils/hooks/usePermissions';
import { stringifyLocationSearch } from '../../../../utils/locationSearch';
import FormDescription from './FormDescription';
import TaskListTable from './TaskListTable';
import WorkOrderFiles from './WorkOrderFiles';
import { defaultValues, schema } from './schema';
import {
  backIcon,
  buttonBlock,
  headerSx,
  // ! Need in the future
  // cancelBtn,
  saveBtn,
  updateBtn
} from './style';

const CreateWorkOrder = () => {
  const [activeTab, setActiveTab] = useState('1');
  const [tableSort, setTableSort] = useState(null);
  const [tableFilter, setTableFilter] = useState(null);
  const [pageOptions, setPageOptions] = useState(null);
  const navigate = useNavigate();
  const params = useParams();

  const [getWorkOrder, { data: workOrder, isLoading: isWorkOrder }] = useGetWorkOrderDetailMutation();
  const [updateWorkOrder, { isLoading: isLoadingUpdate }] = useUpdateWorkOrderMutation();
  const [getIrs, { isLoading: isDownloadingIrs }] = useGetIrsPdfMutation();
  const [getView, { isLoading: isDownloadingView }] = useGetViewPdfMutation();
  const [getExcel, { isLoading: isExportDataLoading }] = useWOTaskExportToExcelMutation();

  const hasPermissionsWoEdit = usePermissions(['wo_creator', 'wo_superuser']);
  const hasPermissionsWoSuperuser = usePermissions('wo_superuser');

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    reset,
    trigger,
    formState: { dirtyFields },
  } = methods;

  const maxWidth768px = useMediaQuery('(max-width:768px)');

  const handleUpdateValues = (result) => {
    Object.entries(result).forEach(
      ([name, value]) => {
        if (name === 'closed_by' && value === 0) setValue(name, '');
        else if (name === 'ods' && value === 0) setValue(name, null); 
        else setValue(name, value);
      }
    );
  };

  const handleFlightData = async () => {
    const { data: result } = await getWorkOrder(params.id);

    if (result) {
      handleUpdateValues(result);
    }
  };

  const handleDownloadIrsPDF = async () => {
    const wo_id = params.id;
    if (wo_id) {
      const toastId = toast.loading('Loading...');
      await getIrs({ id: wo_id }).then((res) => {
        if (!res?.error) {
          toast.success('Success!', {
            id: toastId,
          });
          const fileUrl = URL.createObjectURL(res.data);
          window.open(fileUrl, '_blank', 'noreferrer');
        } else {
          toast.error('Something went wrong.', {
            id: toastId,
          });
        }
      });
    }
  };

  const handleDownloadViewPDF = async () => {
    const wo_id = params.id;
    if (wo_id) {
      const toastId = toast.loading('Loading...');
      await getView({ id: wo_id }).then((res) => {
        if (!res?.error) {
          toast.success(res.data.message, {
            duration: 6000,
            id: toastId,
          });

          setActiveTab('2');
        } else {
          toast.error('Something went wrong.', {
            id: toastId,
          });
        }
      });
    }
  };

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');
    const formattedData = Object.keys(dirtyFields).reduce((res, key) => ({ ...res, [key]: data[key] }), {});
    const res = await updateWorkOrder({ data: formattedData, id: params.id });

    if (res.error) {
      toast.error(res.error.data?.message, {
        id: toastId,
      });

      return;
    }

    reset(res.data);

    await handleFlightData();
    trigger();

    toast.success('Successful!', {
      id: toastId,
    });
  };

  const submitExportToExcel = async () => {
    const wo_id = params.id;
    let excelData = {
      wo_id
    };
    
    if (tableSort) {
      excelData = {
        ...excelData,
        sortDir: tableSort?.sort?.toUpperCase(),
        sortField: tableSort?.field
      };
    } else {
      excelData = { ...excelData, ...{ sortDir: 'DESC', sortField: 'performed' } };
    }

    if (tableFilter) {
      excelData = { ...excelData, ...tableFilter };
    }

    if (pageOptions) {
      excelData = { ...excelData, ...pageOptions };
    } else {
      excelData = { ...excelData, ...{ page: 1, pageSize: 15 } };
    }
    const res = await getExcel(stringifyLocationSearch(excelData));

    FileSaver.saveAs(res?.data, workOrder?.name);
  };

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleBack = () => {
    navigate('/maintenance/work-order/1');
  };

  useEffect(() => {
    handleFlightData();
  }, []);

  if (isWorkOrder) return <ContentPageLoader />;

  return (
    <Box>
      <Box sx={headerSx}>
        <ChevronLeftIcon sx={backIcon} onClick={handleBack} />
        <Typography variant="h4">
          {workOrder?.name}
          {' '}
          {hasPermissionsWoSuperuser && 'SUPERUSER MODE'}
        </Typography>
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={buttonBlock}>
            <Grid
              container
              xs={12}
              sx={{
                display: 'flex', justifyContent: 'space-start', gap: '10px', mt: '20px',
              }}
            >
              <Grid item xs={maxWidth768px ? 12 : 2}>
                <Button disabled={isLoadingUpdate || !hasPermissionsWoEdit} sx={updateBtn} type="submit" title="Save" />
              </Grid>
              {/* ! Need in the future  */}
              {/* <Grid item xs={maxWidth768px ? 12 : 2}>
                <Button sx={saveBtn} title="Refresh" />
              </Grid> */}
              <Grid item xs={maxWidth768px ? 12 : 2}>
                <Button
                  sx={saveBtn}
                  title="PDF View"
                  disabled={isDownloadingView}
                  onClick={() => handleDownloadViewPDF()}
                />
              </Grid>
              <Grid item xs={maxWidth768px ? 12 : 2}>
                <Button
                  sx={saveBtn}
                  title="I.R.S. PDF"
                  disabled={isDownloadingIrs}
                  onClick={() => handleDownloadIrsPDF()}
                />
              </Grid>
              <Grid item xs={maxWidth768px ? 12 : 2}>
                <Button
                  disabled={isExportDataLoading}
                  sx={updateBtn}
                  onClick={submitExportToExcel}
                  title="Export to Excel"
                />
              </Grid>
              {/* ! Need in the future  */}
              {/* <Grid item xs={maxWidth768px ? 12 : 2}>
                <Button sx={cancelBtn} onClick={handleBack} title="Cancel" />
              </Grid> */}
            </Grid>
          </Box>

          <FormDescription hasPermissionsWoEdit={hasPermissionsWoEdit} workOrder={workOrder} />
        </form>
      </FormProvider>

      <Card sx={{
        mt: '10px'
      }}
      >
        <TabContext value={activeTab}>
          <TabList onChange={handleChangeTab} aria-label="tabs" xs={6}>
            <Tab value="1" label="Task List" icon={<TaskIcon />} />
            <Tab value="2" label="PDF Views" icon={<FileOpenIcon />} />
          </TabList>
          {workOrder && (
            <TabPanel value="1">
              <TaskListTable woId={workOrder?.aircraft_id} hasPermissionsWoEdit={hasPermissionsWoEdit} workOrder={workOrder} handleFlightData={handleFlightData} setTableSort={setTableSort} setPageOptions={setPageOptions} setTableFilter={setTableFilter} />
            </TabPanel>
          )}
          <TabPanel value="2">
            <WorkOrderFiles work_order_id={workOrder?.id} />
          </TabPanel>
        </TabContext>
      </Card>
    </Box>
  );
};

export default CreateWorkOrder;
