import {
  Box, Card, Divider, Grid, Typography, useMediaQuery
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Button from '../../../../../components/Button';
import EditableTable from '../../../../../components/EditableTable';
import {
  useDeleteWorkOrderTaskPartsPairMutation,
  useGetWorkOrderTaskPartsPairQuery, useUpdateWorkOrderTaskPartsPairMutation
} from '../../../../../store/session';
import { boxTitle, cardInfo } from '../../../../Maintenance/MaintenanceACFT/styles';
import { columns } from './columns';
import { changeCurrentPartsData } from './utils';

const Table = (props) => {
  const {
    open, id, handleSetTypeBody, optionsCrewList, actionName, currentActionParts
  } = props;

  const [tableState, setTableState] = useState(null);
  const [currentPartOnState, setCurrentPartOnState] = useState('');
  const [currentPartOffState, setCurrentPartOffState] = useState('');
  const maxWidth425px = useMediaQuery('(max-width:425px)');
  const tableParams = { action_id: id };

  const handleSetTableState = (data) => {
    setTableState(data);
  };

  const handleOpenUpdateDrawer = (item) => {
    handleSetTypeBody('create', item);
  };

  useEffect(() => {
    if (currentActionParts && tableState) {
      const changedCurrentPartOn = changeCurrentPartsData(currentActionParts?.part_on, tableState);
      const changedCurrentPartOff = changeCurrentPartsData(currentActionParts?.part_off, tableState);
      
      setCurrentPartOnState(changedCurrentPartOn);
      setCurrentPartOffState(changedCurrentPartOff);
    }
  }, [tableState, currentActionParts]);

  return (
    <>
      <Grid sx={{
        padding: '24px'
      }}
      >
        <Box sx={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr'
        }}
        >
          <Box>
            <Typography variant="h4">
              Action:
              {' '}
              {actionName}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h4" textAlign="center">Part Off & Part On Table</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button title="+ Add" onClick={() => handleSetTypeBody('create')} />
          </Box>
        </Box>
      </Grid>
      <Grid sx={{
        padding: '24px',
        display: 'flex'
      }}
      >
        {currentPartOffState && (
        <Card sx={{ width: '268px', ...(maxWidth425px && { width: '100%' }) }}>
          <Card sx={cardInfo}>
            <Box sx={boxTitle}>
              <Box>
                <Typography noWrap sx={{ textTransform: 'capitalize' }}>
                  Part Off:
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ minWidth: '133px' }}>
                <Typography sx={{ textTransform: 'capitalize', fontWeight: 600 }}>
                  {currentPartOffState}
                </Typography>
              </Box>
            </Box>
          </Card>
        </Card>
        )}
        {currentPartOnState && (
        <Card sx={{ mr: 5, width: '268px', ...(maxWidth425px && { width: '100%' }) }}>
          <Card sx={cardInfo}>
            <Box sx={boxTitle}>
              <Box>
                <Typography noWrap sx={{ textTransform: 'capitalize' }}>
                  Part On:
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ minWidth: '133px' }}>
                <Typography sx={{ textTransform: 'capitalize', fontWeight: 600 }}>
                  {currentPartOnState}
                </Typography>
              </Box>
            </Box>
          </Card>
        </Card>
        )}
      </Grid>
      <Grid sx={{
        padding: '0 24px'
      }}
      >
        {open && (
        <EditableTable
          useHook={useGetWorkOrderTaskPartsPairQuery}
          useHookDelete={useDeleteWorkOrderTaskPartsPairMutation}
          useHookUpdate={useUpdateWorkOrderTaskPartsPairMutation}
          columns={columns}
          hasPermissionsWoEdit
          tableParams={tableParams}
          optionsCrewList={optionsCrewList}
          getRowHeight={() => 'auto'}
          getRowId={(row) => row?.id}
          sortByType="DESC"
          sortByActive="performed_by"
          setTableDataOut={handleSetTableState}
          handleOpenUpdateDrawer={handleOpenUpdateDrawer}
        />
        )}
      </Grid>
    </>
  );
};

export default Table;
