import {
  array, boolean, number, object, string
} from 'yup';

export const defaultValues = {
  notice: '',
  orderType: '',
  prices: [
    {
      orderType: '',
      amount: null,
      currency: '11',
      supplier_id: null,
      suggested: false,
      status: '',
      transaction_type: '',
      address_from: '',
      address_to: '',
    }
  ]
};

export const deliverySchema = object().shape({
  notice: string()
    .nullable()
    .label('Notice'),
  prices: array().of(object({}).shape({
    amount: number().required('${label} cannot be blank.').label('Amount'),
    currency: string().required('${label} cannot be blank.').label('Currency'),
    suggested: boolean().required('${label} cannot be blank.').label('Suggested'),
    address_from: string().nullable().label('Address From'),
    address_to: string().nullable().label('Address To'),
    supplier_id: number().nullable()
  }))
});

export const schema = object().shape({
  notice: string()
    .nullable()
    .label('Notice'),
  orderType: string(),
  prices: array().of(object().shape({
    orderType: string(),
    amount: string().when('orderType', {
      is: (val) => ['part', 'instrument', 'consumable', 'delivery', 'Other', 'other'].includes(val),
      then: string().required('${label} cannot be blank.'),
      otherwise: string().nullable()
    }).label('Amount'),
    currency: string().required('${label} cannot be blank.').label('Currency'),
    supplier_id: number().when('orderType', {
      is: (val) => ['part', 'instrument', 'consumable', 'delivery', 'Other', 'other'].includes(val),
      then: number().required('${label} cannot be blank.'),
      otherwise: number().nullable()
    }).label('Supplier'),
    address_from: string().when('orderType', {
      is: (val) => ['Overhaul', 'Repair'].includes(val),
      then: string().min(2, 'Min 2 length').required('${label} cannot be blank.'),
      otherwise: string().nullable()
    }).label('Address From'),
    address_to: string().when('orderType', {
      is: (val) => ['Overhaul', 'Repair'].includes(val),
      then: string().min(2).required('${label} cannot be blank.'),
      otherwise: string().nullable()
    }).label('Address To'),
    suggested: boolean().required('${label} cannot be blank.').label('Suggested'),
    status: string().when('orderType', {
      is: (val) => ['part', 'instrument', 'consumable', 'other'].includes(val),
      then: string().required('${label} cannot be blank.'),
      otherwise: string().nullable()
    }).label('Status'),
    transaction_type: string().when('orderType', {
      is: (val) => ['part', 'instrument', 'consumable', 'other'].includes(val),
      then: string().required('${label} cannot be blank.'),
      otherwise: string().nullable()
    }).label('Transaction Type')
  })),
});

export const PRICES_STATUS = [
  { label: 'NE', value: 'new' },
  { label: 'FN', value: 'factory_new' },
  { label: 'NS', value: 'new_surplus' },
  { label: 'OH', value: 'overhauled' },
  { label: 'SV', value: 'serviceable' },
  { label: 'INS/TES', value: 'inspected_tested' },
  { label: 'MOD', value: 'modified' },
  { label: 'REP', value: 'repaired' },
  { label: 'US', value: 'unserviceable' },
  { label: 'AR', value: 'as_removed' },
];

export const TRANSACTION_TYPE = [
  { label: 'Exchange', value: 'exchange' },
  { label: 'Outright', value: 'outright' }
];