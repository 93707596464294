import {
  Box, Card, CardContent, Typography
} from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../../../../components/Button';
import EditableTable from '../../../../../components/EditableTable';
import { cardContent } from '../../../../../components/EditableTable/styles';
import {
  useDeleteWorkOrderTaskPartsPairMutation, useGetCrewListQuery, useGetWorkOrderTaskPartsPairQuery, useUpdateWorkOrderTaskPartsPairMutation,
} from '../../../../../store/session';
import { stringifyLocationSearch } from '../../../../../utils/locationSearch';
import CreatePartsPairDrawer from './CreatePartsPairDrawer';
import { columns } from './columns';

export const PartOffPartOn = ({ hasPermissionsWoEdit, workOrderTask }) => {
  const params = useParams();
  const [currentParts, setCurrentParts] = useState(null);
  const { data: crewList } = useGetCrewListQuery(stringifyLocationSearch({
    crew_line_check: 1,
    post: 3
  }));

  const optionsCrewList = crewList?.map((item) => ({
    label: item.crew_name,
    value: item.id
  }));

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const tableParams = { wo_task_id: params?.id };

  const handleOpenDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);

    if (isDrawerOpen) setCurrentParts(null);
  };

  const handleOpenUpdateDrawer = (item) => {
    setCurrentParts(item);
    setIsDrawerOpen(true);
  };

  return (
    <Box>
      <Card sx={{ marginTop: '35px' }}>
        <CardContent sx={cardContent}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h4">Part off & part on list</Typography>
            <Button disabled={!hasPermissionsWoEdit} onClick={handleOpenDrawer} title="Add" />
          </Box>

          <EditableTable
            useHook={useGetWorkOrderTaskPartsPairQuery}
            useHookDelete={useDeleteWorkOrderTaskPartsPairMutation}
            useHookUpdate={useUpdateWorkOrderTaskPartsPairMutation}
            columns={columns}
            hasPermissionsWoEdit={hasPermissionsWoEdit}
            tableParams={tableParams}
            optionsCrewList={optionsCrewList}
            getRowHeight={() => 'auto'}
            getRowId={(row) => row?.id}
            sortByType="DESC"
            sortByActive="performed_by"
            handleOpenUpdateDrawer={handleOpenUpdateDrawer}
          />
        </CardContent>
      </Card>

      <CreatePartsPairDrawer workOrderTask={workOrderTask} optionsCrewList={optionsCrewList} open={isDrawerOpen} onClose={handleOpenDrawer} currentParts={currentParts} />
    </Box>
  );
};
