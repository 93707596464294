import { Box, Typography } from '@mui/material';
import { HeaderName, StyledDelete, StyledPencil } from '../../../components/EditableTable/styles';
import Input from '../../../components/Input/Input';
import { TextWrap } from '../../Logistics/style';

export const columns = ({
  handleUpdate,
  filters,
  handleFilter,
  handleOpenDeleteModal
}) => ([
  {
    flex: 0.2,
    field: 'airport_name',
    minWidth: 190,
    headerName: 'Name',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Name</Typography>
        <Input
          size="small"
          placeholder="Name"
          defaultValue={filters.airport_name || ''}
          onChange={(e) => {
            handleFilter(e.target.value, 'airport_name');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        sx={TextWrap}
      >
        {row.airport_name}
      </Typography>
    ),
  },
  {
    flex: 0.1,
    field: 'airport_country',
    minWidth: 140,
    headerName: 'Country',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Country</Typography>
        <Input
          size="small"
          placeholder="Country"
          defaultValue={filters.airport_country || ''}
          onChange={(e) => {
            e.stopPropagation();
            handleFilter(e.target.value, 'airport_country');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
        }}
      >
        {row.airport_country}
      </Typography>
    ),
  },
  {
    flex: 0.1,
    field: 'airport_icao',
    minWidth: 90,
    headerName: 'ICAO',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>ICAO</Typography>
        <Input
          size="small"
          placeholder="Icao"
          defaultValue={filters.airport_icao || ''}
          onChange={(e) => {
            e.stopPropagation();
            handleFilter(e.target.value, 'airport_icao');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        {row.airport_icao}
      </Typography>
    ),
  },
  {
    flex: 0.1,
    field: 'airport_iata',
    minWidth: 90,
    headerName: 'IATA',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>IATA</Typography>
        <Input
          size="small"
          placeholder="Iata"
          defaultValue={filters.airport_iata || ''}
          onChange={(e) => {
            e.stopPropagation();
            handleFilter(e.target.value, 'airport_iata');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        {row.airport_iata}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'airport_fees_e',
    minWidth: 120,
    headerName: 'Fees Empty',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Fees Empty</Typography>
        <Input
          type="number"
          size="small"
          placeholder="Fees Empty"
          defaultValue={filters.airport_fees_empty || ''}
          onChange={(e) => {
            e.stopPropagation();
            handleFilter(e.target.value, 'airport_fees_empty');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
      >
        {row.airport_fees_e}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'airport_fees_f',
    minWidth: 120,
    headerName: 'Fees Loaded',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Fees Loaded</Typography>
        <Input
          type="number"
          size="small"
          placeholder="Fees Loaded"
          defaultValue={filters.airport_fees_loaded || ''}
          onChange={(e) => {
            e.stopPropagation();
            handleFilter(e.target.value, 'airport_fees_loaded');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
      >
        {row.airport_fees_f}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'airport_Type',
    minWidth: 80,
    headerName: 'Type',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Type</Typography>
        <Input
          size="small"
          placeholder="Type"
          defaultValue={filters.airport_type || ''}
          onChange={(e) => {
            e.stopPropagation();
            handleFilter(e.target.value, 'airport_type');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
      >
        {row.airport_Type}
      </Typography>
    ),
  },
  {
    flex: 0.1,
    field: 'airport_cat',
    minWidth: 80,
    headerName: 'Cat',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Cat</Typography>
        <Input
          size="small"
          placeholder="Cat"
          defaultValue={filters.airport_category || ''}
          onChange={(e) => {
            e.stopPropagation();
            handleFilter(e.target.value, 'airport_category');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
      >
        {row.airport_cat}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'airport_AirportHours',
    minWidth: 160,
    headerName: 'Hours',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Hours</Typography>
        <Input
          size="small"
          placeholder="Hours"
          defaultValue={filters.airport_hours || ''}
          onChange={(e) => {
            e.stopPropagation();
            handleFilter(e.target.value, 'airport_hours');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
      >
        {row.airport_AirportHours}
      </Typography>
    ),
  },

  {
    flex: 0.2,
    field: 'airport_SlotsRequired',
    minWidth: 140,
    headerName: 'Slots Required',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Slots Required</Typography>
        {/* <Input
          size="small"
          placeholder="Slot"
          defaultValue={filters.airport_slots_required || ''}
          onChange={(e) => {
            e.stopPropagation();
            handleFilter(e.target.value, 'airport_slots_required');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        /> */}
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
      >
        {row.airport_SlotsRequired}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'airport_runway',
    minWidth: 110,
    headerName: 'Runway',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Runway</Typography>
        <Input
          size="small"
          placeholder="Runway"
          defaultValue={filters.airport_run_away || ''}
          onChange={(e) => {
            e.stopPropagation();
            handleFilter(e.target.value, 'airport_run_away');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
      >
        {row.airport_runway}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'airport_IFRorVFR',
    minWidth: 80,
    headerName: 'IFR/VFR',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>IFR/VFR</Typography>
        <Input
          size="small"
          placeholder="IFR/VFR"
          defaultValue={filters.airport_ifr_or_vfr || ''}
          onChange={(e) => {
            e.stopPropagation();
            handleFilter(e.target.value, 'airport_ifr_or_vfr');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
      >
        {row.airport_IFRorVFR}
      </Typography>
    ),
  },
  {
    width: 100,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <>
        <StyledPencil onClick={() => { handleUpdate(row.id); }} />
        {' '}
        <StyledDelete onClick={() => { handleOpenDeleteModal(row.id); }} />
        {' '}
      </>
    ),
  },
]);