import { object, string } from 'yup';

export const defaultValues = {
  task_number: '',
  title: '',
  man_seconds: '00:00',
  interval_days: 0,
  use_interval: 0,
  interval_fh: 0,
  interval_fc: 0,
  due_at_ac_fh: 0,
  remaining_days: 0,
  remaining_fh: 0,
  remaining_fc: 0,
  is_active: 0,
  is_checked: 0
};

export const schema = object().shape({
  task_number: string()
    .required('${label} cannot be blank.')
    .label('Task number'),
  title: string()
    .required('${label} cannot be blank.')
    .label('Title'),
  description: string()
    .required('${label} cannot be blank.')
    .label('Title'),
  man_seconds: string()
    .matches(
      /^[0-9][0-9]:[0-5][0-9]$/,
      'Incorrect time format.'
    )
    .label('MAN HOUR'),
});
