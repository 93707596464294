import { Card, CardContent, Grid } from '@mui/material';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import Autocomplete from '../../../../form/components/Autocomplete/Autocomplete';
import DatePicker from '../../../../form/components/DatePicker';
import FieldError from '../../../../form/components/FieldError';
import FormGroup from '../../../../form/components/FormGroup/FormGroup';
import Input from '../../../../form/components/Input';
import Select from '../../../../form/components/Select';
import {
  useGetAircraftListQuery,
} from '../../../../store/session';
import { stringifyLocationSearch } from '../../../../utils/locationSearch';
import { selectOptionFormat } from '../../../../utils/number';

const CreateQuotationForm = (props) => {
  const {
    setValue,
    control,
    Controller,
    errors,
    trigger,
    companiesListLoad,
    CompaniesList,
    PersonsList,
    personsListLoad,
    company,
    setCompany,
    handleSetPerson,
    AircraftTypesList,
    aircraftTypesListLoad,
    AirportList,
    CargoList,
    cargoListLoad
  } = props;

  // TODO: delete after realization on backend
  const [formattedCompaniesList, setFormattedCompaniesList] = useState([]);
  const [searchedCompaniesList, setSearchedCompaniesList] = useState([]);
  const [aircraftType, setAircraftType] = useState([]);
  const { data: AircraftList, isLoading: aircraftListLoad } = useGetAircraftListQuery(stringifyLocationSearch(aircraftType), {
    refetchOnMountOrArgChange: true
  });

  const formattedPersonsList = selectOptionFormat(PersonsList, 'person_name');

  const formattedAircraftList = selectOptionFormat(AircraftList, 'aircraft_registration');

  const formattedAircraftTypesList = selectOptionFormat(AircraftTypesList, 'aircraft_type_name');

  const formattedCargoList = selectOptionFormat(CargoList, 'cargo_name');

  const handleInputChange = debounce((value) => {
    if (value) {
      // TODO: delete after realization on backend
      setSearchedCompaniesList(formattedCompaniesList.filter(i => i.label.toUpperCase().includes(value ? value.toUpperCase() : '')));
      setCompany(value);
    } else {
      setSearchedCompaniesList(formattedCompaniesList);
    }
  }, 300);

  const handleSelectCompany = (selection) => {
    const option = selection || { value: null, label: '' };

    if (option.value === company?.id) {
      return;
    }

    if (selection) {
      setValue('fleet_company', option.value);
      setCompany(option);
    } else {
      setValue('fleet_company', null);
      setCompany(null);
    }

    trigger('fleet_company');
  };

  const handleChangeRegType = ({ target: { value } }) => {
    setAircraftType({
      type: value
    });
  };

  const handleChangeReg = ({ target: { value } }) => {
    const selectedReg = AircraftList.find(i => i.id === value);

    const airport = AirportList.filter(i => i.id === selectedReg.aircraft_current_location).map(i => ({ ...i, value: i.id, label: `${i.airport_iata}/${i.airport_icao} (${i.airport_name})` }))[0];

    setValue(`hops.${0}.hop_start`, airport?.label || '');
    setValue(`hops.${0}.hop_start_id`, airport?.id || null);

    setValue('fleet_acmi', selectedReg.aircraft_acmi);
    setValue('fleet_cond_nav', selectedReg.aircraft_nav);
    setValue('fleet_cond_fuel', selectedReg.aircraft_fuel_price);
  };

  useEffect(() => {
    if (!companiesListLoad) {
      setFormattedCompaniesList(CompaniesList?.map(({
        id, company_name,
      }) => ({
        label: company_name,
        value: id,
      })) || []);
      // TODO: delete after realization on backend
      setSearchedCompaniesList(CompaniesList?.map(({
        id, company_name,
      }) => ({
        label: company_name,
        value: id,
      })) || []);
    }
  }, [companiesListLoad]);

  return (
    <Card sx={{ mt: 3 }}>
      <CardContent>
        <Grid container spacing={3} rowSpacing={2} sx={{ flexGrow: 1 }}>
          <Grid item xs={12} sm={6}>
            <FormGroup label="Company" required hasError={!!errors.fleet_company}>
              <Controller
                render={({ field } ) => (
                  <Autocomplete
                    {...field}
                    freeSolo
                    sx={{
                      '& .MuiAutocomplete-popupIndicator': {
                        marginRight: '2px',
                      },
                    }}
                    isLoading={companiesListLoad}
                    onChangeInput={handleInputChange}
                    onChange={(e, data) => {
                      handleSelectCompany(data);
                      field.onChange(data?.value);
                    }}
                    size="small"
                    options={searchedCompaniesList}
                    value={company}
                    placeholder="Company"
                    filterOptions={(options) => options}
                  />
                )}
                control={control}
                name="fleet_company"
              />

              <FieldError error={errors.fleet_company} />
            </FormGroup>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormGroup label="Person">
              <Controller
                name="fleet_company_person"
                render={({ field: { value, onChange } }) => (
                  <Select
                    value={value}
                    options={formattedPersonsList}
                    placeholder="Person"
                    onChange={(e) => {
                      handleSetPerson(e.target.value);
                      onChange(e);
                    }}
                    disabled={!PersonsList || personsListLoad}
                  />
                )}
                control={control}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormGroup label="Type of A/C" required hasError={!!errors.fleet_typeofac}>
              <Controller
                name="fleet_typeofac"
                control={control}
                render={({ field: { onChange, ...req } }) => (
                  <Select
                    options={formattedAircraftTypesList}
                    placeholder="Type of A/C"
                    {...req}
                    disabled={aircraftTypesListLoad}
                    onChange={(e) => {
                      handleChangeRegType(e);
                      onChange(e);
                    }}
                  />
                )}
              />

              <FieldError error={errors.fleet_typeofac} />
            </FormGroup>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormGroup label="Reg" required hasError={!!errors.fleet_regofac}>
              <Controller
                name="fleet_regofac"
                control={control}
                render={({ field: { onChange, ...req } }) => (
                  <Select
                    options={formattedAircraftList}
                    placeholder="Reg"
                    {...req}
                    onChange={(e) => {
                      handleChangeReg(e);
                      onChange(e);
                    }}
                    disabled={aircraftListLoad || aircraftListLoad}
                  />
                )}
              />

              <FieldError error={errors.fleet_regofac} />
            </FormGroup>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormGroup label="Cargo" required hasError={!!errors.fleet_cargo}>
              <Controller
                name="fleet_cargo"
                control={control}
                render={({ field }) => (
                  <Select
                    options={formattedCargoList}
                    placeholder="Cargo"
                    {...field}
                    disabled={cargoListLoad}
                  />
                )}
              />

              <FieldError error={errors.fleet_cargo} />
            </FormGroup>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormGroup label="Payload" required hasError={!!errors.fleet_payload}>
              <Controller
                name="fleet_payload"
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder="Payload"
                    {...field}
                  />
                )}
              />

              <FieldError error={errors.fleet_payload} />
            </FormGroup>
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormGroup label="Dimensions" hasError={!!errors.fleet_dimensions}>
              <Controller
                name="fleet_dimensions"
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder="Dimensions"
                    multiline
                    rows={4}
                    {...field}
                  />
                )}
              />

              <FieldError error={errors.fleet_dimensions} />
            </FormGroup>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormGroup label="Date" required hasError={!!errors.fleet_date}>
              <Controller
                name="fleet_date"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    placeholderText="YYYY-MM-DD"
                    dateFormat="yyyy-MM-dd"
                    showMonthDropdown
                    showYearDropdown
                    {...field}
                  />
                )}
              />

              <FieldError error={errors.fleet_date} />
            </FormGroup>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormGroup label="Crew Ready" required hasError={!!errors.fleet_crew_ready}>
              <Controller
                name="fleet_crew_ready"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    placeholderText="YYYY-MM-DD H:m"
                    dateFormat="yyyy-MM-dd HH:mm"
                    showMonthDropdown
                    showYearDropdown
                    {...field}
                  />
                )}
              />

              <FieldError error={errors.fleet_crew_ready} />
            </FormGroup>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormGroup label="Excluding" hasError={!!errors.fleet_excluding}>
              <Controller
                name="fleet_excluding"
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder="Excluding"
                    multiline
                    rows={4}
                    {...field}
                  />
                )}
              />

              <FieldError error={errors.fleet_excluding} />
            </FormGroup>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormGroup label="Subject to" hasError={!!errors.fleet_subjectto}>
              <Controller
                name="fleet_subjectto"
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder="Subject To"
                    multiline
                    rows={4}
                    {...field}
                  />
                )}
              />

              <FieldError error={errors.fleet_subjectto} />
            </FormGroup>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CreateQuotationForm;
