import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import {
  array,
  number,
  object, string
} from 'yup';
import { useAppDispatch } from '../../../../../../../../hooks/toolkitHooks';
import {
  updateExistInvoiceInOrder,
  usePreviewSupplierInvoiceMutation,
  useSendSupplierInvoiceMutation
} from '../../../../../../../../store/slices/logisticsSlice';

export const defaultValues = {
  supplier_id: null,
  to: '',
  email: '',
  actual_address: '',
  ship_to: '',
  ship_via: '',
  // supplier parts
  parts: [
    {
      part_no: '',
      part_desc: '',
      qty: '',
      cd: '',
      price_each: '',
      amount: '',
    }
  ]
};

export const schema = object().shape({
  supplier_id: string()
    .required()
    .label('Supplier'),
  email: string()
    .required()
    .label('Supplier Email'),
  to: string()
    .required()
    .label('To'),
  actual_address: string()
    .nullable()
    .label('Actual Address'),
  ship_to: string()
    .required()
    .label('Ship to'),
  ship_via: string()
    .required()
    .label('Ship Via'),
  parts: array()
    .min(1)
    .of(
      object({
        part_no: string()
          .required(),
        part_desc: string()
          .required(),
        qty: number()
          .required(),
        cd: string()
          .required(),
        price_each: string()
          .required(),
        amount: string()
          .required(),
      })
    )
    .required()
});

export const STORAGE_ADDRESS = {
  budapest: `Fleet Air International
2220, F out. 218,Vecses,
Hungary
Tel: +36702873055`,
  hof: `Fleet Air International
Flughafen Hof-Plauen GmbH & Co. KG
Pirk 20
95032 Hof
phone 49-15115286982`
};

export const deliveryServices = [
  {
    label: 'DHL', value: `DHL Express Hungary
Our Account: #952441447`
  },
  {
    label: 'FedEx', value: `FedEx Express Hungary Transportation Kft.
Our Account: #390747209`
  },
  { label: 'Other', value: '...' }
];

export const useSupplierInvoice = ({ refetch }) => {
  const { order, currencies } = useSelector((state) => state.logistics);
  const [supplier, setSupplier] = useState(null);
  const [supplierInvoiceInfo, setSupplierInvoiceInfo] = useState({
    isOpen: false,
    creator: null,
    created_at: null,
    invoice_number: null
  });
  const [supplierToRegenerate, setSupplierToRegenerate] = useState({
    isOpen: false,
    invoice: null
  });
  const dispatch = useAppDispatch();
  const [previewInvoice, { isLoading: previewLoading }] = usePreviewSupplierInvoiceMutation();
  const [sendInvoice, { isLoading: sendLoading }] = useSendSupplierInvoiceMutation();
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    fields: partsArray,
    append,
    remove
  } = useFieldArray({
    name: 'parts',
    control: methods.control,
  });

  const orderCurrency = (Array.isArray(currencies) ? currencies : [])?.find(curr => curr?.value.toString() === order?.prices?.[0]?.currency_id.toString())?.label;

  const setShipmentData = () => {
    methods.setValue('ship_to', `Please ship the components to the following address:
${STORAGE_ADDRESS[order.storage] ?? '...'}

Please ship via:
${methods.watch('ship_via')}
`);
  };

  const createInvoiceNumber = (supplierData) => {
    const invoiceNumber = parseInt(order?.id, 10);
    const formattedNumber = invoiceNumber.toString().padStart(8, '0');

    if (supplierData?.length) {
      return `#${formattedNumber}(${supplierData.length})`;
    }

    return `#${formattedNumber}`;
  };

  const handleSelectSupplier = (selection) => {
    setSupplier(selection);

    methods.setValue('supplier_id', selection?.value);
    methods.setValue('email', selection?.data?.email);
    methods.setValue('actual_address', selection?.data?.actual_address);
    methods.setValue('to', `${selection?.data?.label}${selection?.data?.actual_address ? `, ${selection?.data?.actual_address}` : ''}`);

    setShipmentData();

    methods.setValue('parts.0.part_no', order?.part_number);
    methods.setValue('parts.0.qty', order?.part_count);
    methods.setValue('parts.0.cd', 'RP');
    methods.setValue('parts.0.part_desc', order?.part_name);
    methods.setValue('parts.0.price_each', `${orderCurrency} ${order?.prices?.[0]?.amount}`);
    // eslint-disable-next-line no-unsafe-optional-chaining
    methods.setValue('parts.0.amount', `${orderCurrency} ${order?.part_count * order?.prices?.[0]?.amount}`);
  };

  const handlePreviewInvoice = async (_, invoiceData = null) => {
    const data = {
      to: methods.getValues('to'),
      ship_to: methods.getValues('ship_to'),
      parts: methods.getValues('parts'),
      invoice_number: createInvoiceNumber(order?.exist_invoices_data),
    };

    const res = await previewInvoice({ data: invoiceData || data });

    if (!res?.errros) {
      const fileUrl = URL.createObjectURL(res.data);
      window.open(fileUrl, '_blank', 'noreferrer');
    }
  };

  const handleAppendNewParts = () => {
    const currency = methods.getValues('parts.0.price_each')?.split(' ')?.[0] || '';

    append({
      ...defaultValues.parts[0],
      price_each: `${currency || ''} `,
      amount: `${currency || ''} `,
    });
  };

  const handleRemoveParts = (index) => {
    remove(index);
  };

  const onSubmit = async (regenerateData = {}, callback = () => {}) => {
    const toastId = toast.loading('Loading...');
    const data = {
      to: methods.getValues('to'),
      email: methods.getValues('email'),
      ship_to: methods.getValues('ship_to'),
      parts: methods.getValues('parts'),
      ship_via: methods.getValues('ship_via'),
      invoice_number: createInvoiceNumber(),
      order_id: order?.id,
      ...regenerateData
    };
    const res = await sendInvoice({ data });
    if (res?.data) {
      toast.success(res?.data?.message, {
        id: toastId
      });

      if (typeof callback === 'function') {
        callback();
      }
      setSupplier(null);
      dispatch(updateExistInvoiceInOrder(true));
      refetch();
      Object.entries(defaultValues).forEach(([key, value]) => methods.setValue(key, value));
    } else if (res?.error?.data) {
      toast.error(res?.error?.data?.message, {
        id: toastId
      });
    }
  };

  const handleOpenSupplierInfoModal = (isOpen = false, creator = null, created_at = null, invoice_number = null) => {
    setSupplierInvoiceInfo(prev => ({
      ...prev,
      isOpen,
      creator,
      created_at,
      invoice_number
    }));
  };

  const handleSetInfoToRegenerate = (isOpen = false, invoice = null) => {
    setSupplierToRegenerate(prev => ({
      ...prev,
      isOpen,
      invoice
    }));
  };

  return {
    methods,
    handleSelectSupplier,
    supplier,
    handlePreviewInvoice,
    handleAppendNewParts,
    handleRemoveParts,
    partsArray,
    previewLoading,
    sendLoading,
    deliveryServices,
    orderCurrency,
    onSubmit,
    handleOpenSupplierInfoModal,
    supplierInvoiceInfo,
    handleSetInfoToRegenerate,
    supplierToRegenerate
  };
};
