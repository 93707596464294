import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import ModalBase from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React from 'react';
import Button from '../Button';
import {
  blockBtn,
  cancel,
  closeIcon, headerSx, modalSx,
  text,
  titleSx,
} from './styles';

const ApproveModal = ({
  open, onClose, data, onApprove, refetch,
}) => {
  const handleApprove = () => {
    onApprove({ data: data?.data, id: data?.id, refetch });
  };

  return (
    <ModalBase
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalSx}>
        <Box sx={headerSx}>
          <Typography sx={titleSx}>
            Approve
          </Typography>
          <CloseIcon onClick={onClose} sx={closeIcon} />
        </Box>
        <Box sx={text}>
          {data?.text}
        </Box>
        <Box sx={blockBtn}>
          <Button onClick={handleApprove} sx={data?.changeStyle ? cancel : null} title={`${data?.successButton || 'Delete'}`} />
          <Button onClick={onClose} sx={data?.changeStyle ? null : cancel} title={`${data?.cancelButton || 'Cancel'}`} />
        </Box>
      </Box>
    </ModalBase>
  ); };

export default ApproveModal;
