import React, { useState } from 'react';
import {
  Button, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Tooltip
} from '@mui/material';
import DefaultButton from '../../../../../../../components/Button';
import { RedButton } from '../../../../../style';
import { useDeletePaymentRequestMutation } from '../../../../../../../store/slices/logisticsSlice';
import { toast } from 'react-hot-toast';
import ButtonIcon from '../../../../../../../components/ButtonIcon';
import { StyledDelete } from '../../../../../../../components/EditableTable/styles';

const DeclineOrder = ({ requestId }) => {
  const [deleteRequest] = useDeletePaymentRequestMutation();

  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleOpen = () => {
    setConfirmOpen(true);
  };

  const handleClose = () => {
    setConfirmOpen(false);
  };

  const handleDecline = async () => {
    const toastId = toast.loading('Loading...');
    const { error: serverError } = await deleteRequest(requestId);

    if (serverError) {
      toast.error(serverError.data.message || 'Something went wrong', {
        id: toastId
      });

      return;
    }

    toast.success('Successfully!', {
      id: toastId
    });
    setConfirmOpen(false);
  };

  return (
    <>
      <ButtonIcon onClick={handleOpen}>
        <Tooltip title="Delete">
          <StyledDelete />
        </Tooltip>
      </ButtonIcon>
      <Dialog
        open={confirmOpen}
        onClose={handleClose}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you really want to delete this payment request? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DefaultButton onClick={handleClose}>
            Cancel
          </DefaultButton>
          <Button
            sx={RedButton}
            onClick={handleDecline}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeclineOrder;
