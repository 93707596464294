import {
  Autocomplete,
  Box, Card,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Button from '../../../../components/Button';
import Drawer from '../../../../components/Drawer';
import EditableTable from '../../../../components/EditableTable';
import LinkButton from '../../../../components/LinkButton';
import Loader from '../../../../components/Loader/ContentPageLoader';
import {
  useCreateInstructorDocumentTableMutation,
  useDeleteInstructorDocumentTableMutation,
  useGetInstructorDataQuery,
  useGetInstructorDocumentTableQuery,
  useUpdateInstructorDocumentTableMutation,
  useUpdateInstructorTagsMutation,
} from '../../../../store/session';
import { usePermissions } from '../../../../utils/hooks/usePermissions';
import DropDown from './DropDown/DropDown';
import InstructorForm from './InstructorForm/InstructorForm';
import { columns } from './columns';
import {
  PilotContainer,
  TitleContainer,
  tableContainer,
} from './styles';
import { useInstructorPage } from './useInstructorPage';

const getRowStyle = (row) => {
  let res = '';

  if (row.file_exist) {
    const now = moment();
    
    if (!row.expiry_date) {
      res = 'yellow';
    } else if (moment(row?.expiry_date).isBefore(now)) {
      res = 'red';
    } else {
      res = 'green';
    }
  } else {
    res = 'gray';
  }

  return res;
};

const InstructorPage = () => {
  const {

    instructorId,
    selectedRow,
    openFile,
    closeMenuFile,
    anchorElFile,
    handleOpenDropDownFile,
    handlePreviewFile,
    handleUpdateFile,
    handleDeleteFile,
    refetch,
    useRefetch,
    toast
  } = useInstructorPage();

  const { data: instructorData, refetch: refetchinstructorData, isLoading } = useGetInstructorDataQuery(instructorId);
  const [updateTagsMutation, { isLoading: isTagsLoading }] = useUpdateInstructorTagsMutation();

  const hasPermissions = usePermissions('training_module_write');

  const [openDocumentDrawer, setOpenDocumentDrawer] = useState(false);
  const [openTagsDrawer, setOpenTagsDrawer] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagsAutocomplete, setTagsAutocomplete] = useState([]);

  useEffect(() => {
    if (instructorData) {
      setTags(instructorData.tags);
      setTagsAutocomplete(instructorData.tags);
    }
  }, [isTagsLoading, instructorData]);

  const handleTagChange = (newValue) => {
    const newTags = newValue.map(tag => {
      const oldTag = tagsAutocomplete.find(e => e.tag_name === tag);
      if (oldTag) return oldTag;

      return { tag_name: tag };
    });
    setTagsAutocomplete(newTags);
  };

  const handleUpdateTags = async () => {
    const toastId = toast.loading('Loading...');

    const requestObject = {
      id: instructorId,
      data: { tags: tagsAutocomplete }
    };
    const { data: newTags } = await updateTagsMutation(requestObject);
    if (!newTags) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }
    toast.success('Success!', {
      id: toastId,
    });
    setTags(newTags);
    setTagsAutocomplete(newTags);
    setOpenTagsDrawer(false);
  };

  const [createDocument] = useCreateInstructorDocumentTableMutation();

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    const formData = new FormData();

    formData.append('document_name', data.document_name);
    formData.append('expiry_date', data.expiry_date ? moment(data.expiry_date).format('YYYY-MM-DD') : '');
    formData.append('file', data.file);
    formData.append('instructor_id', instructorId);

    const res = await createDocument(formData);

    if (res.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }
    useRefetch();
    toast.success('Success!', {
      id: toastId,
    });
    setOpenDocumentDrawer(false);
  };

  if ( isLoading || !instructorData ) return <Loader />;

  return (
    <>
      <LinkButton path="/training-module/training-personnel">
        <Button title="Back"></Button>
      </LinkButton>
      <Card sx={{ padding: '1rem', mt: 3 }}>

        <Box sx={TitleContainer}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          >
            <Box sx={PilotContainer}>
              <Typography variant="h3" sx={{ display: 'block' }}>
                {instructorData.instructor_name}
              </Typography>
            </Box>
            {hasPermissions && (<Button loading={isTagsLoading} title="Edit Tags" onClick={() => setOpenTagsDrawer(true)} />)}
          </Box>

          <Box sx={{
            overflow: 'auto',
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
            pb: '15px'
          }}
          >
            <Chip label="INSTRUCTOR" variant="outlined" color="primary" />
            {tags.map(tag => (<Chip label={tag.tag_name} variant="outlined" color="primary" key={tag.tag_name} />))}
          </Box>

        </Box>

        <Divider sx={{ mt: 0, mb: 5 }} />

        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button sx={{ mb: '10px' }} disabled={!hasPermissions} title="New Document" onClick={() => setOpenDocumentDrawer(true)} />
        </Box>

        <Box sx={tableContainer}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h4" sx={{ display: 'block' }}> QUALIFICATION DOCUMENTS </Typography>
          </Box>
          <EditableTable
            getRowId={(row) => row.id}
            useHook={useGetInstructorDocumentTableQuery}
            useHookUpdate={useUpdateInstructorDocumentTableMutation}
            useHookDelete={useDeleteInstructorDocumentTableMutation}
            tableParams={{
              instructor_id: instructorId,
            }}
            getRowHeight={() => 'auto'}
            sortByType="ASC"
            sortByActive="document_name"
            getRowClassName={({ row }) => `row-${getRowStyle(row)}`}
            columns={columns}
            hasPermissions={hasPermissions}
            handlePreviewFile={handlePreviewFile}
            handleOpenDropDown={handleOpenDropDownFile}
            refetchTable={refetch}
          />

          <DropDown
            open={openFile}
            onClose={closeMenuFile}
            anchorEl={anchorElFile}
            selectedRow={selectedRow}
            useGetHook={handlePreviewFile}
            usePostHook={handleUpdateFile}
            useDeleteHook={handleDeleteFile}
          />
        </Box>

      </Card>
      <Drawer
        open={openDocumentDrawer}
        onClose={() => setOpenDocumentDrawer(false)}
      >
        <InstructorForm onSubmit={onSubmit} />
      </Drawer>

      <Drawer
        open={openTagsDrawer}
        onClose={() => setOpenTagsDrawer(false)}
      >
        <Card sx={{
          mt: 3,
          padding: '1rem'
        }}
        >
          <Box>
            <Typography variant="h3">Edit Tags</Typography>
          </Box>
          <CardContent sx={{ mt: 5 }}>
            <Autocomplete
              id="pilot-tags"
              multiple
              freeSolo
              value={tagsAutocomplete?.map(e => e.tag_name)}
              onChange={(_, newValue) => {
                handleTagChange(newValue);
              }}
              disabled={!hasPermissions}
              options={[]}
              renderInput={(params) => (isTagsLoading ? <CircularProgress />
                : (
                  <TextField
                    {...params}
                    label="Select Tags"
                  />
                ))}
            />
            <Button loading={isTagsLoading} sx={{ mt: '1rem' }} title="Edit" onClick={() => handleUpdateTags()} />
          </CardContent>
        </Card>
      </Drawer>
    </>
  );
};

export default InstructorPage;
