import {
  Box, Card, CircularProgress, Typography
} from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../../../components/Button';
import LinkButton from '../../../../components/LinkButton';
import { useGetSupplierInfoQuery } from '../../../../store/slices/logisticsSlice';
import { headerBtn } from '../../../Invoices/style';
import { RedButton, TextWithIcon } from '../../style';
import DrawerForm from '../NewSupplier/DrawerForm';

const SupplierPage = () => {
  const params = useParams();
  const partId = params.id;

  const { data: supplier, isLoading } = useGetSupplierInfoQuery({ id: partId }, {
    refetchOnMountOrArgChange: true
  });

  return (
    <>
      <Card
        sx={{
          mt: 3,
          padding: '1rem'
        }}
      >
        <Box
          sx={{ ...headerBtn }}
        >
          <Box>
            <Typography noWrap variant="h4" sx={TextWithIcon}>
              Supplier #
              {partId}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '10px' }}>
            <LinkButton path="/logistics/suppliers">
              <Button title="Back" sx={RedButton}></Button>
            </LinkButton>
          </Box>

        </Box>
      </Card>
      {isLoading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Card sx={{
          mt: 3,
          padding: '1rem'
        }}
        >
          <DrawerForm type="update" drawerClose={() => {}} handleRefresh={() => {}} supplier={supplier} />
        </Card>
      )}
    </>
  );
};

export default SupplierPage;