import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { debounce, omitBy } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMenuToggle } from '../../utils/hooks/useMenuToggle';
import {
  parseLocationSearch,
  stringifyLocationSearch,
} from '../../utils/locationSearch';
import ApproveModal from '../ApproveModal';
import CommentDeclineModal from '../CommentDeclineModal';
import DeleteModal from '../DeleteModal';
import DeleteModalConfirmation from '../DeleteModalConfirmation';
import DropDownInput from './DropDownInput';
import { TableStyle, TableStyleColumnGrouped } from './styles';

const EditableTable = ({
  useHook = () => [],
  columns,
  sortByActive,
  sortByType,
  getRowClassName,
  getRowId,
  getRowHeight,
  tableParams = {},
  customIsLoading,
  onCustomAddParams,
  useHookDelete = () => [],
  useHookUpdate = () => [],
  setSortOut = () => {},
  setFilterOut = () => {},
  setTableDataOut = () => {},
  setPageOptions = () => {},
  deleteCallback = () => {},
  refetchCallback = () => {},
  experimentalFeatures = null,
  columnGroupingModel = null,
  dateProps,
  deleteConfirmation,
  updateInvoice,
  style,
  refetchTable = 0,
  ...props
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  
  const xpath = "//div[text()='MUI X Missing license key']";
  const matchingElement = document.evaluate(
    xpath,
    document,
    null,
    XPathResult.FIRST_ORDERED_NODE_TYPE,
    null
  ).singleNodeValue;

  if (matchingElement) {
    matchingElement.remove();
  }

  const {
    open, openMenu, closeMenu, anchorEl
  } = useMenuToggle();

  const [defaultValue, setDefaultValue] = useState('');
  const [options, setOptions] = useState('');
  const [label, setLabel] = useState('');
  const [variant, setVariant] = useState('');
  const [activeSelect, setActiveSelect] = useState('');
  const [selectedRow, setSelectedRow] = useState('');
  const [column, setColumn] = useState('');
  const [modalData, setModalData] = useState(null);
  const [modalCallback, setModalCallback] = useState(() => {});
  const [modelType, setModelType] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [sortType, setSortType] = useState(sortByType);
  const [posPagination, setPosPagination] = useState('top');
  const [sortBy, setSortBy] = useState(sortByActive);
  const [filters, setFilters] = useState(parseLocationSearch(location.search));
  const {
    data, isLoading, refetch, isFetching
  } = useHook(
    stringifyLocationSearch({
      sortDir: sortType?.toLocaleUpperCase(),
      sortField: sortBy,
      pageSize,
      page,
      ...tableParams,
      ...filters,
    }),
    { refetchOnMountOrArgChange: true }
  );
  const [updateCrew, updateParams] = useHookUpdate();
  const { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate } = updateParams || {};
  const [deleteCrew, deleteParams] = useHookDelete();
  const { isLoading: isLoadingDelete, isSuccess: isSuccessDelete } = deleteParams || {};

  const handleDelete = async (id) => {
    const res = await deleteCrew(id);

    if (res.error) {
      toast.error(res?.error?.data?.message || 'Something went wrong');

      return;
    }

    toast.success('Successfully deleted!');
    deleteCallback();
    refetch();
  };

  const handleFilter = useCallback(
    debounce((value, filterBy, isDate, onlyDate = false) => {
      let res = value;

      if (isDate) {
        const format = onlyDate || 'YYYY-MM-DD';
        res = value && moment(value).isValid()
          ? moment(value).format(format)
          : null;
      }

      const newFilters = {
        ...filters,
        ...{ [filterBy]: res },
      };

      const result = omitBy(
        newFilters,
        (v) => v === null || v === undefined || v === ''
      );

      if (result) {
        setFilters(result);
        setFilterOut(result);
        const locationSearch = stringifyLocationSearch(result);

        if (onCustomAddParams) {
          onCustomAddParams(result, filterBy);
        } else {
          navigate(location.pathname + locationSearch);
        }
      }
    }, 300),
    [useHook, filters, tableParams]
  );

  // eslint-disable-next-line no-shadow
  const handleOpen = (
    e,
    text,
    labelInput,
    type,
    option,
    activeItem,
    column,
    item
  ) => {
    openMenu(e);
    setDefaultValue(text);
    setLabel(labelInput);
    setVariant(type);
    setOptions(option);
    setActiveSelect(activeItem);
    setColumn(column);
    setSelectedRow(item);
  };

  const handleClose = (e) => {
    closeMenu(e);
    if (variant === 'DATE_SELECT_OR_N/A') {
      setDefaultValue('');
      setLabel('');
      setActiveSelect('');
      setColumn('');
      setSelectedRow('');
    }
  };

  const handleCloseModal = () => {
    setModalData(null);
    setModelType(null);
  };

  const handleOpenDeleteModal = (id, callback = () => {}) => {
    if (id?.confirm) {
      setModelType('confirmationDelete');
    } else if (id?.commentDelete) {
      setModelType('commentDelete');
    } else if (id?.approve) {
      setModelType('approve');
    } else {
      setModelType('delete');
    }

    setModalCallback(callback);
    setModalData(id);
  };

  const onDelete = async (deleteData) => {
    handleCloseModal();
    await handleDelete(deleteData || modalData);
  };

  // eslint-disable-next-line no-shadow
  const onDeleteConfirmation = async ({ data, id }) => {
    handleCloseModal();

    if (!data) {
      await deleteConfirmation(id).then((res) => {
        if (!res?.error) {
          if (modelType === 'approve') {
            toast.success('Successfully!');
          } else {
            toast.success('Deleted!');
          }
        } else {
          toast.error(res?.error?.data?.message);
        }
      });
    } else {
      await updateInvoice({ data: { deleted: 0 }, id }).then((res) => {
        if (!res?.error) {
          toast.success('Restored!');
        } else {
          toast.error(res?.error?.data?.message);
        }
      });
    }

    refetch();
  };

  const handleSort = (sortObj) => {
    if (sortObj.length) {
      const [{ field, sort }] = sortObj;
      setSortType(sort);
      setSortBy(field);
      setSortOut({ field, sort });

      return;
    }

    setSortType('asc');
    setSortBy(sortByActive);
    setSortOut({ field: sortByActive, sort: 'asc' });
  };

  useEffect(() => {
    if (refetchTable) {
      refetch();
    }
  }, [refetchTable]);

  useEffect(() => {
    if (!isFetching) {
      setTableDataOut(data?.data);
      refetchCallback();
    }
  }, [isFetching]);

  const isLoadingAll = isLoading
    || isLoadingUpdate
    || isLoadingDelete
    || customIsLoading
    || isFetching;

  const isColumnGrouping = experimentalFeatures?.columnGrouping && columnGroupingModel;

  useEffect(() => {
    window.addEventListener('scroll', function() {
      if (this.scrollY > 500) {
        if (posPagination !== 'bottom') {
          setPosPagination('bottom');
        }
      } else if (posPagination !== 'top') {
        setPosPagination('top');
      }
    });

    return () => {
      window.removeEventListener('scroll', () => {});
    };
  }, [posPagination]);

  useEffect(() => {
    const tableScrollerList = document.querySelectorAll('.MuiDataGrid-virtualScroller');
    const isDoubleScroll = document.querySelectorAll('.double-scroll');
    const fullTable = document.querySelectorAll('.MuiDataGrid-virtualScrollerContent');
 
    if (tableScrollerList.length && !isDoubleScroll.length && fullTable.length) {
      const tableScroller = tableScrollerList?.[0];
      const block = document.createElement('div');
      const fakeScroll = document.createElement('div');
      const tableWidth = fullTable?.[0]?.clientWidth;
      const parentTableScroller = tableScroller.parentNode;

      if (tableWidth) {
        block.className = 'double-scroll';
        block.style.width = '100%';
        block.style.overflowX = 'scroll';
  
        fakeScroll.style.height = '1px';
        fakeScroll.style.width = `${tableWidth}px`;
        
        block.append(fakeScroll);

        tableScroller.addEventListener('scroll', (e) => {
          block.scrollLeft = e.target.scrollLeft;
        });
  
        block.addEventListener('scroll', (e) => {
          tableScroller.scrollLeft = e.target.scrollLeft;
        });
        
        parentTableScroller.insertBefore(block, tableScroller);
      }
    }
  }, [document.querySelectorAll('.MuiDataGrid-virtualScroller'), document.querySelectorAll('.MuiDataGrid-virtualScrollerContent')]);

  return (
    <Box>
      <DataGrid
        rows={data?.data || []}
        columns={columns({
          handleOpenDeleteModal,
          handleOpen,
          handleDelete,
          handleFilter,
          filters,
          refetch,
          column,
          isLoadingAll,
          ...props,
        })}
        height={110}
        experimentalFeatures={experimentalFeatures}
        columnGroupingModel={columnGroupingModel}
        autoHeight
        disableColumnMenu
        disableSelectionOnClick
        getRowId={getRowId}
        getRowHeight={getRowHeight}
        loading={isLoadingAll}
        pageSize={pageSize}
        disableColumnFilter
        rowCount={data?.total}
        sortingMode="server"
        getRowClassName={getRowClassName}
        rowsLoadingMode="server"
        paginationMode="server"
        onSortModelChange={(sortModel) => {
          handleSort(sortModel);
        }}
        pageSizeOptions={[15, 25, 50]}
        onPaginationModelChange={(params) => {
          setPage(params.page + 1);
          setPageSize(params.pageSize);
          setPageOptions({ page: params.page + 1, pageSize: params.pageSize });
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 15 } },
        }}
        sx={{
          ...(isColumnGrouping ? TableStyleColumnGrouped : TableStyle),
          ...style,
          flexDirection: (posPagination === 'bottom') ? 'column' : 'column-reverse'
        }}
      />

      <DropDownInput
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        text={defaultValue}
        label={label}
        type={variant}
        options={options}
        activeSelect={activeSelect}
        column={column}
        selectedItem={selectedRow}
        updateCrew={updateCrew}
        refetch={refetch}
        dateProps={dateProps}
      />

      <CommentDeclineModal
        open={modelType === 'commentDelete'}
        data={modalData}
        onClose={handleCloseModal}
        onDelete={onDelete}
      />
      <DeleteModal
        open={modelType === 'delete'}
        onClose={handleCloseModal}
        onDelete={onDelete}
      />
      <DeleteModalConfirmation
        open={modelType === 'confirmationDelete'}
        data={modalData}
        onClose={handleCloseModal}
        onDelete={onDeleteConfirmation}
      />
      <ApproveModal
        open={modelType === 'approve'}
        data={modalData}
        onClose={handleCloseModal}
        onApprove={onDeleteConfirmation}
      />
    </Box>
  );
};

export default EditableTable;
