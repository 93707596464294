import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete as AutocompleteMui, Box, Card,
  TextField
} from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Button from '../../../../components/Button';
import Drawer from '../../../../components/Drawer';
import Autocomplete from '../../../../form/components/Autocomplete';
import FieldError from '../../../../form/components/FieldError';
import FormGroup from '../../../../form/components/FormGroup';
import { useGenerateNotificationMutation, useGetInvoicesListQuery } from '../../../../store/slices/financeSlice';
import { useHandleQueryFormError } from '../../../../utils/hooks/useHandleQueryFormError';
import { defaultValues, schema } from './schema';

const GenerateNotification = ({
  open, onClose, flightId, brokerEmail = ''
}) => {
  const { data: invoicesList } = useGetInvoicesListQuery({ id: flightId }, {
    refetchOnMountOrArgChange: true,
    skip: !flightId
  });
  const [generateNotification] = useGenerateNotificationMutation();
  const handleError = useHandleQueryFormError();

  const {
    handleSubmit,
    setValue,
    setError,
    getValues,
    reset,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    const invoiceList = data.invoices.map(item => +item.value);
    const res = await generateNotification({ data: { emails: data.emails, invoices: invoiceList } });

    if (res.error) {
      if (res.error.status === 403) {
        toast.error('Access is denied', {
          id: toastId,
        });

        return;
      }
      handleError(res.error, setError, getValues, toastId);

      return;
    }

    toast.success('The Notification was generate!', {
      id: toastId,
    });

    onClose();
    reset();
  };

  const invoicesOptions = Object.entries(invoicesList || {}).map(([key, value]) => ({
    label: value,
    value: key
  }));

  useEffect(() => {
    const changeBrokerEmail = brokerEmail?.split(', ');

    setValue('emails', changeBrokerEmail);
  }, [open]);

  return (
    <Drawer
      title="Generate Notification"
      open={open}
      onClose={() => {
        onClose();
        reset();
      }}
      sx={{
        '.MuiTypography-root': {
          margin: '0 auto'
        }
      }}
    >
      <Card sx={{
        mt: 10,
        padding: 5
      }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <FormGroup required label="Emails" hasError={!!errors.emails}>
              <Controller
                name="emails"
                control={control}
                render={({ field }) => (
                  <AutocompleteMui
                    {...field}
                    multiple
                    id="tags-filled"
                    options={[]}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option?.label)}
                    onChange={(e, value) => {
                      field.onChange(value);
                      setValue('emails', value);
                    }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Enter email addresses"
                      />
                    )}
                  />
                )}
              />
              <FieldError error={errors.emails} />
            </FormGroup>
          </Box>
          <Box>
            <FormGroup required label="Invoices" hasError={!!errors.invoices}>
              <Controller
                name="invoices"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={invoicesOptions || []}
                    multiple
                    size="small"
                    placeholder="Please select a currency"
                    onChange={(e, value) => {
                      field.onChange(value);
                    }}
                  />
                )}
              />
              <FieldError error={errors.invoices} />
            </FormGroup>
          </Box>
          <Button type="submit">Send</Button>
        </form>
      </Card>
    </Drawer>
  );
};

export default GenerateNotification;
