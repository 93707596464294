import { Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Button from '../../../../../components/Button';
import AutoSizeTextarea from '../../../../../form/components/AutoSizeTextarea';
import FieldError from '../../../../../form/components/FieldError';
import FormGroup from '../../../../../form/components/FormGroup';
import Input from '../../../../../form/components/Input';
import TimeInput from '../../../../../form/components/TimeInput';
import TypeCard from '../../../../Ods/OdsForm/TypeCard';

const General = ({
  isUpdate, disabled, handleFileDrawer, currentId 
}) => {
  const {
    control, formState: { errors }
  } = useFormContext();

  const maxWidth768px = useMediaQuery('(max-width:768px)');

  return (
    <TypeCard title="GENERAL" variant="yellow">
      <Grid xs={12} container spacing={4} rowSpacing={2} sx={{ flexGrow: 1 }}>
        <Grid item xs={maxWidth768px ? 12 : 3}>
          <FormGroup required={!isUpdate} label="TASK NUMBER" hasError={!!errors?.task_number}>
            <Controller
              render={({ field } ) => (
                <Input
                  placeholder="Enter task number"
                  disabled={disabled}
                  {...field}
                />
              )}
              control={control}
              name="task_number"
            />

            <FieldError error={errors?.task_number} />
          </FormGroup>
        </Grid>
        <Grid item xs={maxWidth768px ? 12 : 3}>
          <FormGroup label="PART NUMBER">
            <Controller
              render={({ field } ) => (
                <Input
                  placeholder="Enter part name"
                  disabled={disabled}
                  {...field}
                />
              )}
              control={control}
              name="part_number"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={maxWidth768px ? 12 : 3}>
          <FormGroup label="SERIAL NUMBER">
            <Controller
              render={({ field } ) => (
                <Input
                  placeholder="Enter serial number"
                  disabled={disabled}
                  {...field}
                />
              )}
              control={control}
              name="serial_number"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={maxWidth768px ? 12 : 3}>
          <FormGroup label="PART NAME">
            <Controller
              render={({ field } ) => (
                <Input
                  placeholder="Enter part name"
                  disabled={disabled}
                  {...field}
                />
              )}
              control={control}
              name="part_name"
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid xs={12} container spacing={5} rowSpacing={2} sx={{ flexGrow: 1 }}>
        <Grid item xs={maxWidth768px ? 12 : 6}>
          <FormGroup label="TITLE" required hasError={!!errors?.title}>
            <Controller
              render={({ field } ) => (
                <Input
                  placeholder="Enter title"
                  disabled={disabled}
                  {...field}
                />
              )}
              control={control}
              name="title"
            />

            <FieldError error={errors?.title} />
          </FormGroup>
        </Grid>
        <Grid item xs={maxWidth768px ? 12 : 3}>
          <FormGroup label="REFERENCE">
            <Controller
              render={({ field } ) => (
                <Input
                  placeholder="Enter task number"
                  disabled={disabled}
                  {...field}
                />
              )}
              control={control}
              name="reference"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={maxWidth768px ? 12 : 3}>
          {isUpdate && (
          <FormGroup label="Ref Files">
            <Button title="Open Files" onClick={() => handleFileDrawer(true, currentId, '', 'Ref', '')} />
          </FormGroup>
          )}
        </Grid>
      </Grid>
      
      <Grid xs={12} container spacing={5} rowSpacing={2} sx={{ flexGrow: 1 }}>
        <Grid item xs={12}>
          <FormGroup label="DESCRIPTION" required hasError={!!errors?.description}>
            <Controller
              render={({ field } ) => (
                <Input
                  placeholder="Enter description"
                  disabled={disabled}
                  {...field}
                />
              )}
              control={control}
              name="description"
            />

            <FieldError error={errors?.description} />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid xs={12} container spacing={5} rowSpacing={2} sx={{ flexGrow: 1 }}>
        <Grid item xs={12}>
          <FormGroup label="WO ISSUED">
            <Controller
              render={({ field } ) => (
                <AutoSizeTextarea
                  disabled={disabled}
                  {...field}
                  placeholder="Enter wo issued"
                  rows={7}
                />
              )}
              control={control}
              name="wo_issued"
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid xs={12} container spacing={5} rowSpacing={2} sx={{ flexGrow: 1 }}>
        <Grid item xs={maxWidth768px ? 12 : 6}>
          <FormGroup label="ZONE">
            <Controller
              render={({ field } ) => (
                <Input
                  placeholder="Enter position"
                  disabled={disabled}
                  {...field}
                />
              )}
              control={control}
              name="position"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={maxWidth768px ? 12 : 6}>
          <FormGroup label="TO DO">
            <Controller
              render={({ field } ) => (
                <Input
                  placeholder="Enter to do"
                  disabled={disabled}
                  {...field}
                />
              )}
              control={control}
              name="to_do"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={maxWidth768px ? 12 : 2}>
          <FormGroup label="MAN HOUR" hasError={!!errors?.man_seconds}>
            <Controller
              render={({ field } ) => (
                <TimeInput
                  disabled={disabled}
                  {...field}
                  variant="standard"
                  helperText="Approximate completion time in hours"
                />
              )}
              control={control}
              name="man_seconds"
            />
            <FieldError error={errors?.man_seconds} />
          </FormGroup>
        </Grid>
      </Grid>
    </TypeCard>
  );
};

export default General;
