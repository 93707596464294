import { Card, Grid, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import EditableTable from '../../../components/EditableTable';
import {
  useDeleteAirportMutation,
  useGetAirportTableListQuery,
} from '../../../store/session';
import { columns } from './columns';

const AirportTable = ({ title }) => {
  const navigate = useNavigate();
  const maxWidth800px = useMediaQuery('(max-width:800px)');

  const handleUpdate = (id) => {
    navigate(`/directories/airport/${id}`);
  };

  return (
    <Grid>
      <Grid>
        <Card>
          <Box
            sx={maxWidth800px ? {
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
              mb: 4
            } : {
              pb: 3,
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant="h3"
              sx={{ textTransform: 'capitalize' }}
            >
              {title}
            </Typography>

            <Button title="Add Airport" onClick={() => { navigate('/directories/airport/create'); }} />
          </Box>
          <EditableTable
            getRowId={(row) => row.id}
            useHook={useGetAirportTableListQuery}
            useHookDelete={useDeleteAirportMutation}
            getRowHeight={() => 'auto'}
            sortByType="asc"
            sortByActive="airport_icao"
            handleUpdate={handleUpdate}
            columns={columns}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default AirportTable;
