import {
  Box, Button, Card, CardContent, CircularProgress, Divider, TextField, Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import {
  useGetFlightToggleCancelMutation,
  useGetFlightToggleInvoiceStatusMutation,
  useGetFlightTogglePermissionStatusMutation,
  useGetFlightToggleStatusMutation,
} from '../../../../../store/session';
import { loader } from './styles';

const StatusForm = (props) => {
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [permissionStatus, setPermissionStatus] = useState(false);
  const { control, Controller } = useFormContext();

  const {
    update, getValues, getFlight, setValue, trigger, setLoading, handleUpdateValues,
  } = props;
  const params = useParams();
  const maxWidth800px = useMediaQuery('(max-width:800px)');
  const status = (update && getValues('is_canceled')) ? 'Canceled' : ((update && getValues('planing_status')) ? 'Closed' : 'Opened');
  const invoice = (update && getValues('planing_invoice_status')) ? 'Paid' : 'Not Paid';
  const permission = (update && getValues('planing_permission_needed')) ? 'Needed' : 'Not needed';
  const [flightToggleStatus] = useGetFlightToggleStatusMutation();
  const [flightToggleCancel] = useGetFlightToggleCancelMutation();
  const [flightToggleInvoiceStatus] = useGetFlightToggleInvoiceStatusMutation();
  const [flightTogglePermissionStatus] = useGetFlightTogglePermissionStatusMutation();

  const handleUpdate = async (result, type) => {
    if (result) {
      handleUpdateValues(result);

      setLoading(false);
      setLoadingStatus(false);
      setPermissionStatus(false);
      if (type === 'status') {
        await trigger('planing_status');
      } else if ((type === 'invoice')) {
        await trigger('planing_invoice_status');
      } else {
        await trigger('planing_permission_needed');
      }
    }
  };

  const handleStatus = async () => {
    setLoading(true);
    const res = await flightToggleStatus(params.id);
    if (res.error) {
      toast.error('Something went wrong');

      return;
    }
    toast.success('Successfully updated!');
    const result = await getFlight({ id: params.id, type: 'cargo' });

    handleUpdate(result.data, 'status');
  };
  const handleCancel = async () => {
    setLoading(true);
    const res = await flightToggleCancel(params.id);
    if (res.error) {
      toast.error('Something went wrong');

      return;
    }
    toast.success('Successfully updated!');
    const result = await getFlight({ id: params.id, type: 'cargo' });

    handleUpdate(result.data, 'status');
  };

  const handleInvoiceStatus = async () => {
    setLoadingStatus(true);
    const res = await flightToggleInvoiceStatus(params.id);
    if (res.error) {
      toast.error('Something went wrong');

      return;
    }
    toast.success('Successfully updated!');
    const result = await getFlight({ id: params.id, type: 'cargo' });

    handleUpdate(result.data, 'invoice');
  };

  const handlePermissionStatus = async () => {
    setPermissionStatus(true);
    const res = await flightTogglePermissionStatus(params.id);
    if (res.error) {
      toast.error('Something went wrong');

      return;
    }
    toast.success('Successfully updated!');
    const result = await getFlight({ id: params.id, type: 'cargo' });

    handleUpdate(result.data, 'permission');
  };

  return (
    <Card sx={{ mt: 3 }}>
      <Card sx={{
        p: '0.75rem',
        backgroundColor: '#f5f5f5',
        border: '1px solid #ffffff',
      }}
      >
        <Box sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          gap: '20px'
        }}
        >
          <Box>
            <Typography variant="h5" noWrap sx={{ textTransform: 'capitalize', mb: 2 }}>
              Status:
            </Typography>
            <Typography variant="h5" noWrap sx={{ textTransform: 'capitalize', mb: 2 }}>
              Invoice status:
            </Typography>
            <Typography variant="h5" noWrap sx={{ textTransform: 'capitalize' }}>
              Permission:
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ minWidth: '133px' }}>
            <Typography
              variant="h5"
              noWrap
              sx={{
                textTransform: 'capitalize',
                mb: 2,
                fontWeight: 600,
                color: status === 'Canceled' ? 'blue' : (status === 'Opened' ? 'green' : 'orange'),
              }}
            >
              {status}
            </Typography>
            <Typography variant="h5" noWrap sx={{ textTransform: 'capitalize', mb: 2, fontWeight: 600 }}>
              {loadingStatus ? (<CircularProgress sx={loader} />) : invoice}
            </Typography>
            <Typography variant="h5" noWrap sx={{ textTransform: 'capitalize', fontWeight: 600 }}>
              {permissionStatus ? (<CircularProgress sx={loader} />) : permission}
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ display: 'grid', ...(maxWidth800px && { width: '100%' }) }}>
            <Box sx={{ display: 'flex' }}>
              {!getValues('is_canceled')
                && (
                <Button disabled={!update} onClick={handleStatus} sx={{ flex: 1, mr: 2, mb: 2 }} variant="contained">
                  {(update && getValues('planing_status')) ? 'Open' : 'Close'}
                </Button>
                )}
              <Button disabled={!update} onClick={handleCancel} sx={{ flex: 1, mb: 2 }} variant="contained">
                {getValues('is_canceled') ? 'Uncancel' : 'Cancel'}
              </Button>
            </Box>
            <Button disabled={!update} onClick={handleInvoiceStatus} sx={{ mb: 2 }} variant="contained">Change invoice status</Button>
            <Button disabled={!update} onClick={handlePermissionStatus} variant="contained">Change permission status</Button>
          </Box>
        </Box>
      </Card>
      {getValues('planing_permission_needed') === 1 && (
        <CardContent sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '15px'
        }}
        >
          <Controller
            name="planing_consignor"
            control={control}
            render={({ field }) => (
              <TextField
                sx={{
                  width: '49%'
                }}
                rows={5}
                multiline
                variant="filled"
                label="Consignor"
                {...field}
              />
            )}
          />
          <Controller
            name="planing_consignee"
            control={control}
            render={({ field }) => (
              <TextField
                sx={{
                  width: '49%'
                }}
                rows={5}
                multiline
                variant="filled"
                label="Consignee"
                {...field}
              />
            )}
          />
        </CardContent>
      )}
    </Card>
  );
};

export default StatusForm;
