import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Grid
} from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Button from '../../../../components/Button';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import { useCreateSupplierMutation, useUpdateSupplierInfoMutation } from '../../../../store/slices/logisticsSlice';
import { useHandleQueryFormError } from '../../../../utils/hooks/useHandleQueryFormError';
import { usePermissions } from '../../../../utils/hooks/usePermissions';
import { GreenButton } from '../../style';
import { button, buttonBlock } from '../style';
import { defaultValues, schema } from './schema';

const DrawerForm = ({
  type, drawerClose, handleRefresh, supplier = null
}) => {
  const update = type === 'update';
  const hasWritePermission = usePermissions('logistics_suppliers_write');
  const {
    handleSubmit,
    setValue,
    setError,
    getValues,
    reset,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: update ? supplier : defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const [sendSupplier] = useCreateSupplierMutation();
  const [updateSupplier] = useUpdateSupplierInfoMutation();
  const handleError = useHandleQueryFormError();

  const onSubmit = async (data) => {
    if (!hasWritePermission) {
      toast.error('You do not have access!');

      return;
    }
    const toastId = toast.loading('Loading...');
    const res = update ? await updateSupplier({ id: supplier?.id, data }) : await sendSupplier({ data });

    if (res.error) {
      handleError(res.error, setError, getValues, toastId);
      // toast.error(res.error.data?.message, {
      //   id: toastId,
      // });

      return;
    }

    toast.success(`The Supplier was ${update ? 'update' : 'created'}!`, {
      id: toastId,
    });

    if (!update) {
      drawerClose();
      reset();
    }

    handleRefresh();
  };

  useEffect(() => {
    if (type === 'update') {
      Object.keys(defaultValues).forEach((key) => setValue(key, supplier?.[key]));
    }
    setValue('type', type);
  }, [supplier]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={8} sx={{ padding: '0 1rem', mt: 5 }}>
        <FormGroup
          label="Name"
          required
          hasError={!!errors.name}
        >
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input placeholder="Name" {...field} />
            )}
          />
        </FormGroup>
        <FormGroup
          label="Legal Address"
          required
          hasError={!!errors.legal_address}
        >
          <Controller
            name="legal_address"
            control={control}
            render={({ field }) => (
              <Input placeholder="Legal Address" {...field} />
            )}
          />
        </FormGroup>
        <FormGroup
          label="Actual Address"
          required
          hasError={!!errors.actual_address}
        >
          <Controller
            name="actual_address"
            control={control}
            render={({ field }) => (
              <Input placeholder="Actual Address" {...field} />
            )}
          />
        </FormGroup>
        <FormGroup
          label="Delivery Address"
          required
          hasError={!!errors.delivery_address}
        >
          <Controller
            name="delivery_address"
            control={control}
            render={({ field }) => (
              <Input placeholder="Delivery Address" {...field} />
            )}
          />
        </FormGroup>
        <FormGroup
          label="Phone"
          hasError={!!errors.phone}
        >
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <Input placeholder="Phone" {...field} />
            )}
          />
        </FormGroup>
        <FormGroup
          label="Email"
          hasError={!!errors.email}
        >
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input placeholder="Email" {...field} />
            )}
          />
        </FormGroup>
        <FormGroup
          label="Fax"
          hasError={!!errors.fax}
        >
          <Controller
            name="fax"
            control={control}
            render={({ field }) => (
              <Input placeholder="Fax" {...field} />
            )}
          />
        </FormGroup>
      </Grid>
      <Box sx={{
        ...buttonBlock, textAlignLast: 'start', padding: '0 1rem', mt: 10
      }}
      >
        <Button sx={{ ...button, ...(update && GreenButton) }} title={update ? 'Save' : 'Create'} type="submit" />
      </Box>
    </form>
  );
};

export default DrawerForm;
