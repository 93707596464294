import { Box, InputAdornment, TextField } from '@mui/material';
import React, { forwardRef, useEffect, useState } from 'react';
import { numberUnFormat } from '../../../utils/number';
import { useHasError } from '../FormGroup';

const CurrencyInput = forwardRef(
  ({
    name, value, onBlur, onChange, positive, ...props
  }, ref) => {
    const hasError = useHasError();
    const [presentationValue, setPresentationValue] = useState('');

    useEffect(() => {
      setPresentationValue(value?.toString() ?? 0);
    }, [value]);



    const handleChange = (event) => {
      event.target.value = numberUnFormat(event.target.value, positive);
      onChange(event);
    };

    return (
      <>
        <input
          ref={ref}
          name={name}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          style={{ display: 'none' }}
        />
        <TextField
          error={hasError}
          size="small"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Box>€ EUR</Box>
              </InputAdornment>
            ),
          }}
          value={presentationValue}
          onChange={handleChange}
          {...props}
        />
      </>
    );
  }
);

export default CurrencyInput;
