import React from 'react';
import { Box, Typography } from '@mui/material';
import TimeForm from './form/TimeForm';
import CrewForm from './form/CrewForm';
import ServicesForm from './form/ServicesForm';
import FuelForm from './form/FuelForm';
import OtherInvoicesForm from './form/OtherInvoicesForm';
import AdditionalInvoicesForm from './form/AdditionalInvoicesForm';
import FullCost from './form/FullCost';

const CloseForm = () => (
  <Box sx={{ pl: '1rem', pt: '1rem', width: '100%' }}>
    <Typography variant="h3">
      Actual Values
    </Typography>
    <TimeForm />
    <CrewForm />
    <ServicesForm />
    <FuelForm />
    <OtherInvoicesForm />
    <AdditionalInvoicesForm />
    <FullCost />
  </Box>
);

export default CloseForm;
