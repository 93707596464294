import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import ContentPageLoader from '../../../components/Loader/ContentPageLoader';
import {
  useUpdateAircraftProfileMutation,
  useGetAircraftProfileMutation,
  useStoreAircraftProfileMutation,
  useGetAirTypeListQuery,
  useGetOperatorListQuery,
} from '../../../store/session';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema, defaultValues } from './schema';
import FormGroup from '../../../form/components/FormGroup/FormGroup';
import FieldError from '../../../form/components/FieldError';
import { selectOptionFormat } from '../../../utils/number';
import Select from '../../../form/components/Select';
import Input from '../../../form/components/Input';

const AircraftProfileForm = () => {
  const [isLoading, setLoading] = useState(false);
  const [isUpdating, setUpdating] = useState(false);
  const { id } = useParams();
  const update = id !== 'create';
  const { data: airTypeList, isLoading: airTypeListLoad } = useGetAirTypeListQuery();
  const { data: operatorList, isLoading: operatorListLoad } = useGetOperatorListQuery();
  const [updateAircraftProfile] = useUpdateAircraftProfileMutation();
  const [storeAircraftProfile] = useStoreAircraftProfileMutation();
  const [getAircraftProfile] = useGetAircraftProfileMutation();
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  });

  const formattedAircraftTypeList = selectOptionFormat(airTypeList, 'aircraft_type_name');
  const formattedOperatorList = selectOptionFormat(operatorList, 'company_name');
  const statusOptions = [
    {
      value: 1,
      label: 'Yes',
    },
    {
      value: 0,
      label: 'No',
    }
  ];

  const handleAircraftProfileData = async () => {
    setLoading(true);
    const { data: result } = await getAircraftProfile({ id });

    return result;
  };

  useEffect(() => {
    if (update) {
      handleAircraftProfileData().then((result) => {
        if (result) {
          Object.entries(result).forEach(
            ([name, value]) => setValue(name, value),
          );
        }
        setLoading(false);
      });
    }
  }, [update]);

  if (isLoading || airTypeListLoad || operatorListLoad) {
    return <ContentPageLoader />;
  }

  const onSubmit = async (data) => {
    if (update) {
      setUpdating(true);
      const res = await updateAircraftProfile({
        ...data,
      });
      if (res.error) {
        toast.error('Something went wrong');
        setUpdating(false);
        
        return;
      }
      toast.success('Successfully updated!');
      setUpdating(false);
    } else {
      const res = await storeAircraftProfile({
        ...data,
      });
      if (res.error) {
        toast.error('Something went wrong');
        setUpdating(false);

        return;
      }
      toast.success('Successfully created!');
      setUpdating(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ mt: 3 }}>
        <CardHeader title="AircraftProfile" />
        <CardContent sx={{ mt: 5 }}>
          <Grid container spacing={5} rowSpacing={2} sx={{ flexGrow: 1 }}>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Is Active" required hasError={!!errors.is_active}>
                <Controller
                  name="is_active"
                  control={control}
                  render={({ field }) => (
                    <Select
                      options={statusOptions}
                      placeholder="Active"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.is_active} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Aircraft Type" required hasError={!!errors.aircraft_type}>
                <Controller
                  name="aircraft_type"
                  control={control}
                  render={({ field: { onChange, ...req } }) => (
                    <Select
                      options={formattedAircraftTypeList}
                      placeholder="Aircraft Type"
                      {...req}
                      onChange={onChange}
                      disabled={airTypeListLoad}
                    />
                  )}
                />

                <FieldError error={errors.aircraft_type} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="ACFT Operator" required hasError={!!errors.aircraft_operator}>
                <Controller
                  name="aircraft_operator"
                  control={control}
                  render={({ field: { onChange, ...req } }) => (
                    <Select
                      options={formattedOperatorList}
                      placeholder="Type of A/C"
                      {...req}
                      disabled={operatorListLoad}
                      onChange={onChange}
                    />
                  )}
                />

                <FieldError error={errors.aircraft_operator} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Aircraft Registration" required hasError={!!errors.aircraft_registration}>
                <Controller
                  name="aircraft_registration"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Aircraft Registration"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.aircraft_registration} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Aircraft ATYP" required hasError={!!errors.aircraft_atyp}>
                <Controller
                  name="aircraft_atyp"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Aircraft ATYP"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.aircraft_atyp} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="ATYP IATA" required hasError={!!errors.aircraft_atypiata}>
                <Controller
                  name="aircraft_atypiata"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="ATYP IATA"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.aircraft_atypiata} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Aircraft Serial Number" hasError={!!errors.aircraft_serial_number}>
                <Controller
                  name="aircraft_serial_number"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Aircraft Serial Number"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.aircraft_serial_number} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Aircraft Home Base" hasError={!!errors.aircraft_home_base}>
                <Controller
                  name="aircraft_home_base"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Aircraft Home Base"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.aircraft_home_base} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Aircraft Default Flight Number" required hasError={!!errors.aircraft_default_flight_number}>
                <Controller
                  name="aircraft_default_flight_number"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Aircraft Default Flight Number"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.aircraft_default_flight_number} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Aircraft Default Flight Number Cargo" required hasError={!!errors.aircraft_default_flight_number_cargo}>
                <Controller
                  name="aircraft_default_flight_number_cargo"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Aircraft Default Flight Number Cargo"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.aircraft_default_flight_number_cargo} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Aircraft Default Flight Number Tech" hasError={!!errors.aircraft_default_flight_number_tech}>
                <Controller
                  name="aircraft_default_flight_number_tech"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Aircraft Default Flight Number Tech"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.aircraft_default_flight_number_tech} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Aircraft MTOW" required hasError={!!errors.aircraft_mtow}>
                <Controller
                  name="aircraft_mtow"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Aircraft MTOW"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.aircraft_mtow} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Aircraft OEW" required hasError={!!errors.aircraft_oew}>
                <Controller
                  name="aircraft_oew"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Aircraft OEW"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.aircraft_oew} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Aircraft PEW" required hasError={!!errors.aircraft_pew}>
                <Controller
                  name="aircraft_pew"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Aircraft PEW"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.aircraft_pew} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Aircraft Max Cargo" required hasError={!!errors.aircraft_max_cargo}>
                <Controller
                  name="aircraft_max_cargo"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Aircraft Max Cargo"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.aircraft_max_cargo} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Aircraft Max Fuel" required hasError={!!errors.aircraft_max_fuel}>
                <Controller
                  name="aircraft_max_fuel"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Aircraft Max Fuel"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.aircraft_max_fuel} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Aircraft First Hour Fuel" required hasError={!!errors.aircraft_taxi_fuel}>
                <Controller
                  name="aircraft_taxi_fuel"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Aircraft First Hour Fuel"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.aircraft_taxi_fuel} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Aircraft Taxi Fuel" required hasError={!!errors.aircraft_first_hour_fuel}>
                <Controller
                  name="aircraft_first_hour_fuel"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Aircraft Taxi Fuel"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.aircraft_first_hour_fuel} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Aircraft Subsequent Fuel" hasError={!!errors.aircraft_subsequent_fuel}>
                <Controller
                  name="aircraft_subsequent_fuel"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Aircraft Subsequent Fuel"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.aircraft_subsequent_fuel} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Aircraft Speed" required hasError={!!errors.aircraft_speed}>
                <Controller
                  name="aircraft_speed"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Aircraft Speed"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.aircraft_speed} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Aircraft ACMI" required hasError={!!errors.aircraft_acmi}>
                <Controller
                  name="aircraft_acmi"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Aircraft ACMI"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.aircraft_acmi} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Aircraft Nav|1nm" required hasError={!!errors.aircraft_nav}>
                <Controller
                  name="aircraft_nav"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Aircraft Nav|1nm"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.aircraft_nav} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Fuel, eur|T" required hasError={!!errors.aircraft_fuel_price}>
                <Controller
                  name="aircraft_fuel_price"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Fuel, eur|T"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.aircraft_fuel_price} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Aircraft DEP A|P fees empty" hasError={!!errors.aircraft_departure_airport_fee}>
                <Controller
                  name="aircraft_departure_airport_fee"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Aircraft DEP A|P fees empty"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.aircraft_departure_airport_fee} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Aircraft ARR A|P fees empty" hasError={!!errors.aircraft_arrival_airport_fee}>
                <Controller
                  name="aircraft_arrival_airport_fee"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Aircraft ARR A|P fees empty"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.aircraft_arrival_airport_fee} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Aircraft DEP A|P fees loaded" hasError={!!errors.aircraft_departure_airport_fee_loaded}>
                <Controller
                  name="aircraft_departure_airport_fee_loaded"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Aircraft DEP A|P fees loaded"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.aircraft_departure_airport_fee_loaded} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Aircraft ARR A|P fees loaded" hasError={!!errors.aircraft_arrival_airport_fee_loaded}>
                <Controller
                  name="aircraft_arrival_airport_fee_loaded"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Aircraft ARR A|P fees loaded"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.aircraft_arrival_airport_fee_loaded} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Aircraft Email" hasError={!!errors.aircraft_email}>
                <Controller
                  name="aircraft_email"
                  control={control}
                  type="email"
                  render={({ field }) => (
                    <Input
                      placeholder="Aircraft Email"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.aircraft_email} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Aircraft Phone Number" hasError={!!errors.aircraft_phone_number}>
                <Controller
                  name="aircraft_phone_number"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Aircraft Phone Number"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.aircraft_phone_number} />
              </FormGroup>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={4} 
            >
              <FormGroup label="Daily Availability Notification" hasError={!!errors.notification_active}>
                <Controller
                  name="notification_active"
                  control={control}
                  render={({ field }) => (
                    <Select
                      options={statusOptions}
                      placeholder="Active"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.notification_active} />
              </FormGroup>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Button disabled={isUpdating} type="submit" variant="contained" size="large" sx={{ marginTop: '10px' }}>
        {update ? 'Update' : 'Save'}
      </Button>
    </form>
  );
};

export default AircraftProfileForm;
