import React from 'react';
import Drawer from '../../../../components/Drawer';
import DrawerForm from './DrawerForm';

const NewSupplier = ({ open, onClose, handleRefresh }) => {

  return (
    <Drawer
      title="Add New Supplier"
      open={open}
      onClose={onClose}
      sx={{
        '.MuiTypography-root': {
          margin: '0 auto'
        }
      }}
    >
      <DrawerForm type="service" drawerClose={onClose} handleRefresh={handleRefresh} />
    </Drawer>
  );
};

export default NewSupplier;
