export const columns = [
  {
    id: 'airport',
    label: 'Airport',
  },
  {
    id: 'service',
    label: 'Service',
  },
  {
    id: 'supplier',
    label: 'Supplier',
  },
  {
    id: 'description',
    label: 'Description',
  },
  {
    id: 'price',
    label: 'Price',
  },
];
