import React from 'react';
import {
  TableCell, TableContainer, TableHead, TableBody, TableRow, Table, Box
} from '@mui/material';
import { columns } from './constants';
import { useServicesForm } from './useServicesForm';
import {
  header, body, blockTextarea, input, cellWrapper,
} from './style';
import CurrencyInput from '../../../../../../../form/components/CurrencyInput';
import AutoSizeTextarea from '../../../../../../../form/components/AutoSizeTextarea';
import { SLOT } from '../../../../../../../constans/constants';

const ServicesForm = () => {
  const {
    suppliers,
    airportList,
    services,
    fields,
    control,
    Controller,
  } = useServicesForm();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((item) => (
              <TableCell sx={header}>{item.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {fields?.map((service, i) => {
            const airport = airportList.find(element => element.id === service.airport_id);
            const list = [];

            const supplierServices = service.services
              .filter((item) => item.pl_serv_supplier !== SLOT)
              .map(i => i.pl_serv_service)
              .filter(Boolean);

            service.services.forEach(item => {
              if (!list.map(i => i.pl_serv_supplier).includes(item.pl_serv_supplier)
                && item.pl_serv_supplier !== SLOT
                && item.pl_serv_service
              ) {
                list.push(item);
              }
            });

            return (
              <TableRow key={service.airport_id}>
                <TableCell sx={body}>{`${airport.airport_iata}/${airport.airport_icao} (${airport?.airport_name})`}</TableCell>
                <TableCell sx={body}>
                  <Box sx={cellWrapper}>
                    {list.map((item) => {
                      const serviceName = supplierServices.map(i => services?.services?.[item.pl_serv_airport]?.[i]).join(',');

                      return (
                        <Box>{serviceName}</Box>
                      );
                    })}
                  </Box>
                </TableCell>
                <TableCell sx={body}>
                  <Box sx={cellWrapper}>
                    {list.map((item) => {
                      const supplier = suppliers[item.pl_serv_airport]?.[item.pl_serv_service]?.[item.pl_serv_supplier];

                      return (
                        <Box>{supplier}</Box>
                      );
                    })}
                  </Box>
                </TableCell>

                <TableCell sx={{ ...body, ...blockTextarea }}>
                  <Box sx={cellWrapper}>
                    {list.map((item, index) => (
                      <Controller
                        name={`planing_services.${i}.services.${index}.pl_serv_description`}
                        control={control}
                        render={({ field }) => (
                          <AutoSizeTextarea
                            placeholder="Description"
                            {...field}
                          />
                        )}
                      />
                    ))}
                  </Box>
                </TableCell>

                <TableCell sx={body}>
                  <Box sx={cellWrapper}>
                    {list.map((item, index) => (
                      <Controller
                        name={`planing_services.${i}.services.${index}.pl_serv_real_price`}
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            placeholder="Price"
                            sx={input}
                            {...field}
                          />
                        )}
                      />
                    ))}
                  </Box>
                </TableCell>
              </TableRow>
            ); })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ServicesForm;
