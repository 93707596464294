
import {
  array,
  mixed, number,
  object, string
} from 'yup';

export const defaultValues = {
  invoice_sum: '',
  file: '',
  description: '',
  due_payment_date: null,
  currency_id: '',
  orders: []
};

export const schema = object().shape({
  file: mixed().test('isEmpty', 'File cannot be blank', (value) => !!value ).nullable(),
  invoice_sum: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('SUM'),
  description: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('Description'),
  currency: object()
    .nullable()
    .required('${label} cannot be blank')
    .label('Currency'),
  currency_id: number()
    .nullable()
    .required('${label} cannot be blank')
    .label('Currency'),
  due_payment_date: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('Deadline Payment'),
  orders: array()
    .nullable()
    .required('${label} cannot be blank')
    .label('Other Orders'),
});
