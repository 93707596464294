import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Card, Divider, Grid, Typography, DialogContent
} from '@mui/material';
import { headerBtn } from '../../../../../Invoices/style';
import FormGroup from '../../../../../../form/components/FormGroup';
import { Controller, useForm } from 'react-hook-form';
import Select from '../../../../../../form/components/Select';
import FieldError from '../../../../../../form/components/FieldError';
import { file, fileName, label } from '../../../../../DocumentsAndInvoices/Documents/DocumentsForm/style';
import Upload from '../../../../../../components/Upload';
import Button from '../../../../../../components/Button';
import { GreenButton } from '../../../../../Logistics/style';
import EditableTable from '../../../../../../components/EditableTable';
import {
  useDeleteTransactionsUploadMutation, useGetTransactionUploadFileMutation,
  useGetTransactionUploadsTableQuery, useUploadTransactionsMutation
} from '../../../../../../store/slices/financeSlice';
import { columns } from '../columns';
import UploadErrors from '../../UploadErrors';
import { defaultValues, schema } from '../schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGetBankAccountListQuery } from '../../../../../../store/session';
import { getOptions } from '../../../../../../utils/getOptions';
import { toast } from 'react-hot-toast';

const Weekly = ({ bankList, open }) => {
  const [nameFile, setNameFile] = useState();
  const [openErrors, setOpenErrors] = useState(false);
  const [currentUpload, setCurrentUpload] = useState(null);
  const [upload] = useUploadTransactionsMutation();
  const [download, { isLoading: fileLoading }] = useGetTransactionUploadFileMutation();

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const { data: accounts, isLoading: accountsLoading } = useGetBankAccountListQuery(watch('bank_id'), {
    refetchOnMountOrArgChange: true,
    skip: !watch('bank_id')
  });
  const accountList = useMemo(() => getOptions(accounts), [accounts]);

  const uploadFile = (e) => {
    setNameFile(e.target.files[0].name);

    setValue('file', e.target.files[0]);
  };

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    const formData = new FormData();

    formData.append('bank_id', data.bank_id);
    formData.append('bank_account_number_id', data.bank_account_number_id);
    formData.append('file', data.file);

    const res = await upload(formData);

    if (res.error) {
      toast.error(res.error.data?.message || 'Something went wrong', {
        id: toastId,
      });

      return;
    }

    toast.success('The transactions has been imported!', {
      id: toastId,
    });

    reset();
    document.getElementById('file-upload').value = '';
    setNameFile(null);
  };

  const handleDownloadFile = async (upload_id) => {
    if (fileLoading) return;
    const toastId = toast.loading('Opening...');

    await download(upload_id).then((res) => {
      if (!res?.errros) {
        toast.success('Success!', {
          id: toastId,
        });
        const fileUrl = URL.createObjectURL(res.data);
        window.open(fileUrl, '_blank', 'noreferrer');
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  const handleOpenErrors = (upload_id) => {
    setCurrentUpload(upload_id);
    setOpenErrors(true);
  };

  const handleCloseErrors = () => {
    setOpenErrors(false);
    setCurrentUpload(null);
  };

  useEffect(() => {
    if (open) {
      reset();
      setNameFile('');
    }
  }, [open]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <Card sx={{ padding: '1rem' }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormGroup required label="Bank" hasError={!!errors.bank_id}>
                    <Controller
                      name="bank_id"
                      control={control}
                      render={({ field }) => (
                        <Select
                          placeholder="Select Bank"
                          options={bankList}
                          {...field}
                        />
                      )}
                    />
                    <FieldError error={errors.bank_id} />
                  </FormGroup>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{
                    backgroundColor: '#dfe6f4',
                    borderRadius: '5px',
                    padding: '10px',
                    ...(file)
                  }}
                  >
                    <FormGroup sx={label} required label="File" hasError={!!errors.file}>
                      <Controller
                        name="file"
                        control={control}
                        render={({ field }) => (
                          <Upload
                            {...field}
                            handleUpload={(e) => {
                              field.onChange(e);
                              uploadFile(e);
                            }}
                            title="Upload file"
                          />
                        )}
                      />
                      <FieldError error={errors.file} />
                    </FormGroup>
                    <Typography sx={fileName}>{nameFile}</Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormGroup required label="Account Number" hasError={!!errors.bank_account_number_id}>
                    <Controller
                      name="bank_account_number_id"
                      control={control}
                      render={({ field }) => (
                        <Select
                          placeholder="Select Account NUmber"
                          options={accountList}
                          disabled={!accountList?.length || accountsLoading}
                          {...field}
                        />
                      )}
                    />
                    <FieldError error={errors.bank_account_number_id} />
                  </FormGroup>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button sx={{ ...(GreenButton), mt: 5 }} title="Upload" type="submit" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>

        <Divider />

        <Card sx={{ padding: '1rem', mt: 2 }}>
          <Box sx={headerBtn}>
            <Box>
              <Typography variant="h5" noWrap>
                Recent Uploads
              </Typography>
              <Typography variant="subtitle2" noWrap>
                Clicking on the Failed status displays a list of errors *
              </Typography>
              <Typography variant="subtitle2" noWrap>
                Clicking on the file name will initiate the download of the file *
              </Typography>
              <Typography variant="subtitle2" noWrap>
                If you delete the upload, it will remove all transactions related to this upload *
              </Typography>
            </Box>
          </Box>

          <EditableTable
            getRowId={(row) => row.id}
            useHook={useGetTransactionUploadsTableQuery}
            useHookDelete={useDeleteTransactionsUploadMutation}
            columns={columns}
            getRowHeight={() => 'auto'}
            sortByType="DESC"
            sortByActive="id"
            handleDownloadFile={(upload_id) => handleDownloadFile(upload_id)}
            handleOpenErrors={(upload_id) => handleOpenErrors(upload_id)}
          />
        </Card>
      </DialogContent>

      <UploadErrors open={openErrors} onClose={handleCloseErrors} upload_id={currentUpload} />
    </form>
  );
};

export default Weekly;
