import React from 'react';
import {
  Box,
  Card, CardContent, Grid
} from '@mui/material';

const PriceCard = ({ price }) => (
  <Card sx={{ mt: 3 }}>
    <CardContent>
      <Grid container spacing={3} rowSpacing={2} sx={{ flexGrow: 1 }}>
        <Grid item xs={12} sm={12}>
          <Box>
            Price:
            {' '}
            {price}
          </Box>
        </Grid>
      </Grid>
    </CardContent>

  </Card>
);

export default PriceCard;
