import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import React from 'react';
import Button from '../../../components/Button';
import ButtonIcon from '../../../components/ButtonIcon';
import FiltrationInput from '../../../components/EditableTable/FiltrationInput';
import FiltrationSelect from '../../../components/EditableTable/FiltrationSelect';
import {
  HeaderName, StyledDelete, StyledPencil,
} from '../../../components/EditableTable/styles';
import LinkButton from '../../../components/LinkButton';
import { OPTIONS_ACTIVE } from '../../../constans/maintenance';
import { GreenButton, TextWrap } from '../../Logistics/style';
import { taskNumberTypog } from './styles';

export const columns = ({
  handleFilter,
  filters,
  handleOpenDeleteModal,
  handleWOHistoryDrawer,
  deletePermission,
  hasPermissions,
}) => ([
  {
    flex: 0.2,
    minWidth: 100,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <>
        <ButtonIcon>
          <LinkButton disabled target="_blank" path={`/maintenance/update/${row.id}`}>
            <StyledPencil />
          </LinkButton>
        </ButtonIcon>
        {' '}
        <ButtonIcon disabled={!hasPermissions && !deletePermission} onClick={() => handleOpenDeleteModal(row.id)}>
          <StyledDelete />
        </ButtonIcon>
      </>
    ),
  },
  {
    flex: 0.2,
    minWidth: 110,
    field: 'in_process',
    headerName: 'In process',
    renderCell: ({ row }) => (
      <Box>
        <Typography>
          {row?.in_process
            ? <Chip label="In process" color="warning" variant="outlined" size="small" sx={{ fontWeight: 800 }} />
            : <Chip label="Open" color="success" variant="outlined" size="small" sx={{ fontWeight: 800 }} />}
        </Typography>
      </Box>
    ),
  },
  {
    flex: 0.1,
    field: 'is_checked',
    minWidth: 105,
    headerName: 'Checked',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Checked</Typography>
        <FiltrationSelect
          options={OPTIONS_ACTIVE}
          defaultValue={filters.is_checked || ''}
          handleFilter={handleFilter}
          placeholder="Checked"
          value="is_checked"
          sx={{ maxWidth: 70 }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>
        {row.is_checked ? 'Yes' : 'No'}
      </Typography>
    ),
  },
  {
    flex: 0.1,
    field: 'is_active',
    minWidth: 105,
    headerName: 'Active',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Active</Typography>
        <FiltrationSelect
          options={OPTIONS_ACTIVE}
          defaultValue={filters.is_active || ''}
          handleFilter={handleFilter}
          placeholder="Active"
          sx={{ maxWidth: 70 }}
          value="is_active"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.is_active ? 'Yes' : 'No'}</Typography>
    ),
  },
  {
    flex: 0.1,
    field: 'remaining_calc_date',
    minWidth: 120,
    headerName: 'Last Update',
    renderCell: ({ row }) => (
      <Typography>{row.remaining_calc_date}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'task_number',
    minWidth: 160,
    headerName: 'Task number',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Task number</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.task_number || ''}
          value="task_number"
          placeholder="Task number"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography sx={taskNumberTypog}>{row.task_number}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'part_number',
    minWidth: 180,
    headerName: 'Part Number',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Part Number</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.part_number || ''}
          value="part_number"
          placeholder="Part number"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.part_number}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'serial_number',
    minWidth: 150,
    headerName: 'Serial Number',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Serial Number</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.serial_number || ''}
          value="serial_number"
          placeholder="Serial Number"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography sx={TextWrap}>
        {row.serial_number}

      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'part_name',
    minWidth: 150,
    headerName: 'Part Name',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Part Name</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.part_name || ''}
          value="part_name"
          placeholder="Part Name"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography sx={TextWrap}>{row.part_name}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'interval',
    minWidth: 150,
    headerName: 'Interval',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Interval</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.interval || ''}
          value="interval"
          placeholder="Interval"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography sx={TextWrap}>{row.interval}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'description',
    minWidth: 400,
    maxHeight: 400,
    headerName: 'Description',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          width: '100%'
        }}
      >
        <Typography style={HeaderName}>Description</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.description || ''}
          value="description"
          placeholder="Description"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>
        {row.description}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'wo_issued',
    minWidth: 180,
    headerName: 'WO Issued',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>WO Issued</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.wo_issued || ''}
          value="wo_issued"
          placeholder="WO Issued"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.wo_issued}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'wo_task_history',
    minWidth: 160,
    headerName: 'WO Task History',
    sortable: false,
    renderCell: ({ row }) => (
      <Button
        sx={{ ...GreenButton, fontSize: '0.75rem', padding: '0.4rem' }}
        onClick={() => handleWOHistoryDrawer({ isOpen: true, id: row?.id })}
      >
        Open History
      </Button>
    ),
  },
  {
    flex: 0.2,
    field: 'position',
    minWidth: 180,
    headerName: 'Zone',
    renderCell: ({ row }) => (
      <Box>
        <Typography sx={TextWrap}>{row.position}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.2,
    field: 'to_do',
    minWidth: 150,
    headerName: 'To Do',
    renderCell: ({ row }) => (
      <Box>
        <Typography sx={TextWrap}>{row.to_do}</Typography>
      </Box>

    ),
  },
  {
    flex: 0.2,
    field: 'last_done_date',
    minWidth: 150,
    headerName: 'Last Done Date',
    renderCell: ({ row }) => (
      <Typography>{row.last_done_date}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'last_done_fh',
    minWidth: 150,
    headerName: 'Last Done FH',
    renderCell: ({ row }) => (
      <Typography>{row.last_done_fh}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'last_done_fc',
    minWidth: 150,
    headerName: 'Last Done FC',
    renderCell: ({ row }) => (
      <Typography>{row.last_done_fc}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'due_at_ac_date',
    minWidth: 150,
    headerName: 'Due At Ac Date',
    renderCell: ({ row }) => (
      <Typography>{row.due_at_ac_date}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'due_at_ac_fh',
    minWidth: 150,
    headerName: 'Due At Ac FH',
    renderCell: ({ row }) => (
      <Typography>{row.due_at_ac_fh}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'due_at_ac_fc',
    minWidth: 150,
    headerName: 'Due At Ac FC',
    renderCell: ({ row }) => (
      <Typography>{row.due_at_ac_fc}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'remaining_days',
    minWidth: 150,
    headerName: 'Remaining Days',
    renderCell: ({ row }) => (
      <Typography>{row.remaining_days}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'remaining_fh',
    minWidth: 150,
    headerName: 'Remaining FH',
    renderCell: ({ row }) => (
      <Typography>{row.remaining_fh}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'remaining_fc',
    minWidth: 150,
    headerName: 'Remaining FC',
    renderCell: ({ row }) => (
      <Typography>{row.remaining_fc}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'man_seconds',
    minWidth: 150,
    headerName: 'Man Hour',
    renderCell: ({ row }) => (
      <Typography>{row.man_seconds}</Typography>
    ),
  },
]);
