import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import {
  Box,
  Card, CardContent,
  CircularProgress,
  DialogContent,
  Divider,
  Grid,
  Tooltip,
  Typography
} from '@mui/material';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Button from '../../../../components/Button';
import DropDownInput from '../../../../components/EditableTable/DropDownInput';
import { TYPE_SELECT } from '../../../../components/EditableTable/constants';
import { ORDER_STATUSES, PRIORITY } from '../../../../constans/logistics';
import { useAppDispatch } from '../../../../hooks/toolkitHooks';
import { useGetInternalOrderQuery, useUpdateInternalAircraftMutation } from '../../../../store/slices/internalLogisticsSlice';
import {
  fetchCurrencies, useGetOrderQuery,
  useUpdateAircraftMutation
} from '../../../../store/slices/logisticsSlice';
import { formatDate } from '../../../../utils/getTime';
import { useMenuToggle } from '../../../../utils/hooks/useMenuToggle';
import { usePermissions } from '../../../../utils/hooks/usePermissions';
import { headerBtn } from '../../../Invoices/style';
import { RedButton, TextWithIcon, TextWrap } from '../../style';
import {
  CloseIconStyle, DetailsGridStyle, OrderDetailsStyle, RightMenuStyle
} from '../styles';
import DeclineForm from './DeclineForm';
import ApprovedForm from './forms/approved/ApprovedForm';
import NewOrderForm from './forms/new/NewOrderForm';
import OnTheWayForm from './forms/onTheWay/OnTheWayForm';
import PendingApproveForm from './forms/pendingApprove/PendingApproveForm';

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

const TaskForm = ({
  open, onClose, currentOrder, refetchAllOrders, locationOrderId = null, aircraftOptions = []
}) => {
  const location = useLocation();
  const dashboardType = location.pathname.split('/')[3];
  const dispatch = useAppDispatch();
  const { currencies = [] } = useSelector((state) => state.logistics);
  const { data: defaultOrder, isLoading: isCurrentOrderLoading, refetch: refetchCurrent } = useGetOrderQuery(
    { orderId: locationOrderId },
    {
      refetchOnMountOrArgChange: true,
      skip: (dashboardType !== 'orders') || !open || !locationOrderId
    }
  );
  const { data: internalOrder, isLoading: isInternalOrderLoading, refetch: refetchInternal } = useGetInternalOrderQuery(
    { orderId: locationOrderId },
    {
      refetchOnMountOrArgChange: true,
      skip: (dashboardType === 'orders') || !open || !locationOrderId
    }
  );
  const order = dashboardType === 'orders' ? defaultOrder : internalOrder;
  const orderLoading = dashboardType === 'orders' ? isCurrentOrderLoading : isInternalOrderLoading;
  const refetch = dashboardType === 'orders' ? refetchCurrent : refetchInternal;
  const [updateCurrentAircraft] = useUpdateAircraftMutation();
  const [updateInternalAircraft] = useUpdateInternalAircraftMutation();
  const updateAircraft = dashboardType === 'orders' ? updateCurrentAircraft : updateInternalAircraft;
  const aircraftReservationAccess = usePermissions('logistics_aircraft_reservation');
  const adminAccess = usePermissions('admin');
  const {
    open: openDrop, openMenu: openMenuDrop, closeMenu: closeMenuDrop, anchorEl
  } = useMenuToggle();

  const handleUpdateAircraft = async (data) => {
    const sendData = dashboardType === 'orders' ? {
      ...data,
      data: {
        ...data.data,
        aircraft_id: data.data?.aircraft_id === 0 ? null : data.data?.aircraft_id
      }
    } : {
      ...data,
      data: {
        aircraft_reservation: data.data?.aircraft_id === 0 ? null : data.data?.aircraft_id
      }
    };

    const res = await updateAircraft(sendData);

    return res;
  };
  const details = [
    { label: 'Created by', value: order?.created_by },
    {
      label: 'Aircraft Reservation',
      value: (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <NoMaxWidthTooltip
            placement="top-end"
            variant="soft"
            title={(
              <Box>
                <Typography sx={{ color: '#fff' }}>
                  {Array.isArray(order?.aircraft_history) ? (
                    <Box>
                      <Typography sx={{ color: '#fff' }} variant="h6">Aircraft History:</Typography>
                      {order?.aircraft_history.map(({
                        aircraft_id_from, aircraft_id_to, time, aircraft_from, aircraft_to
                      }) => (
                        <Box sx={{
                          display: 'flex',
                          columnGap: '2rem',
                          color: '#fff',
                          alignItems: 'center'
                        }}
                        >
                          <Typography sx={{
                            color: '#fff',
                            display: 'grid',
                            gridTemplateColumns: '8px 3rem 2rem 3rem',
                            gap: '.5rem',
                            alignItems: 'center',
                            textWrap: 'nowrap'
                          }}
                          >
                            <CircleIcon sx={{ fontSize: '8px', mr: 3 }} />
                            <Typography sx={{ color: '#fff', }}>
                              {aircraftOptions.find(({ value, label }) => ((value === aircraft_id_from) || (label === aircraft_from)))?.label || 'N/A'}
                            </Typography>
                            <TrendingFlatIcon sx={{
                              ml: 3,
                              mr: 3
                            }}
                            />
                            <Typography sx={{ color: '#fff', }}>
                              {aircraftOptions.find(({ value, label }) => ((value === (aircraft_id_to || null)) || (label === (aircraft_to || null))))?.label || 'N/A'}
                            </Typography>

                          </Typography>
                          <Typography sx={{
                            color: '#fff',
                            textWrap: 'nowrap'
                          }}
                          >
                            {formatDate(+(`${time}` + '000'))}
                          </Typography>
                        </Box>

                      ))}
                    </Box>
                  ) : `The Aircraft has not yet been changed in this Order #${order?.id}`}
                </Typography>
              </Box>
          )}
          >
            <Chip label={(order?.aircraft || order?.aircraft_reservation || 'N/A')} variant="outlined" size="small" />
          </NoMaxWidthTooltip>

          {(((order?.priority !== 10) || adminAccess) || aircraftReservationAccess) && (
          <Button
            title="Change"
            onClick={openMenuDrop}
            disabled={!aircraftReservationAccess}
            sx={{
              ...RedButton,
              height: '1.5rem',
              fontSize: '.7rem',
              lineHeight: '.7rem'
            }}
          />
          )}
          <DropDownInput
            open={openDrop}
            onClose={closeMenuDrop}
            anchorEl={anchorEl}
            type={TYPE_SELECT}
            options={aircraftOptions.map(item => ({
              ...item,
              id: item?.value
            }))}
            activeSelect={aircraftOptions.find(({ label }) => label === (order?.aircraft || order?.aircraft_reservation || 'N/A'))?.value || ''}
            selectedItem={{ id: order?.id }}
            updateCrew={handleUpdateAircraft}
            column="aircraft_id"
            refetch={refetch}
          />
        </Box>
      )
    },
    { label: 'Status', value: ORDER_STATUSES.find(i => i.value == order?.status)?.icon },
    {
      label: 'Priority',
      value: () => {
        const priority = PRIORITY.find(i => i.value === order?.priority);

        return (
          <Typography sx={TextWithIcon}>
            {priority?.icon}
            {priority?.label}
          </Typography>
        );
      }
    },
    (order?.storage && { label: 'Storage', value: order?.storage }),
    (order?.job_id && {
      label: 'Work Order Task',
      value: () => {
        if (order?.job_id) {
          return (
            <Link
              style={{ 'textDecoration': 'none' }}
              to={`/maintenance/work-order-task/update/${order?.job_id}`}
              target="_blank"
            >
              {order?.job_name}
            </Link>
          );
        }

        return 'None';
      }
    }),
    { label: 'Created', value: order?.created_at }
  ].filter(item => !!item);

  useEffect(() => {
    if (open && !!order?.status && !!currentOrder?.status && order?.status !== currentOrder?.status) {
      refetchAllOrders();
    };
  }, [open, order]);

  useEffect(() => {
    if (!currencies) dispatch(fetchCurrencies());
  }, [currencies]);

  const handleDetailInfo = () => (
    details.map((item, index) => (
      <Grid key={index} item xs={12}>
        <Box
          sx={{
            display: 'column', justifyContent: 'left'
          }}
        >
          <Typography noWrap variant="subtitle2">
            {item.label}
          </Typography>
          <Typography noWrap variant="subtitle1" sx={{ textTransform: 'capitalize' }}>
            <b>{typeof item.value === 'function' ? item.value() : item.value}</b>
          </Typography>
        </Box>
      </Grid>
    ))
  );

  const handleOrderForm = (status) => {
    switch (status) {
      case 0:
        return <NewOrderForm onClose={onClose} aircraftOptions={aircraftOptions} />;
      case 1:
        return <PendingApproveForm onClose={onClose} />;
      case 2:
        return <ApprovedForm onClose={onClose} refetch={refetch} />;
      case 3:
        return <OnTheWayForm onClose={onClose} refetch={refetch} />;
      default:
        return 'Incorrect Status';
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={onClose}
    >
      {orderLoading ? (
        <DialogContent sx={{ alignSelf: 'center' }}>
          <CircularProgress />
        </DialogContent>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={8.5} sx={{ backgroundColor: '#e9f1fe26' }}>
            <DialogContent>
              <Card
                sx={{ padding: '1rem' }}
              >
                <Box sx={headerBtn}>
                  <Box>
                    <Typography variant="h5" noWrap>
                      Order #
                      {order?.id}
                      {(dashboardType === 'delivery') && 'D'}
                    </Typography>
                  </Box>
                  {order?.status ? (
                    <DeclineForm orderId={order?.id} closeOrderCard={onClose} orderStatus={order?.status} />
                  ) : <></>}
                </Box>

                <Divider />

                <Grid container spacing={1}>
                  {(order?.part_number && !['delivery', 'Overhaul', 'Repair'].includes(order?.part_type)) && (
                  <Grid item xs={12}>
                    <Typography sx={TextWithIcon}>
                      <b>Part Number:</b>
                      <Chip label={order?.part_number} variant="outlined" />
                    </Typography>
                  </Grid>
                  )}
                  {(order?.part_name && !['delivery', 'Overhaul', 'Repair'].includes(order?.part_type)) && (
                  <Grid item xs={12}>
                    <Typography sx={TextWithIcon}>
                      <b>Part Name:</b>
                      <Chip label={order?.part_name} variant="outlined" />
                    </Typography>
                  </Grid>
                  )}
                  {order?.part_type && (
                  <Grid item xs={12}>
                    <Typography sx={TextWithIcon}>
                      <b>Part Type:</b>
                      <Chip label={order?.part_type} variant="outlined" sx={{ textTransform: 'capitalize' }} />
                    </Typography>
                  </Grid>
                  )}
                  {(order?.ttm_code || order?.ttn_code) && (
                  <Grid item xs={12}>
                    <Typography sx={TextWithIcon}>
                      <b>Tracking Number:</b>
                      <Chip label={order?.ttm_code || order?.ttn_code} variant="outlined" />
                    </Typography>
                  </Grid>
                  )}
                  {((order?.quantity || order?.part_count) && (dashboardType === 'orders')) && (
                  <Grid item xs={12}>
                    <Typography sx={TextWithIcon}>
                      <b>Quantity:</b>
                      <Chip label={order?.quantity || `${order?.part_count} ${order?.unit ?? ''}`} variant="outlined" />
                    </Typography>
                  </Grid>
                  )}
                  {((order?.remarks && (order?.status !== 3)) && (dashboardType === 'orders')) && (
                  <Grid item xs={12}>
                    <Typography sx={TextWithIcon}>
                      <b>Remarks:</b>
                      <Chip label={order?.remarks || ''} variant="outlined" />
                    </Typography>
                  </Grid>
                  )}
                  {order?.description && (
                  <Grid item xs={12}>
                    <Typography sx={{ ...TextWithIcon, alignItems: 'start' }}>
                      <b>Description:</b>
                      <Typography sx={TextWrap}>{order?.description}</Typography>
                    </Typography>
                  </Grid>
                  )}
                  {((order?.part_type === 'Exchange') && order?.order_id) && (
                  <Grid item xs={12}>
                    <Typography sx={TextWithIcon}>
                      <b>Installed Order ID:</b>
                      <Link
                        style={{ 'textDecoration': 'none' }}
                        to={`/logistics/orders-storage/${order?.order_id}`}
                        target="_blank"
                      >
                        <Chip label={order?.order_id} variant="outlined" />
                      </Link>
                    </Typography>
                  </Grid>
                  )}
                  {Array.isArray(order?.quantity) && order?.quantity.map((item, index) => (
                    <Grid item xs={12} sx={{ display: 'flex', gap: '1rem' }}>
                      <NoMaxWidthTooltip
                        placement="top-end"
                        variant="soft"
                        title={(
                          <Box>
                            <Typography sx={{ ...TextWithIcon, color: '#fff' }}>
                              <b>Part Number:</b>
                              <Chip label={order?.parts?.[index]?.part_number} variant="outlined" sx={{ color: '#fff' }} />
                            </Typography>
                            <Typography sx={{ ...TextWithIcon, color: '#fff' }}>
                              <b>Serial Number:</b>
                              <Chip label={order?.parts?.[index]?.serial_number} variant="outlined" sx={{ color: '#fff' }} />
                            </Typography>
                            <Typography sx={{ ...TextWithIcon, color: '#fff' }}>
                              <b>Part Name:</b>
                              <Chip label={order?.parts?.[index]?.part_name} variant="outlined" sx={{ color: '#fff' }} />
                            </Typography>
                          </Box>
                        )}
                      >
                        <Link
                          style={{ 'textDecoration': 'none' }}
                          to={`/logistics/orders-storage/${item?.id || order?.parts?.[index]?.id}`}
                          target="_blank"
                        >
                          <Typography sx={TextWithIcon}>
                            <b style={{ color: '#1b00ff' }}>Part ID:</b>

                            <Chip label={`${item?.id || order?.parts?.[index]?.id} (${order?.parts?.[index]?.part_name})`} variant="outlined" />

                          </Typography>
                        </Link>
                      </NoMaxWidthTooltip>

                      <Typography sx={TextWithIcon}>
                        <b>Quantity:</b>
                        <Chip label={item?.count || item} variant="outlined" />
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
                <Divider />
              </Card>

              <Grid item xs={12}>
                {handleOrderForm(order?.status)}
              </Grid>
            </DialogContent>
          </Grid>

          <Grid item xs={3.5} sx={DetailsGridStyle}>
            <Grid container>
              <Grid item xs={12} sx={RightMenuStyle}>
                <CloseIcon
                  sx={CloseIconStyle}
                  onClick={onClose}
                />
              </Grid>

              <Grid item xs={12}>
                <Card sx={OrderDetailsStyle}>
                  <CardContent>
                    <Grid container spacing={2}>
                      {handleDetailInfo()}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              {(order?.address_from || order?.prices?.[0]?.address_from) && (
                <Grid item xs={12}>
                  <Card sx={OrderDetailsStyle}>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: 'column', justifyContent: 'left'
                            }}
                          >
                            <Typography noWrap variant="subtitle2">
                              From
                            </Typography>
                            <Typography variant="subtitle1">
                              <b>{order?.address_from || order?.prices?.[0]?.address_from}</b>
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: 'column', justifyContent: 'left'
                            }}
                          >
                            <Typography noWrap variant="subtitle2">
                              To
                            </Typography>
                            <Typography variant="subtitle1">
                              <b>{order?.address_to || order?.prices?.[0]?.address_to}</b>
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>

                    </CardContent>
                  </Card>
                </Grid>
              )}

              {order?.status > 0 && (
                <Grid item xs={12}>
                  <Card sx={OrderDetailsStyle}>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: 'column', justifyContent: 'left'
                            }}
                          >
                            <Typography noWrap variant="subtitle2">
                              Requested by
                            </Typography>
                            <Typography noWrap variant="subtitle1">
                              <b>{order?.requested_by || order?.created_by}</b>
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: 'column', justifyContent: 'left'
                            }}
                          >
                            <Typography noWrap variant="subtitle2">
                              Requested
                            </Typography>
                            <Typography noWrap variant="subtitle1">
                              <b>{((order?.requested_at === true) || !order?.requested_at) ? order?.created_at : order?.requested_at}</b>
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>

                    </CardContent>
                  </Card>
                </Grid>
              )}

              {order?.status > 1 && (
              <Grid item xs={12}>
                <Card sx={OrderDetailsStyle}>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: 'column', justifyContent: 'left'
                          }}
                        >
                          <Typography noWrap variant="subtitle2">
                            Approved by
                          </Typography>
                          <Typography noWrap variant="subtitle1">
                            <b>{order?.approved_by}</b>
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: 'column', justifyContent: 'left'
                          }}
                        >
                          <Typography noWrap variant="subtitle2">
                            Approved
                          </Typography>
                          <Typography noWrap variant="subtitle1">
                            <b>{order?.approved_at}</b>
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>

                    {((dashboardType === 'orders') || ((dashboardType === 'delivery') && (order?.prices?.[0]?.amount > 1))) && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: 'column', justifyContent: 'left'
                          }}
                        >
                          <Typography noWrap variant="subtitle2">
                            Price
                          </Typography>
                          <Typography noWrap variant="subtitle1">
                            <b>{order?.prices?.[0]?.amount ? `${order?.prices?.[0]?.amount} ${(Array.isArray(currencies) ? currencies : [])?.find(curr => curr?.value?.toString() === order?.prices?.[0]?.currency_id?.toString())?.label}` : 'N/A'}</b>
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    )}

                    {order?.prices?.[0]?.transaction_type && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: 'column', justifyContent: 'left'
                          }}
                        >
                          <Typography noWrap variant="subtitle2">
                            Transaction Type
                          </Typography>
                          <Typography
                            noWrap
                            variant="subtitle1"
                            sx={{
                              textTransform: 'capitalize'
                            }}
                          >
                            <b>{`${order?.prices?.[0]?.transaction_type}`}</b>
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    )}

                    {(order?.prices?.[0]?.supplier_data) && (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: 'column', justifyContent: 'left'
                            }}
                          >
                            <Typography noWrap variant="subtitle2">
                              Vendor
                            </Typography>
                            <Typography noWrap variant="subtitle1">
                              <b>{order?.prices?.[0]?.supplier_data?.name}</b>
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    )}
                  </CardContent>
                </Card>
              </Grid>
              )}

              {order?.status > 2 && (
              <Grid item xs={12}>
                <Card sx={OrderDetailsStyle}>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: 'column', justifyContent: 'left'
                          }}
                        >
                          <Typography noWrap variant="subtitle2">
                            Ordered by
                          </Typography>
                          <Typography noWrap variant="subtitle1">
                            <b>{order?.ordered_by}</b>
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: 'column', justifyContent: 'left'
                          }}
                        >
                          <Typography noWrap variant="subtitle2">
                            Ordered
                          </Typography>
                          <Typography noWrap variant="subtitle1">
                            <b>{order?.ordered_at}</b>
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: 'column', justifyContent: 'left'
                          }}
                        >
                          <Typography noWrap variant="subtitle2">
                            Tracking Number
                          </Typography>
                          <Typography noWrap variant="subtitle1">
                            <b>{order?.ttm_code || order?.ttn_code}</b>
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>

                  </CardContent>
                </Card>
              </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Dialog>
  );
};

export default TaskForm;
