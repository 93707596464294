
import {
  object, string, number, date
} from 'yup';

export const defaultValues = {
  ap_fuel_spid: '',
  ap_fuel_apid: '',
  ap_fuel_price: '',
  ap_fuel_to: ''
};

export const schema = object().shape({
  ap_fuel_spid: number()
    .required('${label} cannot be blank.')
    .label('Supplier'),
  ap_fuel_apid: number()
    .required('${label} cannot be blank.')
    .label('Airport'),
  ap_fuel_price: number()
    .required('${label} cannot be blank.')
    .label('Price'),
  ap_fuel_to: date().required('${label} cannot be blank.').label('Valid to'),

});
