import SendIcon from '@mui/icons-material/Send';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Card, CardContent, Divider, Grid, InputLabel, Switch, TextField
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import moment from 'moment/moment';
import React, { useCallback, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import logoBlack from '../../../../assets/img/fleet-logo-black.png';
import Button from '../../../../components/Button';
import DatePicker from '../../../../form/components/DatePicker';
import FieldError from '../../../../form/components/FieldError';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import Select from '../../../../form/components/Select';
import { usePermissions } from '../../../../utils/hooks/usePermissions';
import { setDateValue, validateDatePickerValue } from '../../../../utils/setDateValue';
import { Logo } from '../../../Login/styles';
import { GreenButton } from '../../../Logistics/style';
import { FormHeader, SwitchContent } from '../TrainingReportForm/styles';
import EpsCheckItems from './components/arrays/EpsCheckItems';
import ExtraItems from './components/arrays/ExtraItems';
import GeneralItems from './components/arrays/GeneralItems';
import MarkersItems from './components/arrays/MarkersItems';
import ProceduresItems from './components/arrays/ProceduresItems';
import {
  AIRCRAFT_TYPES, CONDITIONS, FFS_REG_NR, OBS_EVAL, OPC_IS, TRAINING_CYCLES
} from './constants';
import { useSecondTrainingReportForm } from './useSecondTrainingReportForm';

export const SecondTrainingReportForm = () => {
  const {
    isUpdate,
    form: {
      watch,
      handleSubmit,
      setValue,
      getValues,
      control,
      formState: { errors }
    },
    generalItems,
    abnormalAndEmergencyProcedures,
    epsCheck,
    extraItemsChecked,
    onPreview,
    onSubmit,
    PilotsList,
    TrainingPersonnelList,
    markers_1,
    markers_2,
    markers_3,
    markers_4,
    CrewList,
    instructorsOptions
  } = useSecondTrainingReportForm();

  const { user } = useSelector((state) => state.session);

  const [opcOptions, setOpcOptions] = useState(OPC_IS);
  const trainingModuleWriteAccess = usePermissions('training_module_write');
  const adminAccess = (user?.role === 'admin');

  const handleSetTotalBlock = () => {
    let offBlock = getValues('off_block');
    let onBlock = getValues('on_block');

    if (offBlock && onBlock) {
      offBlock = offBlock?.split(':')?.map((el, index) => {
        if (index === 0) {
          return +el * 60;
        }

        return +el;
      });
      onBlock = onBlock?.split(':')?.map((el, index) => {
        if (index === 0) {
          return +el * 60;
        }

        return +el;
      });

      offBlock = offBlock?.[0] + offBlock?.[1];
      onBlock = onBlock?.[0] + onBlock?.[1];

      let totalBlock = onBlock - offBlock;
      const hours = (totalBlock / 60);
      const rhours = Math.floor(hours);
      const minutes = (hours - rhours) * 60;
      const rminutes = Math.round(minutes);

      if (totalBlock > 0) {
        totalBlock = `${rhours.toString().length > 1 ? rhours : `0${rhours}`}:${rminutes.toString().length > 1 ? rminutes : `0${rminutes}`}`;

        setValue('total_block', totalBlock);
      } else {
        setValue('total_block', '');
      }
    }
  };

  const opcHandle = useCallback((value, items, key) => {
    if (value === 'fail' || value === 'V' || value === 'P') {
      if (opcOptions.length > 1) {
        setOpcOptions([OPC_IS[1]]);
        setValue('opc_is', false);
      }
    } else if (opcOptions.length < 2) {
      let isFail = false;
      items.forEach((item, index) => {
        const itemValue = watch(key.replace('{index}', index));
        if (itemValue === 'fail' || itemValue === 'V' || itemValue === 'P') {
          isFail = true;
        }
      });
      if (!isFail) {
        setOpcOptions(OPC_IS);
        setValue('opc_is', true);
        opcChange(true);
      }
    }
  }, [opcOptions]);

  const opcChange = useCallback((value) => {
    if (value === true) {
      setValue('recommendations_fail', '');
      setValue('decision_fail', '');
    }
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={9}>
        <Card>
          <CardContent>
            <Grid container spacing={6}>
              <Grid item xs={7}>
                <Box sx={{
                  ...(FormHeader),
                  backgroundColor: 'whitesmoke',
                  padding: 5,
                  radius: '8px',
                  height: '100%',
                  justifyContent: 'center'
                }}
                >
                  <img src={logoBlack} style={Logo} alt="logoWhite" />
                  <Typography variant="subtitle1">
                    OPERATIONS MANUAL PART D
                  </Typography>
                  <Typography variant="subtitle1">
                    APPENDIX
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box sx={SwitchContent}>
                      <InputLabel>
                        Issue Number:
                      </InputLabel>
                      <FormGroup hasError={!!errors.issue_number}>
                        <Controller
                          name="issue_number"
                          control={control}
                          render={({ field }) => (
                            <Input
                              placeholder="Issue Number"
                              {...field}
                            />
                          )}
                        />

                        <FieldError error={errors.issue_number} />
                      </FormGroup>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={SwitchContent}>
                      <InputLabel>
                        Issue Date:
                      </InputLabel>
                      <FormGroup hasError={!!errors.issue_date}>
                        <Controller
                          name="issue_date"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              value={validateDatePickerValue(field.value)}
                              onChange={(e) => {
                                field.onChange(e);
                                setDateValue(e, setValue, 'issue_date');
                              }}
                              placeholderText="Issue Date"
                              dateFormat="yyyy-MM-dd"
                              placeholder="yyyy-MM-dd"
                              showMonthDropdown
                              showYearDropdown

                            />
                          )}
                        />
                        <FieldError error={errors.issue_date} />
                      </FormGroup>
                    </Box>

                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={SwitchContent}>
                      <InputLabel>
                        Revision Number:
                      </InputLabel>
                      <FormGroup hasError={!!errors.revision_number}>
                        <Controller
                          name="revision_number"
                          control={control}
                          render={({ field }) => (
                            <Input
                              placeholder="Revision Number"
                              {...field}
                            />
                          )}
                        />

                        <FieldError error={errors.revision_number} />
                      </FormGroup>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={SwitchContent}>
                      <InputLabel>
                        Revision Date:
                      </InputLabel>
                      <FormGroup hasError={!!errors.revision_date}>
                        <Controller
                          name="revision_date"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              value={validateDatePickerValue(field.value)}
                              onChange={(e) => {
                                field.onChange(e);
                                setDateValue(e, setValue, 'revision_date');
                              }}
                              placeholderText="Revision Date"
                              dateFormat="yyyy-MM-dd"
                              placeholder="2023-01-01"
                              showMonthDropdown
                              showYearDropdown
                            />
                          )}
                        />
                        <FieldError error={errors.revision_date} />
                      </FormGroup>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Divider />

            <Grid container spacing={1} sx={{ mt: 3, mb: 5 }}>
              <Grid item xs={12}>
                <FormGroup label="CREW MEMBER" hasError={!!errors?.crew_member_id}>
                  <Controller
                    name="crew_member_id"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        freeSolo
                        value={field.value ? PilotsList?.filter((CrewItem) => CrewItem.id === field.value)[0] : null}
                        options={PilotsList}
                        getOptionLabel={(option) => `${option.crew_shortname} ${option.crew_name}` || ''}
                        isOptionEqualToValue={(option, AirportListItem) => option.id === AirportListItem.id}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            inputRef={field.ref}
                          />
                        )}
                        onChange={(e, data) => {
                          field.onChange(data?.id);
                        }}
                      />
                    )}
                  />

                  <FieldError error={errors?.crew_member_id} />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <FormGroup label="AIRCRAFT" hasError={!!errors?.aircraft_type}>
                  <Controller
                    name="aircraft_type"
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={AIRCRAFT_TYPES}
                        placeholder="AIRCRAFT"
                        {...field}
                      />
                    )}
                  />

                  <FieldError error={errors?.aircraft_type} />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <FormGroup label="FFS REG Nr." hasError={!!errors?.ffs_reg_nr}>
                  <Controller
                    name="ffs_reg_nr"
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={FFS_REG_NR}
                        placeholder="FFS REG Nr."
                        {...field}
                      />
                    )}
                  />

                  <FieldError error={errors?.ffs_reg_nr} />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <FormGroup label="Training Date" hasError={!!errors.training_date}>
                  <Controller
                    name="training_date"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        value={validateDatePickerValue(field.value)}
                        onChange={(e) => {
                          field.onChange(e);
                          setDateValue(e, setValue, 'training_date');
                        }}
                        dateFormat="yyyy-MM-dd"
                        placeholder="2023-01-01"
                        showMonthDropdown
                        showYearDropdown
                      />
                    )}
                  />

                  <FieldError error={errors.training_date} />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <FormGroup label="CONDITIONS" hasError={!!errors?.conditions}>
                  <Controller
                    name="conditions"
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={CONDITIONS}
                        placeholder="CONDITIONS"
                        {...field}
                      />
                    )}
                  />

                  <FieldError error={errors?.conditions} />
                </FormGroup>
              </Grid>

              <Grid item xs={6}>
                <FormGroup label="From (ICAO or IATA code)" hasError={!!errors.from_code}>
                  <Controller
                    name="from_code"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder="From (ICAO or IATA code)"
                        {...field}
                      />
                    )}
                  />

                  <FieldError error={errors.from_code} />
                </FormGroup>
              </Grid>

              <Grid item xs={6}>
                <FormGroup label="To (ICAO or IATA code)" hasError={!!errors.to_code}>
                  <Controller
                    name="to_code"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder="To (ICAO or IATA code)"
                        {...field}
                      />
                    )}
                  />

                  <FieldError error={errors.to_code} />
                </FormGroup>
              </Grid>

              <Grid item xs={4}>
                <FormGroup label="OFF BLOCK">
                  <Controller
                    render={({ field: { onChange, ...req } }) => (
                      <DatePicker
                        {...req}
                        showTimeSelect
                        timeFormat="HH:mm"
                        dateFormat="HH:mm"
                        showTimeSelectOnly
                        timeIntervals={15}
                        value={req?.value ? moment(req?.value, 'HH:mm').toDate() : null}
                        onChange={(e) => {
                          onChange(e);
                          setDateValue(
                            e,
                            setValue,
                            'off_block',
                            'HH:mm'
                          );
                          handleSetTotalBlock();
                        }}
                      />
                    )}
                    control={control}
                    name="off_block"
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={4}>
                <FormGroup label="ON BLOCK">
                  <Controller
                    render={({ field: { onChange, ...req } }) => (
                      <DatePicker
                        {...req}
                        showTimeSelect
                        timeFormat="HH:mm"
                        dateFormat="HH:mm"
                        showTimeSelectOnly
                        timeIntervals={15}
                        value={req?.value ? moment(req?.value, 'HH:mm').toDate() : null}
                        onChange={(e) => {
                          onChange(e);
                          setDateValue(
                            e,
                            setValue,
                            'on_block',
                            'HH:mm'
                          );
                          handleSetTotalBlock();
                        }}
                      />
                    )}
                    control={control}
                    name="on_block"
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={4}>
                <FormGroup label="TOTAL BLOCK">
                  <Controller
                    render={({ field: { onChange, ...req } }) => (
                      <DatePicker
                        {...req}
                        showTimeSelect
                        timeFormat="HH:mm"
                        dateFormat="HH:mm"
                        showTimeSelectOnly
                        timeIntervals={15}
                        value={req?.value ? moment(req?.value, 'HH:mm').toDate() : null}
                        onChange={(e) => {
                          onChange(e);
                          setDateValue(
                            e,
                            setValue,
                            'total_block',
                            'HH:mm'
                          );
                        }}
                      />
                    )}
                    control={control}
                    name="total_block"
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <FormGroup label="OTHER CREW MEMBER" hasError={!!errors?.other_crew_member_id}>
                  <Controller
                    name="other_crew_member_id"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        freeSolo
                        value={field.value ? PilotsList?.filter((CrewItem) => CrewItem.id === field.value)[0] : null}
                        options={PilotsList}
                        getOptionLabel={(option) => `${option.crew_shortname} ${option.crew_name}` || ''}
                        isOptionEqualToValue={(option, AirportListItem) => option.id === AirportListItem.id}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            inputRef={field.ref}
                          />
                        )}
                        onChange={(e, data) => {
                          field.onChange(data?.id);
                        }}
                      />
                    )}
                  />

                  <FieldError error={errors?.other_crew_member_id} />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <FormGroup label="TRAINING CYCLES" hasError={!!errors?.training_cycle}>
                  <Controller
                    name="training_cycle"
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={TRAINING_CYCLES}
                        placeholder="TRAINING CYCLES"
                        {...field}
                      />
                    )}
                  />

                  <FieldError error={errors?.training_cycle} />
                </FormGroup>
              </Grid>

              <GeneralItems
                generalItems={generalItems}
                form={{
                  watch,
                  setValue,
                  control,
                  formState: { errors }
                }}
                onChangeCallback={opcHandle}
              />

              <ProceduresItems
                abnormalAndEmergencyProcedures={abnormalAndEmergencyProcedures}
                form={{
                  watch,
                  setValue,
                  control,
                  formState: { errors }
                }}
                onChangeCallback={opcHandle}
              />

              <EpsCheckItems
                epsCheck={epsCheck}
                form={{
                  watch,
                  setValue,
                  control,
                  formState: { errors }
                }}
                onChangeCallback={opcHandle}
              />

              <ExtraItems
                extraItems={extraItemsChecked}
                form={{
                  watch,
                  setValue,
                  control,
                  formState: { errors }
                }}
                onChangeCallback={opcHandle}
              />

              <Grid item xs={12}>
                <FormGroup label="RECOMMENDATIONS AND COMMENTS (WRITE DOWN MARK NUMBER AND COMMENT IF ANY)" hasError={!!errors.recommendations_comments}>
                  <Controller
                    name="recommendations_comments"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder="RECOMMENDATIONS AND COMMENTS"
                        multiline
                        rows={4}
                        {...field}
                      />
                    )}
                  />
                  <FieldError error={errors.recommendations_comments} />
                </FormGroup>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    CRM Evaluation (NOTECHS Assessment)
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <MarkersItems
                    markers={markers_1}
                    form={{
                      watch,
                      setValue,
                      control,
                      formState: { errors }
                    }}
                    name="markers_n1"
                    counter="N1."
                    onChangeCallback={opcHandle}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MarkersItems
                    markers={markers_2}
                    form={{
                      watch,
                      setValue,
                      control,
                      formState: { errors }
                    }}
                    name="markers_n2"
                    counter="N2."
                    onChangeCallback={opcHandle}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MarkersItems
                    markers={markers_3}
                    form={{
                      watch,
                      setValue,
                      control,
                      formState: { errors }
                    }}
                    name="markers_n3"
                    counter="N3."
                    onChangeCallback={opcHandle}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MarkersItems
                    markers={markers_4}
                    form={{
                      watch,
                      setValue,
                      control,
                      formState: { errors }
                    }}
                    name="markers_n4"
                    counter="N4."
                    onChangeCallback={opcHandle}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sx={{ mt: 5 }}>
                <FormGroup label="RECOMMENDATIONS AND COMMENTS (WRITE DOWN MARK NUMBER AND COMMENT IF ANY)" hasError={!!errors.recommendations_comments_2}>
                  <Controller
                    name="recommendations_comments_2"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder="RECOMMENDATIONS AND COMMENTS"
                        multiline
                        rows={4}
                        {...field}
                      />
                    )}
                  />
                  <FieldError error={errors.recommendations_comments_2} />
                </FormGroup>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  mt: 5,
                  backgroundColor: '#eff1fc',
                  padding: '8px',
                  borderRadius: '8px 8px 0px 0px',
                }}
              >
                <Typography variant="h5">
                  OPC IS:
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  backgroundColor: '#eff1fc',
                  padding: '8px',
                  borderRadius: '0px 0px 8px 8px',
                }}
              >
                <FormGroup hasError={!!errors?.opc_is}>
                  <Controller
                    name="opc_is"
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={opcOptions}
                        placeholder="OPS IS"
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          opcChange(e.target.value);
                        }}
                      />
                    )}
                  />

                  <FieldError error={errors?.opc_is} />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <FormGroup label="TRE/SFE:" hasError={!!errors?.training_member_id}>
                  <Controller
                    name="training_member_id"
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={instructorsOptions}
                        placeholder="Instructor"
                        {...field}
                      />
                    )}
                  />
                  <FieldError error={errors?.training_member_id} />
                </FormGroup>
              </Grid>

              <Grid item xs={6}>
                <FormGroup label="CURRENT CHECK EXPIRES:" hasError={!!errors.current_check_expires}>
                  <Controller
                    name="current_check_expires"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        value={validateDatePickerValue(field.value)}
                        onChange={(e) => {
                          field.onChange(e);
                          setDateValue(e, setValue, 'current_check_expires');
                        }}
                        dateFormat="yyyy-MM-dd"
                        placeholder="2023-01-01"
                        showMonthDropdown
                        showYearDropdown
                      />
                    )}
                  />

                  <FieldError error={errors.current_check_expires} />
                </FormGroup>
              </Grid>

              <Grid item xs={6}>
                <FormGroup label="NEW VALIDITY DATE:" hasError={!!errors.new_validity_date}>
                  <Controller
                    name="new_validity_date"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        value={validateDatePickerValue(field.value)}
                        onChange={(e) => {
                          field.onChange(e);
                          setDateValue(e, setValue, 'new_validity_date');
                        }}
                        dateFormat="yyyy-MM-dd"
                        placeholder="2023-01-01"
                        showMonthDropdown
                        showYearDropdown
                      />
                    )}
                  />

                  <FieldError error={errors.new_validity_date} />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h5" sx={{ mt: 5 }}>
                  TRAINING OBSERVATION/EVALUATION
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <FormGroup label="Observation/Evalutaion Member" hasError={!!errors?.observation_member_id}>
                  <Controller
                    name="observation_member_id"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        freeSolo
                        value={field.value ? CrewList?.filter((CrewItem) => CrewItem.id === field.value)[0] : null}
                        options={CrewList}
                        getOptionLabel={(option) => `${option.crew_shortname} ${option.crew_name}` || ''}
                        isOptionEqualToValue={(option, AirportListItem) => option.id === AirportListItem.id}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            inputRef={field.ref}
                          />
                        )}
                        onChange={(e, data) => {
                          field.onChange(data?.id);
                        }}
                      />
                    )}
                  />

                  <FieldError error={errors?.observation_member_id} />
                </FormGroup>
              </Grid>

              <Grid
                item
                xs={6}
              >
                <FormGroup label="Observation/Evalutaion" hasError={!!errors?.observer}>
                  <Controller
                    name="observer"
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={OBS_EVAL}
                        placeholder="Observation/Evalutaion"
                        {...field}
                      />
                    )}
                  />

                  <FieldError error={errors?.observer} />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <FormGroup label="Recommendations if training is failed (Shall be filed TRI/SFin case of failed check only):" hasError={!!errors.recommendations_fail}>
                  <Controller
                    name="recommendations_fail"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder="Recommendations if training is failed (Shall be filed TRI/SFin case of failed check only):"
                        multiline
                        rows={4}
                        {...field}
                        InputProps={{
                          readOnly: trainingModuleWriteAccess ? watch('opc_is') : true
                        }}
                      />
                    )}
                  />

                  <FieldError error={errors.recommendations_fail} />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <FormGroup label="Decision in case of failed training (to be filed by Training Manager):" hasError={!!errors.decision_fail}>
                  <Controller
                    name="decision_fail"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder="Decision in case of failed training (to be filed by Training Manager):"
                        multiline
                        rows={4}
                        {...field}
                        InputProps={{
                          readOnly: adminAccess ? watch('opc_is') : true
                        }}
                      />
                    )}
                  />

                  <FieldError error={errors.decision_fail} />
                </FormGroup>
              </Grid>

            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid
        item
        xs={3}
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 10,
          alignSelf: 'flex-start',
        }}
      >
        <Card>
          <CardContent>
            <Box sx={{ display: 'grid', gap: 3 }}>
              <Button
                title={!isUpdate ? 'Send Report' : 'Update'}
                sx={GreenButton}
                startIcon={!isUpdate ? <SendIcon /> : <UpgradeIcon />}
                onClick={handleSubmit(onSubmit)}
              />
              <Button
                title="Preview"
                startIcon={<VisibilityIcon />}
                onClick={handleSubmit(onPreview)}
              />
            </Box>
          </CardContent>
        </Card>

        {!isUpdate && (
          <Box sx={{ ...(SwitchContent), gap: '8px', margin: 3 }}>
            <InputLabel>
              Silent Sign
            </InputLabel>
            <Switch
              checked={watch('silent_sign')}
              onChange={() => {
                setValue('silent_sign', !watch('silent_sign'));
                if (watch('silent_sign')) {
                  setValue('allow_to_fill', false);
                }
              }}
            />
          </Box>
        )}
        {adminAccess && (
          <Box sx={{ ...(SwitchContent), gap: '8px', margin: 3 }}>
            <InputLabel>
              Allow to fill
            </InputLabel>
            <Switch
              checked={watch('allow_to_fill')}
              disabled={isUpdate && watch('silent_sign')}
              onChange={() => {
                setValue('allow_to_fill', !watch('allow_to_fill'));
                if (watch('allow_to_fill') && !isUpdate) {
                  setValue('silent_sign', false);
                }
              }}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default SecondTrainingReportForm;
