import React, { useEffect, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Grid, Tab } from '@mui/material';
import Box from 'mdi-material-ui/PackageVariantClosed';
import Invoice from 'mdi-material-ui/ReceiptText';
import { usePermissions } from '../../utils/hooks/usePermissions';
import Documents from './Documents';
import Invoices from './Invoices';
import { useNavigate, useParams } from 'react-router-dom';

export const DocumentsAndInvoices = () => {
  const { page } = useParams();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState('documents');
  const hasInvoicePermissions = usePermissions(['invoice_request_access', 'invoice_request_logist']);
  const hasDocumentsPermissions = usePermissions('users_documents');
  const hasPermissionsOCCM = usePermissions('invoice_request_approve');

  const handleChange = (event, newValue) => {
    navigate(`/directories/users-documents-and-invoices/${newValue}`);
    setCurrentTab(newValue);
  };

  useEffect(() => {
    if (page) {
      if (page === 'documents' && hasDocumentsPermissions) {
        navigate('/directories/users-documents-and-invoices/documents');
        setCurrentTab('documents');
      }

      if (page === 'invoices' && hasInvoicePermissions) {
        navigate('/directories/users-documents-and-invoices/invoices');
        setCurrentTab('invoices');
      }
    } else {
      if (hasDocumentsPermissions) {
        navigate('/directories/users-documents-and-invoices/documents');
        setCurrentTab('documents');
      }

      if (hasInvoicePermissions) {
        navigate('/directories/users-documents-and-invoices/invoices');
        setCurrentTab('invoices');
      }
    }
  }, [page]);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <TabContext value={currentTab}>
          <TabList onChange={handleChange} aria-label="tabs" xs={6}>
            <Tab disabled={!hasDocumentsPermissions} value="documents" label="Documents" icon={<Box />} />
            <Tab disabled={!hasInvoicePermissions} value="invoices" label="Invoices" icon={<Invoice />} />
          </TabList>
          {hasDocumentsPermissions && (
          <TabPanel value="documents">
            <Documents />
          </TabPanel>
          )}
          {hasInvoicePermissions && (
            <TabPanel value="invoices">
              <Invoices hasPermissions={hasPermissionsOCCM} />
            </TabPanel>
          )}
        </TabContext>
      </Grid>
    </Grid>
  );
};
