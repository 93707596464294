import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import ViewTable from '../../../components/ViewTable';
import { SLOT } from '../../../constans/constants';
import {
  useGetAircraftListQuery, useGetAirportListQuery, useGetCrewListQuery, useGetFlightFuelAirportQuery, useGetFlightMutation, useGetServicesByAirportQuery
} from '../../../store/session';
import {
  fuelColumns, hopsColumns, postColumns, regColumns, serviceColumns
} from './columns';
import { containerSx, footerSx, tableSx } from './styles';

const CrewBriefing = () => {
  const [data, setData] = useState({});
  const { data: airportList } = useGetAirportListQuery();
  const { data: aircraftList } = useGetAircraftListQuery();
  const { data: crew } = useGetCrewListQuery();

  const airports = data?.planing_fuel?.map(item => item.plfuel_ap_id)?.join(',');
  const { data: flightFuel } = useGetFlightFuelAirportQuery(airports);
  const { data: services } = useGetServicesByAirportQuery(airports, { skip: !airports?.length });

  const formattedCrew = {
    caps: {},
    eng: {},
    coP: {},
  };

  data.planing_hop?.forEach((i, key) => {
    if (i.pl_hop_capitane) {
      if (Object.keys(formattedCrew.caps).includes(String(i.pl_hop_capitane))) {
        formattedCrew.caps[i.pl_hop_capitane].push(key + 1);
      } else {
        formattedCrew.caps[i.pl_hop_capitane] = [key + 1];
      }
    }
    if (i.pl_hop_engineer) {
      if (Object.keys(formattedCrew.eng).includes(String(i.pl_hop_engineer))) {
        formattedCrew.eng[i.pl_hop_engineer].push(key + 1);
      } else {
        formattedCrew.eng[i.pl_hop_engineer] = [key + 1];
      }
    }
    if (i.pl_hop_fo) {
      if (Object.keys(formattedCrew.coP).includes(String(i.pl_hop_fo))) {
        formattedCrew.coP[i.pl_hop_fo].push(key + 1);
      } else {
        formattedCrew.coP[i.pl_hop_fo] = [key + 1];
      }
    }
  });

  const { id } = useParams();
  const [getFlight] = useGetFlightMutation({
    fixedCacheKey: 'get-flight-data',
  });

  const loadData = async () => {
    const { data: result } = await getFlight({ id });
    setData(result);
  };

  const suppliersList = useMemo(() => {
    if (data?.planing_services) {
      const list = [];

      data?.planing_services.forEach(service => {
        const supplierServices = service.services
          .filter((item) => item.pl_serv_supplier !== SLOT)
          .map(i => i.pl_serv_service)
          .filter(Boolean);

        service.services.forEach(item => {
          if (!list.map(i => i.pl_serv_supplier).includes(item.pl_serv_supplier) && item.pl_serv_supplier !== SLOT) {
            list.push({ ...item, supplierServices });
          }
        });
      });

      return list;
    }

    return [];
  }, [data]);

  const getDate = () => {
    const d = new Date();
    const curr_date = d.getDate();
    const curr_month = d.getMonth() + 1;
    const curr_year = d.getFullYear();

    return `${curr_date}-${curr_month}-${curr_year}`;
  };

  const changePageTitle = (title) => {
    document.title = title;
  };

  const changeAiroportName = (air = '') => air?.split(' ')[0].split('/')[1] || '';

  const createPageTitle = () => {
    const reg = (aircraftList || []).find(item => item.id === data?.planing_reg)?.aircraft_registration;
    let currentAiroports = [];
    
    (data?.planing_hop || []).forEach((item, i) => {
      if (i === 0) {
        currentAiroports = [`${changeAiroportName(item?.pl_hop_from)}-${changeAiroportName(item?.pl_hop_to)}`];
      } else {
        const name = changeAiroportName(item?.pl_hop_to);

        if (!currentAiroports.includes(name)) currentAiroports = [...currentAiroports, name];
      }
    });

    return `CREW BRIEFING ${currentAiroports.join('-')}, ${reg}, ${getDate()}`;
  };

  useEffect(() => {
    if (data && aircraftList && airportList) {
      const newTitle = createPageTitle();

      changePageTitle(newTitle);
    }
  }, [data, aircraftList, airportList]);

  useEffect(() => {
    loadData();
  }, []);
  
  return (
    <Box p="30px">
      <Typography variant="h3" noWrap>
        Crew Briefing
      </Typography>

      <Grid sx={containerSx}>
        <ViewTable
          list={Object.keys(formattedCrew.caps)}
          columns={postColumns(crew, formattedCrew )}
          sx={tableSx}
          singleView
        />

        <ViewTable
          list={[data]}
          columns={regColumns(aircraftList)}
          sx={tableSx}
        />

        <ViewTable
          list={data.planing_hop || []}
          columns={hopsColumns(data, airportList)}
          sx={tableSx}
        />

        <ViewTable
          list={suppliersList}
          columns={serviceColumns(airportList, services, data)}
          sx={tableSx}
        />

        <ViewTable
          list={data.planing_fuel || []}
          columns={fuelColumns(airportList, flightFuel)}
          sx={tableSx}
        />

        <Box sx={footerSx}>
          <Typography>
            Cargo type:
            {' '}
            {data?.planing_crg_type_label}
          </Typography>
          <Typography>
            Dimension:
            {' '}
            {data?.planing_crg_dimenssions}
          </Typography>
          <Typography>
            Weight:
            {' '}
            {data?.planing_crg_weight}
            {' '}
            kgs
          </Typography>
        </Box>
      </Grid>
    </Box>
  );
};

export default CrewBriefing;
