
import {
  boolean,
  number,
  object, string
} from 'yup';

export const defaultValues = {
  airport_name: '',
  airport_country: '',
  airport_NearestCity: '',
  airport_icao: '',
  airport_iata: '',
  airport_AFS: '',
  airport_SITA: '',
  airport_lat: 0,
  airport_lon: '',
  airport_Web: '',
  airport_emai: '',
  airport_Type: '',
  airport_cat: 'A',
  airport_Entry: '',
  airport_CustomsAvailable: '',
  airport_SlotsRequired: false,
  airport_extensions_possible: false,
  airport_FuelAvailable: '',
  airport_FireCategory: '',
  airport_AirportHours: '',
  airport_TowerHours: '',
  airport_timezone: '',
  airport_TimeZone2: 0,
  airport_DST: '',
  airport_MaxSizeAP: '',
  airport_Elevation: '',
  airport_IFRorVFR: '',
  airport_PrimaryRunwayID: '',
  airport_Surface: '',
  airport_RwyLength: 0,
  airport_RwyWidth: 0,
  airport_PCN: '',
  airport_Intensity: '',
  airport_Centreline: '',
  airport_PALorPCL: '',
  airport_PALFrequency: '',
  airport_fees_e: 2100,
  airport_fees_f: 2800,
  airport_fees_p: 1400,
  airport_invoice_tel: '',
  airport_invoice_fax: '',
  airport_invoice_email: '',
  airport_tower_tel: '',
  airport_tower_fax: '',
  airport_tower_email: '',
  airport_notes: '',
  airport_slot_tel: '',
  airport_slot_fax: '',
  airport_slot_email: '',
  airport_slot_sita: '',
  airport_slot_aftn: ''
};

export const schema = object().shape({
  airport_name: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Airport name'),
  airport_country: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Airport Country'),
  airport_NearestCity: string()
    .nullable()
    .label('Airport Nearest City'),
  airport_icao: string()
    .nullable()
    .required('${label} cannot be blank.')
    .max(10)
    .label('Airport Icao'),
  airport_iata: string()
    .nullable()
    .required('${label} cannot be blank.')
    .max(10)
    .label('Airport Iata'),
  airport_AFS: string()
    .nullable()
    .label('Airport Afs'),
  airport_SITA: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Airport Sita'),
  airport_lat: number()
    .required('${label} cannot be blank.')
    .label('Airport lat '),
  airport_lon: number()
    .required('${label} cannot be blank.')
    .label('Airport lat '),
  airport_Web: string().nullable().label('Airport Web'),
  airport_email: string().required('${label} cannot be blank.').label('Airport email'),
  airport_Type: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Airport Type'),
  airport_cat: string().nullable().max(2).label('Airport Cat'),
  airport_Entry: string().nullable().label('Airport Entry'),
  airport_CustomsAvailable: string().nullable().label('Airport Custom available'),
  airport_SlotsRequired: boolean().nullable().label('Airport Slot required'),
  airport_extensions_possible: boolean().nullable().label('Airport extensions possible'),
  airport_FuelAvailable: string().nullable().label('Airport Fuel available'),
  airport_FireCategory: string().nullable().label('Airport Fire category'),
  airport_AirportHours: string().nullable().label('Airport hours'),
  airport_timezone: string().required('${label} cannot be blank.').nullable().label('Airport time zone'),
  airport_TimeZone2: number().nullable().label('Airport time zone 2'),
  airport_DST: string().nullable().label('Airport Dst'),
  airport_MaxSizeAP: string().nullable().label('Airport max size ap'),
  airport_Elevation: string().nullable().label('Airport evalation'),
  airport_IFRorVFR: string().nullable().label('Airport RorVFR'),
  airport_Surface: string().nullable().label('Airport surface'),
  airport_RwyLength: number().nullable().label('Airport rwy length'),
  airport_RwyWidth: number().nullable().label('Airport rwy width'),
  airport_PCN: string().nullable().label('Airport pcn'),
  airport_Intensity: string().nullable().label('Airport intensity'),
  airport_Centreline: string().nullable().label('Airport center line'),
  airport_PALorPCL: string().nullable().label('Airport PALorPCL'),
  airport_PALFrequency: string().nullable().label('Airport PAL Frequency'),
  airport_fees_e: number().nullable().required('${label} cannot be blank.').label('Airport Fees empty'),
  airport_fees_f: number().nullable().required('${label} cannot be blank.').label('Airport Fees loaded'),
  airport_fees_p: number().nullable().required('${label} cannot be blank.').label('Airport Fees Pax'),
  airport_invoice_tel: string().nullable().label('Airport invoice phone'),
  airport_invoice_fax: string().nullable().label('Airport invoice fax'),
  airport_invoice_email: string().nullable().label('Airport invoice email'),
  airport_tower_tel: string().nullable().label('Airport tower phone'),
  airport_tower_fax: string().nullable().label('Airport tower fax'),
  airport_tower_email: string().nullable().label('Airport tower email'),
  airport_notes: string().nullable().label('Airport notes'),
  airport_slot_tel: string().when('airport_SlotsRequired', {
    is: (vlv) => !!vlv,
    then: string().required('${label} cannot be blank.'),
    otherwise: string().nullable(),
  }).label('Airport SLOT Tel'),
  airport_slot_fax: string().when('airport_SlotsRequired', {
    is: (vlv) => !!vlv,
    then: string().required('${label} cannot be blank.'),
    otherwise: string().nullable(),
  }).label('Airport SLOT Fax'),
  airport_slot_email: string().email().when('airport_SlotsRequired', {
    is: (vlv) => !!vlv,
    then: string().required('${label} cannot be blank.'),
    otherwise: string().nullable(),
  }).label('Airport SLOT Email'),
  airport_slot_sita: string().when('airport_SlotsRequired', {
    is: (vlv) => !!vlv,
    then: string().required('${label} cannot be blank.'),
    otherwise: string().nullable(),
  }).label('Airport SLOT SITA'),
  airport_slot_aftn: string().when('airport_SlotsRequired', {
    is: (vlv) => !!vlv,
    then: string().required('${label} cannot be blank.'),
    otherwise: string().nullable(),
  }).label('Airport SLOT AFTN')
});
