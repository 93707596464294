import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { HeaderName, StyledDelete } from '../../../components/EditableTable/styles';
import FiltrationInput from '../../../components/EditableTable/FiltrationInput';
import TextEdit from '../../../components/EditableTable/TextEdit';
import {
  TYPE_INPUT, TYPE_MULTI_INPUT, TYPE_SELECT, TYPE_SELECT_DATE
} from '../../../components/EditableTable/constants';
import React from 'react';
import { upperCase } from 'lodash';
import {
  RATE_TYPE_DUTY, RATE_TYPE_MONTH, RATE_TYPES, TYPE_OF_PAYMENTS
} from './constants';
import Button from '../../../components/Button';
import { GreenButton, RedButton } from '../../Logistics/style';
import Chip from '@mui/material/Chip';
import FiltrationDate from '../../../components/EditableTable/FiltrationDate';
import { TransactionModel } from '../../../utils/constants/portalFiles';

export const salaries_columns = ({
  handleFilter,
  filters,
  hasPermissions,
  handleOpen,
  handleOpenUpdateRate,
  handleOpenDeleteModal,
  setIsOpenFileDrawer,
  handleOpenUpdateQnt,
  handleFileDrawer,
  handleOpenSalaryTransaction,
  handleTransactionPortalFileDrawer
}) => {
  const calculateAmount = (row) => {
    const {
      additional_amount = 0,
      rate_type,
      day_duty_quantity = 0,
      night_duty_quantity = 0,
      rate_data = {}
    } = row;

    const toNumber = (value) => parseFloat(value) || 0;

    const additionalAmountNum = toNumber(additional_amount);
    const dayDutyQuantityNum = toNumber(day_duty_quantity);
    const nightDutyQuantityNum = toNumber(night_duty_quantity);
    const monthRateNum = toNumber(rate_data?.month_rate ?? 0);
    const dayRateNum = toNumber(rate_data?.day_rate ?? 0);
    const nightRateNum = toNumber(rate_data?.night_rate ?? 0);

    let amount = 0;

    if (rate_type === RATE_TYPE_MONTH) {
      amount = monthRateNum;
    } else if (rate_type === RATE_TYPE_DUTY) {
      amount = (dayRateNum * dayDutyQuantityNum) + (nightRateNum * nightDutyQuantityNum);
    }

    return {
      rate_amount: amount,
      total_amount: amount + additionalAmountNum
    };
  };

  return [
    {
      flex: 0.2,
      field: 'name',
      minWidth: 140,
      headerName: 'Name',
      renderHeader: () => (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Name</Typography>
          <FiltrationInput
            options={[]}
            defaultValue={filters.name || ''}
            handleFilter={handleFilter}
            placeholder="Name"
            value="name"
          />
        </Box>
      ),
      renderCell: ({ row }) => (
        row?.name
      ),
    }, // Name
    {
      flex: 0.2,
      field: 'rate_type',
      minWidth: 140,
      headerName: 'Rate Type',
      renderHeader: () => (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Rate Type</Typography>
          <FiltrationInput
            options={[]}
            defaultValue={filters.rate_type || ''}
            handleFilter={handleFilter}
            placeholder="Rate Types"
            value="rate_type"
          />
        </Box>
      ),
      renderCell: ({ row }) => (
        <TextEdit
          handleEdit={(...params) => handleOpen(...params, 'rate_type', row)}
          text={upperCase(row.rate_type)}
          disabled={!hasPermissions}
          labelInput="Enter Type of payment"
          options={RATE_TYPES}
          activeItem={row.rate_type}
          type={TYPE_SELECT}
        />
      ),
    }, // Rate type
    {
      flex: 0.2,
      field: 'rate_data',
      minWidth: 140,
      headerName: 'Rate',
      renderHeader: () => (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Rate</Typography>
        </Box>
      ),
      renderCell: ({ row }) => {
        const { rate_data } = row;

        return (
          <Box
            sx={{
              border: '1px solid',
              p: 2,
              borderRadius: '5px',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#e9f0fe',
              },
            }}
            onClick={() => {
              if (hasPermissions) { handleOpenUpdateRate({
                rate_type: row?.rate_type,
                night_rate: row?.rate_data?.night_rate,
                day_rate: row?.rate_data?.day_rate,
                month_rate: row?.rate_data?.month_rate,
                name: row?.name,
                id: row.id,
                type: 'salary'
              }); }
            }}
          >
            {row.rate_type === RATE_TYPE_MONTH && (<Typography>{`Rate: ${rate_data?.month_rate ?? 'N/A'}`}</Typography>)}
            {row.rate_type === RATE_TYPE_DUTY && (<Typography>{`Day Rate: ${rate_data?.day_rate ?? 'N/A'}`}</Typography>)}
            {row.rate_type === RATE_TYPE_DUTY && (<Typography>{`Night Rate: ${rate_data?.night_rate ?? 'N/A'}`}</Typography>)}

          </Box>
        );
      },
    }, // Rate data
    {
      flex: 0.2,
      field: 'quantity',
      minWidth: 140,
      headerName: 'Quantity',
      renderHeader: () => (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Quantity</Typography>
        </Box>
      ),
      renderCell: ({ row }) => (
        <Box
          sx={{
            border: '1px solid',
            p: 2,
            borderRadius: '5px',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: '#e9f0fe',
            },
          }}
          onClick={() => {
            if (hasPermissions && row?.rate_type === RATE_TYPE_DUTY) { handleOpenUpdateQnt({
              day_duty_quantity: row?.day_duty_quantity,
              night_duty_quantity: row?.night_duty_quantity,
              id: row.id,
            }); }
          }}
        >
          {row.rate_type === RATE_TYPE_MONTH && (<Typography>1</Typography>)}
          {row.rate_type === RATE_TYPE_DUTY && (<Typography>{`Day Rate Qnt.: ${row?.day_duty_quantity ?? 'N/A'}`}</Typography>)}
          {row.rate_type === RATE_TYPE_DUTY && (<Typography>{`Night Rate Qnt.: ${row?.night_duty_quantity ?? 'N/A'}`}</Typography>)}
        </Box>
      ),
    }, // Rate type
    {
      flex: 0.2,
      field: 'amount',
      minWidth: 140,
      headerName: 'Rate Amount',
      renderHeader: () => (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Rate Amount</Typography>
        </Box>
      ),
      renderCell: ({ row }) => (
        <Chip label={`${calculateAmount(row).rate_amount ?? 0} EUR`} variant="outlined" />
      ),
    }, // Rate type
    {
      flex: 0.2,
      field: 'additional_amount',
      minWidth: 140,
      headerName: 'Additional Amount',
      renderHeader: () => (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Additional Amount</Typography>
        </Box>
      ),
      renderCell: ({ row }) => (
        <TextEdit
          handleEdit={(...params) => handleOpen(...params, 'additional_amount', row)}
          text={parseFloat(row?.additional_amount) || 0}
          labelInput="Additional Amount"
          disabled={!hasPermissions}
          type={TYPE_INPUT}
        />
      ),
    }, // Additional Amount
    {
      flex: 0.2,
      field: 'total_amount',
      minWidth: 140,
      headerName: 'Total Amount',
      renderHeader: () => (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Total Amount</Typography>
        </Box>
      ),
      renderCell: ({ row }) => (
        <Chip label={`${calculateAmount(row).total_amount} EUR`} />
      ),
    }, // Total Amount
    {
      flex: 0.1,
      field: 'date_of_transfer',
      minWidth: 180,
      headerName: 'Date of Transfer',
      renderHeader: () => (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Date of Transfer</Typography>
          <FiltrationDate
            value={filters.date_of_transfer || null}
            onChange={(e) => {
              handleFilter(e, 'date_of_transfer', true);
            }}
          />
        </Box>
      ),
      renderCell: ({ row }) => (
        <TextEdit
          handleEdit={(...params) => handleOpen(...params, 'date_of_transfer', row)}
          text={row.date_of_transfer}
          labelInput="Enter Date"
          type={TYPE_SELECT_DATE}
        />
      ),
    }, // Total Amount
    {
      flex: 0.2,
      field: 'type_of_payment',
      minWidth: 160,
      headerName: 'Type of payment',
      renderHeader: () => (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Type of payment</Typography>
          <FiltrationInput
            options={[]}
            defaultValue={filters.type_of_payment || ''}
            handleFilter={handleFilter}
            placeholder="Type of payment"
            value="type_of_payment"
          />
        </Box>
      ),
      renderCell: ({ row }) => (
        <TextEdit
          handleEdit={(...params) => handleOpen(...params, 'type_of_payment', row)}
          text={row.type_of_payment}
          disabled={!hasPermissions}
          labelInput="Enter Type of payment"
          options={TYPE_OF_PAYMENTS}
          activeItem={row.type_of_payment}
          type={TYPE_SELECT}
        />
      ),
    }, // Type of payment
    {
      flex: 0.4,
      field: 'payment_details',
      minWidth: 140,
      headerName: 'Payment details',
      renderHeader: () => (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Payment details</Typography>
          <FiltrationInput
            options={[]}
            defaultValue={filters.payment_details || ''}
            handleFilter={handleFilter}
            placeholder="Payment details"
            value="payment_details"
          />
        </Box>
      ),
      renderCell: ({ row }) => (
        <TextEdit
          handleEdit={(...params) => handleOpen(...params, 'payment_details', row)}
          text={row.payment_details}
          disabled={!hasPermissions}
          labelInput="Enter Payment details"
          type={TYPE_MULTI_INPUT}
        />
      )
    }, // Payment details
    {
      flex: 0.2,
      field: 'comments',
      minWidth: 140,
      headerName: 'Comments',
      renderHeader: () => (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Comments</Typography>
          <FiltrationInput
            options={[]}
            defaultValue={filters.comments || ''}
            handleFilter={handleFilter}
            placeholder="Comments"
            value="comments"
          />
        </Box>
      ),
      renderCell: ({ row }) => (
        <TextEdit
          handleEdit={(...params) => handleOpen(...params, 'comments', row)}
          text={row.comments}
          labelInput="Comments"
          disabled={!hasPermissions}
          type={TYPE_MULTI_INPUT}
        />
      ),
    }, // Comments
    {
      // flex: 0.2,
      field: 'file',
      minWidth: 120,
      headerName: 'Files',
      sortable: false,
      renderHeader: () => (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Files</Typography>
        </Box>
      ),
      renderCell: ({ row }) => (
        <Box>
          <Button
            sx={row?.file_exist ? (GreenButton) : (RedButton)}
            size="small"
            onClick={() => handleFileDrawer(true, row?.id, 1, 'Salary', row?.id)}
          >
            Files
          </Button>
        </Box>
      )
    }, // Attached Documents
    {
      // flex: 0.2,
      field: 'transaction_id',
      minWidth: 120,
      headerName: 'Transaction',
      sortable: false,
      renderHeader: () => (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Transaction</Typography>
        </Box>
      ),
      renderCell: ({ row }) => (
        <Box>
          <Button
            sx={row?.transaction_id ? (GreenButton) : (RedButton)}
            size="small"
            onClick={() => {
              handleTransactionPortalFileDrawer(true, row?.transaction_id, TransactionModel.types.salary.id, 'Transaction', row?.transaction_id);
              handleOpenSalaryTransaction({ item_id: row.id, transaction_id: row.transaction_id });
            }}
          >
            Transaction
          </Button>
        </Box>
      )
    }, // Transaction
    {
      width: 100,
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          <StyledDelete onClick={() => {
            if (hasPermissions) handleOpenDeleteModal(row.id);
          }}
          />
        </Box>
      ),
    }, // actions
  ];
};
