import { yupResolver } from '@hookform/resolvers/yup';
import {
  Card, CardContent, Chip, Divider, Grid,
  TextField, Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Button from '../../../../components/Button';
import Drawer from '../../../../components/Drawer';
import { DELIVERY_PART_TYPES } from '../../../../constans/logistics';
import Checkbox from '../../../../form/components/Checkbox';
import FieldError from '../../../../form/components/FieldError';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import Select from '../../../../form/components/Select';
import ServerAutocomplete from '../../../../form/components/ServerAutocomplete';
import { useGetInternalAutocompleteOrdersQuery, useGetInternalAutocompletePartsQuery, useSendStoreInternalOrderMutation } from '../../../../store/slices/internalLogisticsSlice';
import { useSearchSupplierQuery } from '../../../../store/slices/logisticsSlice';
import { CARRIERS } from '../../constants';
import { GreenButton } from '../../style';
import OrderItems from './components/OrderItems';
import { defaultValues, schema } from './schema';

const select1 = ['part_delivery', 'overhaul', 'repair', 'exchange'];
const conditionSelectAddress1 = ['exchange', 'overhaul', 'repair'];

const NewDeliveryOrder = (props) => {
  const {
    open, onClose, aircraftOptions = [], handleRefresh, priorityOptions = []
  } = props;
  const [currentPart, setCurrentPart] = useState([]);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [carrierVisibility, setCarrierVisibility] = useState(false);
  const [sendNewOrder] = useSendStoreInternalOrderMutation();
  const [supplier, setSupplier] = useState(null);
  const handleClose = () => {
    onClose();
  };

  const {
    reset,
    setValue,
    control,
    watch,
    handleSubmit,
    formState: { errors },
    trigger,
    getValues
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const addressSelectOptions = [
    { label: 'Hof', value: 'hof' },
    { label: 'Budapest', value: 'budapest' },
    ...aircraftOptions
  ].filter(({ label }) => label !== 'N/A');

  const createFormData = (data) => {
    const formData = new FormData();
    const type = data?.part_type;
    const labelType = DELIVERY_PART_TYPES.find(({ value }) => value === type)?.send_type;

    formData.append('part_type', labelType);

    if (select1.includes(type)) {
      if (data?.part_id.split(',')?.length > 1) {
        data?.part_id.split(',').forEach((el, index) => {
          formData.append(`part_ids[${index}]`, el);
        });
      } else {
        formData.append('part_ids[0]', data?.part_id);
      }
      formData.append('priority', +data?.priority);
      if (!['overhaul', 'repair', 'exchange'].includes(type)) formData.append('aircraft_reservation', +data?.aircraft_reservation === 0 ? '' : +data?.aircraft_reservation);

      data?.current_part.forEach((el, index) => {
        formData.append(`quantity[${index}]`, el?.quantity);
      });

      if (!['overhaul', 'repair'].includes(type)) {
        formData.append('address_from', data?.from ? `${addressSelectOptions.find(({ value }) => value?.toString() === data?.from?.toString())?.label} - ${data?.address_from}` : data?.address_from);
        formData.append('address_to', ((type === 'exchange') ? data?.address_to : data?.to ? `${addressSelectOptions.find(({ value }) => value?.toString() === data?.to?.toString())?.label} - ${data?.address_to}` : data?.address_to));
      }
    }

    if (type === 'part_delivery' && data?.carrierVis) {
      formData.append('carrier', data?.carrier);
    }

    if (type === 'exchange') {
      formData.append('maintenance_invoice_part_id', +data?.maintenance_invoice_part_id);
    }

    if (type === 'other') {
      formData.append('priority', +data?.priority);
      formData.append('address_from', data?.address_from);
      formData.append('address_to', data?.address_to);
      formData.append('description', data?.description);
    }

    return formData;
  };

  const onSubmit = async (data) => {
    const formData = createFormData(data);
    const toastId = toast.loading('Loading...');

    const res = await sendNewOrder(formData);

    if (res?.error?.data) {
      toast.error(res?.error?.data?.message || 'Something went wrong', {
        id: toastId,
      });

      return;
    }

    handleClose();
    handleRefresh();
    toast.success('Successfully!', {
      id: toastId,
    });
  };

  const handleSelectPart = (item) => {
    setCurrentPart(
      Array.isArray(item)
        ? (item.length
          ? [...item]
          : [])
        : (item || null)
    );

    if (!Array.isArray(item) && (getValues('part_type') !== 'part_delivery')) {
      const current_part = item ? {
        ...item?.data,
        quantity: (item?.data?.serial_number !== 'N/A') ? 1 : item?.data?.quantity,
        aircraft_reservation: ['overhaul', 'repair'].includes(watch('part_type')) ? 0 : item?.data?.aircraft_reservation,
      } : null;

      setValue('current_part', item ? [{
        id: current_part?.id,
        part_name: current_part?.part_name,
        part_number: current_part?.part_number,
        serial_number: current_part?.serial_number,
        quantity: current_part?.quantity,
      }] : [{
        id: 0,
        part_name: '',
        part_number: '',
        serial_number: '',
        quantity: 0,
      }]);
      setValue('part_id', item?.value || null);
      trigger('part_id');
    } else {
      const ids = item.map(({ value }) => value).join(',');
      const address = getValues('address_from');
      const currentPartWatch = watch();
      const parts = item.map(({ data }) => {
        const isQuantity = currentPartWatch?.current_part?.some(pt => pt?.id === data?.id);

        return {
          id: data?.id,
          part_name: data?.part_name,
          part_number: data?.part_number,
          serial_number: data?.serial_number,
          quantity: isQuantity ? currentPartWatch?.current_part?.find(pt => pt?.id === data?.id)?.quantity : data?.quantity,
        };
      });

      setValue('current_part', parts || defaultValues.current_part);
      setValue('part_id', ids.length ? ids : null);
      trigger('part_id');

      if ((parts?.length >= 1) && (getValues('part_type') === 'part_delivery') && (!address?.length || (address === item?.[item.length - 2]?.data?.storage))) {
        setValue('address_from', item?.[item.length - 1]?.data?.storage);
        trigger('address_from');
      }
    }
  };

  const handleSelectOrder = (item) => {
    setCurrentOrder(item || null);

    setValue('maintenance_invoice_part_id', item?.value || 0);
    if (item) {
      setValue('installedOrder', [{
        id: item?.data?.id,
        part_number: item?.data?.part_number,
        part_name: item?.data?.part_name,
        part_type: item?.data?.part_type,
        storage_zone: item?.data?.storage_zone,
        storage_number: item?.data?.storage_number,
      }]);
    } else {
      setValue('installedOrder', [defaultValues.installedOrder[0]]);
    }
  };

  const handleSelectSupplier = (selection) => {
    setSupplier(selection);

    setValue('to', selection?.label);
    setValue('address_to', selection?.data?.actual_address || selection?.data?.label);
  };

  const handleChangePartType = (value) => {
    setCurrentPart(null);
    setCurrentOrder(null);
    setSupplier(null);
    reset();
    setValue('part_type', value);
  };

  const handleChangeAddress = (data, key) => {
    if (data === 'hof') {
      setValue(
        key,
        'Fleet Air International Kft\nPirk 20 Flughafen Hof-Plauen GmbH & Co. KG\nHOF, SAALE\n95032\nGermany'
      );
    } else if (data === 'budapest') {
      setValue(
        key,
        'Fleet Air International Kft\n2220 Vecses\nFo ut 218\nHungary'
      );
    } else {
      setValue(key, addressSelectOptions.find(({ value }) => data === value)?.label);
    }
  };

  const handleRemovePart = (id) => {
    const parts = getValues('current_part');
    const partsIds = getValues('part_id');
    
    if (parts) {
      const newParts = parts.filter((part) => (!!part?.id && (part?.id !== id)));
      const newPartIds = partsIds?.split(',')?.filter(partId => +partId !== id)?.join(',');
      if (newParts.length) {
        const newCurrentParts = newParts.map((part) => ({
          label: part?.part_name,
          value: part?.id,
          data: part
        }));

        setCurrentPart(newCurrentParts);
      } else {
        setCurrentPart([]);
      }

      setValue('part_id', newPartIds.length ? newPartIds : null);
      setValue('current_part', newParts);
    }
  };

  useEffect(() => {
    if (!open) {
      setCurrentOrder(null);
      setCurrentPart([]);
      setSupplier(null);
      setCarrierVisibility(false);
      reset();
    }
  }, [open]);

  return (
    <Drawer
      title="Create New Internal Order"
      open={open}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={2}
          rowSpacing={2}
        >
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={6}>
                  <Grid item xs={3}>
                    <FormGroup label="Part Type" hasError={!!errors.part_type}>
                      <Controller
                        control={control}
                        name="part_type"
                        render={({ field }) => (
                          <Select
                            options={DELIVERY_PART_TYPES}
                            placeholder="Part Type"
                            {...field}
                            onChange={(e) => {
                              field.onChange(e?.target?.value);
                              handleChangePartType(e?.target?.value);
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={4}>
                    <FormGroup label="Priority" required hasError={errors?.priority}>
                      <Controller
                        name="priority"
                        control={control}
                        render={({ field }) => (
                          <Select
                            options={priorityOptions}
                            placeholder="Priority"
                            {...field}
                          />
                        )}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                    <Typography variant="h4" sx={{ textTransform: 'capitalize' }}>
                      {DELIVERY_PART_TYPES.find(({ value }) => watch('part_type') === value)?.label}
                      {' '}
                      Form
                    </Typography>
                    <Divider />
                  </Grid>

                  {select1.includes(watch('part_type')) && (
                    <>
                      {((watch('part_type') === 'part_delivery') && open) && (
                      <Grid item xs={6}>
                        <FormGroup label="Part ID" required hasError={!!errors.part_id}>
                          <Controller
                            name="part_id"
                            control={control}
                            render={({ field }) => (
                              <ServerAutocomplete
                                field={field}
                                value={currentPart || []}
                                withData
                                multiple
                                limitTags={1}
                                id="multiple-limit-tags"
                                placeholder="Search Parts"
                                searchQueryFn={useGetInternalAutocompletePartsQuery}
                                queryParams={{
                                  id_with_sa: true
                                }}
                                blurOnSelect="touch"
                                handleSelect={handleSelectPart}
                                getOptionLabel={(option) => (typeof option === 'string' ? option : `${option?.value} - ${option?.label}`) || ''}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Parts"
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <>
                                          {params.InputProps.endAdornment}
                                          <Chip
                                            label="+ Add Part"
                                            size="small"
                                            sx={{
                                              backgroundColor: '#36918b', color: '#FFF', cursor: 'pointer', margin: 1 
                                            }}
                                          />
                                        </>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>
                      )}
                      {(watch('part_type') !== 'part_delivery') && (
                      <Grid item xs={6}>
                        <FormGroup label={watch('part_type') === 'exchange' ? 'Removed Part ID' : 'Part ID'} required hasError={!!errors.part_id}>
                          <Controller
                            name="part_id"
                            control={control}
                            render={({ field }) => (
                              <ServerAutocomplete
                                field={field}
                                value={currentPart}
                                withData
                                placeholder="Search Parts"
                                searchQueryFn={useGetInternalAutocompletePartsQuery}
                                queryParams={{
                                  id_with_sa: true
                                }}
                                handleSelect={handleSelectPart}
                                getOptionLabel={(option) => (typeof option === 'string' ? option : `${option?.value} - ${option?.label}`) || ''}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>
                      )}
                      {watch('part_type') === 'part_delivery' && (
                      <Grid item xs={4}>
                        <FormGroup label="Aircraft Reservation" required hasError={!!errors?.aircraft_reservation}>
                          <Controller
                            control={control}
                            name="aircraft_reservation"
                            render={({ field }) => (
                              <Select
                                options={aircraftOptions}
                                placeholder="Aircraft Reservation"
                                {...field}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>
                      )}
                    </>

                  )}

                  {(watch('part_type') === 'exchange') && (
                  <Grid item xs={6}>
                    <FormGroup label="Installed Order ID" required hasError={!!errors.maintenance_invoice_part_id}>
                      <Controller
                        name="maintenance_invoice_part_id"
                        control={control}
                        render={({ field }) => (
                          <ServerAutocomplete
                            field={field}
                            value={currentOrder}
                            withData
                            placeholder="Search Order"
                            searchQueryFn={useGetInternalAutocompleteOrdersQuery}
                            handleSelect={handleSelectOrder}
                          />
                        )}
                      />
                      <FieldError error={errors?.maintenance_invoice_part_id} />
                    </FormGroup>
                  </Grid>
                  )}

                  {(currentPart?.length >= 1)
                  && (
                  <OrderItems
                    label={(watch('part_type') === 'exchange') ? 'Removed Part' : 'Parts'}
                    items={watch('current_part').filter(item => item?.id)}
                    handleRemoveItem={handleRemovePart}
                    control={control}
                    errors={errors}
                    watch={watch}
                    currentPart={currentPart}
                  />
                  )}
                  {(currentPart && currentOrder) && <Divider />}
                  {currentOrder && (
                  <OrderItems
                    label="Installed Order"
                    items={watch('installedOrder')}
                    handleRemoveItem={() => handleSelectOrder(null)}
                    control={control}
                    errors={{}}
                    watch={watch}
                    typeItems="installedOrder"
                    xs={4}
                  />
                  )}
                  {watch('part_type') === 'part_delivery' && (
                  <Grid container spacing={6} xs={12} sm={12} sx={{ padding: '0 24px' }}>
                    <Grid item xs={4}>
                      <FormGroup label="Self Delivery" hasError={!!errors.carrierVis}>
                        <Controller
                          name="carrierVis"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              onChange={(el) => {
                                setCarrierVisibility(el.target.checked);
                                field.onChange(el);
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                    </Grid>
                    {carrierVisibility && (
                      <Grid item xs={4}>
                        <FormGroup label="Carrier" hasError={!!errors.carrier}>
                          <Controller
                            name="carrier"
                            control={control}
                            render={({ field }) => (
                              <Select
                                options={CARRIERS}
                                placeholder="Carrier"
                                {...field}
                              />
                            )}
                          />
                        </FormGroup>

                      </Grid>
                    )}

                  </Grid>
                  )}

                  {watch('part_type') === 'other' && (
                  <Grid container spacing={6} sx={{ padding: '0 24px' }}>
                    <Grid item xs={12}>
                      <FormGroup label="Description" required hasError={errors?.description}>
                        <Controller
                          name="description"
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              rows={8}
                              multiline
                              sx={{ width: '100%' }}
                            />
                          )}
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  )}

                  {/* Address form */}
                  {(!['overhaul', 'repair'].includes(watch('part_type'))) && (
                    <>
                      <Grid item xs={12}>
                        <Divider />
                        <Typography variant="h4" sx={{ textTransform: 'capitalize' }}>
                          Address Form
                        </Typography>
                        <Divider />
                      </Grid>
                      <Grid container spacing={6} sx={{ padding: '0 24px' }}>
                        {watch('part_type') !== 'other' && (
                        <Grid item xs={5}>
                          <FormGroup label="From">
                            <Controller
                              name="from"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  options={addressSelectOptions}
                                  placeholder="From"
                                  {...field}
                                  onChange={(e) => {
                                    handleChangeAddress(e?.target?.value, 'address_from');
                                    field?.onChange(e?.target?.value);
                                  }}
                                />
                              )}
                            />
                            <FieldError error={errors?.from} />
                          </FormGroup>
                        </Grid>
                        )}
                        <Grid item xs={5}>
                          <FormGroup label="From Address" required hasError={errors?.address_from}>
                            <Controller
                              name="address_from"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  multiline
                                  sx={{ width: '100%' }}
                                />
                              )}
                            />
                            <FieldError error={errors?.address_from} />
                          </FormGroup>
                        </Grid>
                        {
                      conditionSelectAddress1.includes(watch('part_type')) && (
                        <Grid item xs={5}>
                          <FormGroup label="To">
                            <Controller
                              name="to"
                              control={control}
                              render={({ field }) => (
                                <ServerAutocomplete
                                  field={field}
                                  value={supplier}
                                  withData
                                  placeholder="Search suppliers by name or email"
                                  searchQueryFn={useSearchSupplierQuery}
                                  queryParams={{ type: 'service' }}
                                  handleSelect={handleSelectSupplier}
                                />
                              )}
                            />
                            <FieldError error={errors?.to} />
                          </FormGroup>
                        </Grid>
                      )
                    }
                        {((watch('part_type') !== 'other') && (!conditionSelectAddress1.includes(watch('part_type')))) && (
                        <Grid item xs={5}>
                          <FormGroup label="To">
                            <Controller
                              name="to"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  options={addressSelectOptions}
                                  placeholder="To"
                                  {...field}
                                  onChange={(e) => {
                                    handleChangeAddress(e?.target?.value, 'address_to');
                                    field.onChange(e?.target?.value);
                                  }}
                                />
                              )}
                            />
                            <FieldError error={errors?.to} />
                          </FormGroup>
                        </Grid>
                        )}
                        <Grid item xs={5}>
                          <FormGroup label="To Address" required hasError={errors?.address_to}>
                            <Controller
                              name="address_to"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  multiline
                                  sx={{ width: '100%' }}
                                />
                              )}
                            />
                            <FieldError error={errors?.address_to} />
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </>
                  )}

                </Grid>

                <Grid sx={{ mt: 4 }}>
                  <Button type="submit" sx={GreenButton}>Create</Button>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </form>
    </Drawer>
  );
};

export default NewDeliveryOrder;
