import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FaceIcon from '@mui/icons-material/Face';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import {
  Autocomplete,
  Box,
  CardContent, CardHeader, Divider, Grid, Switch, TextField,
  Typography
} from '@mui/material';
import { Airplane, ListStatus, Rename } from 'mdi-material-ui';
import React from 'react';
import DatePicker from '../../../form/components/DatePicker';
import Select from '../../../form/components/Select';
import { CloseButton } from '../../../pages/Logistics/style';
import { CloseIcon } from '../../Icons';
import {
  AutocompleteFilterStyle,
  DatePickerFilterStyle, FilterCardHeader, FilterContainer, SelectFilterStyle, StyledSwitch, StyledTableFilter
} from './style';

const TableFilterContainer = (props) => {
  const {
    filterConfig: {
      items = [],
      afterDivider = true
    }
  } = props;

  const switchIcon = (type, value) => {
    switch (type) {
      case 'Airplane':
        return <Airplane sx={{ ...(!!value && { color: 'green' }) }} />;
      case 'ListStatus':
        return <ListStatus sx={{ ...(!!value && { color: 'green' }) }} />;
      case 'CalendarMonthIcon':
        return <CalendarMonthIcon sx={{ ...(!!value && { color: 'green' }) }} />;
      case 'Storage':
        return <WarehouseIcon sx={{ ...(!!value && { color: 'green' }) }} />;
      case 'Rename':
        return <Rename sx={{ ...(!!value && { color: 'green' }) }} />;
      case 'Face':
        return <FaceIcon sx={{ ...(!!value && { color: 'green' }) }} />;
      default:
        return '';
    }
  };

  const switchFilter = (type, placeholder, callback, options, value, dateFormat = null) => {
    switch (type) {
      case 'Select':
        return (
          <Select
            value={value || (value === 0 ? 0 : null)}
            sx={SelectFilterStyle}
            defaultValue={value || ''}
            placeholder={placeholder || 'Search'}
            options={options || []}
            onChange={callback}
          />
        );
      case 'DatePicker':
        return (
          <DatePicker
            value={value || ''}
            sx={DatePickerFilterStyle}
            placeholderText={placeholder || 'Search'}
            dateFormat={dateFormat || 'dd.MM.yyyy'}
            showMonthDropdown
            showYearDropdown
            onChange={callback}
          />
        );
      case 'TextField':
        return (
          <TextField
            size="small"
            sx={DatePickerFilterStyle}
            value={value || ''}
            placeholder={placeholder}
            onChange={callback}
          />
        );
      case 'CrewAutocomplete':
        return (
          <Autocomplete
            sx={AutocompleteFilterStyle}
            freeSolo
            value={options?.filter((CrewOptions) => CrewOptions.id === value)[0] || ''}
            options={options}
            getOptionLabel={(option) => option.crew_name || ''}
            isOptionEqualToValue={(option, crewItem) => option.id === crewItem.id}
            id="autocomplete-outlined"
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder={placeholder}
              />
            )}
            onChange={callback}
          />
        );
      case 'Switch':
        return (
          <Box sx={{
            display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center', gap: '8px'
          }}
          >
            <Typography
              onClick={() => {
                callback('all_orders');
              }}
              sx={{ cursor: 'pointer', fontSize: '1.2rem', fontWeight: '600' }}
            >
              All Orders
            </Typography>
            <Switch
              checked={value}
              color="default"
              sx={StyledSwitch}
              onClick={() => callback(value ? 'all_orders' : 'my_orders')}
            />
            <Typography
              onClick={() => {
                callback('my_orders');
              }}
              sx={{ cursor: 'pointer', fontSize: '1.2rem', fontWeight: '600' }}
            >
              My Orders
            </Typography>
          </Box>
        );
      default:
        return '';
    }
  };

  return (
    <>
      <CardHeader avatar={<FilterAltIcon />} title="Search Filters" sx={FilterCardHeader} />
      <CardContent sx={FilterContainer}>
        <Grid container spacing={6}>
          {
          items?.filter(item => !!item).map(({
            value = '', callback = () => {}, closeCallback = () => {}, typeFilter = '', icon = '', options = [], placeholder = ''
          }) => (
            <Grid item xs={12} md={2} sx={StyledTableFilter}>
              {switchIcon(icon, value)}
              {switchFilter(typeFilter, placeholder, callback, options, value)}

              {((typeFilter !== 'CrewAutocomplete') && (typeFilter !== 'Switch')) && (
                <CloseIcon sx={CloseButton} onClick={closeCallback} />
              )}

              <Divider orientation="vertical" variant="middle" flexItem />
            </Grid>
          ))
        }
        </Grid>
      </CardContent>
      {afterDivider ? <Divider /> : ''}
    </>

  );
};

export default TableFilterContainer;
